import axios from 'axios';
import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { visuallyHidden } from '@mui/utils';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import CheckIcon from '@mui/icons-material/Check';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import EditFileModal from './EditFileModal';
import CreateNoteModal from './CreateNoteModal';
import EditSyllabusModal from '../EditSyllabusModal';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import FileTableActions from './FileTableActions';
import Pagination from './Pagination';
import { NoModuleFiles } from '../../components/EmptyStates.js';
import '../Tables.css';
import { 
  STATUS, 
  isProcessingStatus, 
  hasError, 
  isRowSelectable as isRowSelectableUtil,
} from './fileStatusUtils';

function descendingComparator(a, b, orderBy) {
  if (a.isModule && !b.isModule) return -1; //modules come first
  if (!a.isModule && b.isModule) return 1; //then files

  // If sorting by title (filename), use localeCompare for proper alphabetical sorting
  if (orderBy === 'title') {
    return b.title.localeCompare(a.title);
  }
  
  // If both items are modules or both are files, sort normally
  if (orderBy === 'created_at') {
    const dateA = new Date(a[orderBy]);
    const dateB = new Date(b[orderBy]);
    
    // Check if dates are valid before comparing
    if (!isNaN(dateA) && !isNaN(dateB)) {
      if (dateB < dateA) {
        return -1;
      }
      if (dateB > dateA) {
        return 1;
      }
      return 0;
    }
  }

  // Other sorting
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return (a, b) => {
    // First, check for errors and prioritize them
    if (hasError(a.status) && !hasError(b.status)) return -1;
    if (!hasError(a.status) && hasError(b.status)) return 1;

    // Always put any processing status at the top
    if (isProcessingStatus(a.status) && !isProcessingStatus(b.status)) return -1;
    if (!isProcessingStatus(a.status) && isProcessingStatus(b.status)) return 1;

    // For other cases, use the existing comparison logic
    const comparison = descendingComparator(a, b, orderBy);
    return order === 'desc' ? comparison : -comparison;
  };
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const formatDate = (dateString) => {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return dateString; // Return original if invalid
  
  // Get day with leading zero if needed
  const day = String(date.getDate()).padStart(2, '0');
  
  // Get month abbreviation (3 chars)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  
  // Get full year
  const year = date.getFullYear();
  
  return `${day} ${month} ${year}`;
};

export default function FileTable( { 
  files = [], 
  modules = [], 
  setFiles, 
  setModules,
  refresh,
  onModuleSelect,
  onReturnToAllFiles
} ) {
  // Hooks for URL management
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  //States
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
  const [editSyllabusModalOpen, setEditSyllabusModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // Initialize currentView based on URL params
  const [currentView, setCurrentView] = useState(() => {
    const moduleId = searchParams.get('moduleId');
    return moduleId || 'all';
  });
  const [displayedRows, setDisplayedRows] = useState([]);
  const [rows, setRows] = useState(files);
  const [replacingFileId, setReplacingFileId] = useState(null); // Track file being replaced
  const [totalItems, setTotalItems] = useState(0);
  const [totalModules, setTotalModules] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0); 
  const [statusCounts, setStatusCounts] = useState({
    loading: 0,
    processing: 0,
    updating: 0,
    replacing: 0
  });

  // Tracking file status
  const isFileProcessing = (row) => {
    return isProcessingStatus(row?.status);
  };

  const isRowSelectable = (row) => {
    if (row.isModule) return true;
    return isRowSelectableUtil(row);
  };

  // Update filtered view when files, modules, or currentView changes
  useEffect(() => {
     // Ensure modules is always an array and properly formatted
    const processedModules = Array.isArray(modules) ? modules : [];
    
    // Log module structure for debugging
    console.log("Processing modules:", processedModules.map(m => ({
      id: m.id,
      title: m.title,
      fileCount: m.files ? m.files.length : 0,
      idType: typeof m.id
    })));
    
    // If we're in a module view, make sure the module exists
    if (currentView !== 'all') {
      const moduleExists = processedModules.some(m => 
        String(m.id) === String(currentView) || 
        `module-${m.id}` === currentView
      );
      
      if (!moduleExists) {
        console.warn(`Current view module (ID: ${currentView}) not found in modules array. Reverting to 'all' view`);
        setCurrentView('all');
      }
    }
    // Reset pagination when view changes
    setPage(0);
    
    let itemsToShow = [];
    const modulesArray = Array.isArray(modules) ? modules : [];

    // For debugging
    console.log("Current view:", currentView);
    console.log("Available modules:", modulesArray.map(m => ({ id: m.id, title: m.title })));
    
      
    if (currentView === 'all') {
      // Show modules and files not attributed to modules
      const modulesData = modulesArray.map(module => {
        // Find all files that belong to this module
        const moduleFiles = files.filter(file => 
          file.module?.id && String(file.module.id) === String(module.id)
        );
        
        // Find earliest created_at date from these files
        let earliestDate = new Date().toISOString(); // Default to today
        
        if (moduleFiles.length > 0) {
          earliestDate = moduleFiles.reduce((earliest, file) => {
            if (!file.created_at) return earliest;
            
            const fileDate = new Date(file.created_at);
            const earliestSoFar = new Date(earliest);
            
            return !isNaN(fileDate) && fileDate < earliestSoFar ? file.created_at : earliest;
          }, moduleFiles[0].created_at || new Date().toISOString());
        }
        
        return {
          ...module,
          isModule: true,
          id: `module-${module.id}`,
          created_at: earliestDate
        };
      });
      
      const nonModuleFiles = files.filter(file => !file.module?.id);
      
      itemsToShow = [...modulesData, ...nonModuleFiles];

      console.log("All view - Modules rendered:", modulesData);
      } else {
        // Show only files from the selected module
        console.log("Module view - Looking for files with module_id:", currentView);
        
        // Convert currentView to string and number for flexible comparison
        const currentViewStr = String(currentView);
        const currentViewNum = parseInt(currentView, 10);
        
        // Try multiple approaches to find the module
        const selectedModule = modulesArray.find(m => 
          String(m.id) === currentViewStr || 
          m.id === currentViewNum ||
          `module-${m.id}` === currentViewStr
        );
        
        if (selectedModule) {
          console.log("Found module:", selectedModule.title, "with ID:", selectedModule.id);
          
          // Get the module's files array (should contain file_ids)
          const moduleFileIds = selectedModule.files || [];
          console.log("Module file IDs:", moduleFileIds);
          
          // Find files that belong to this module using flexible comparison
          itemsToShow = files.filter(file => {
            // Convert to strings for comparison to avoid type issues
            const fileModuleId = file.module_id !== undefined ? String(file.module_id) : '';
            
            // Check multiple conditions for file belonging to module
            const belongsToModuleById = 
              file.module?.id !== undefined && 
              (String(file.module.id) === currentViewStr || 
               String(file.module.id) === String(selectedModule.id));
              
            const belongsToModuleByFileList = 
              moduleFileIds.includes(file.file_id) || 
              (file.file_id && moduleFileIds.includes(String(file.file_id)));
            
            const belongsToModule = belongsToModuleById || belongsToModuleByFileList;
            
            if (belongsToModule) {
              console.log("Found file in module:", file.title, "file_id:", file.file_id);
            }
            
            return belongsToModule;
          });
          
          console.log(`Found ${itemsToShow.length} files in module ${selectedModule.title}`);
        } else {
          // If we still can't find the module, log all module IDs for comparison
          console.warn(`Module not found for ID: ${currentView}`);
          console.log("Available module IDs:", modulesArray.map(m => ({
            id: m.id,
            idType: typeof m.id,
            stringId: String(m.id),
            matchesCurrentView: String(m.id) === String(currentView)
          })));
          itemsToShow = [];
        }
      }
    
    setDisplayedRows(itemsToShow);
    setTotalItems(itemsToShow.length);
    setTotalModules(currentView === 'all' ? modulesArray.length : 0);
    
    // Count files and status counts
    const fileItems = itemsToShow.filter(item => !item.isModule);
    setTotalFiles(fileItems.length);

    setSelected([]);
    // Add debugging
    debugModuleSystem();
  }, [currentView, files, modules]);

  // Add this function to your component

  function debugModuleSystem() {
    console.group("Module System Debug Info");
    
    // Log current state
    console.log("Current view:", currentView);
    console.log("Current view type:", typeof currentView);
    
    // Analyze modules array
    if (Array.isArray(modules)) {
      console.log(`Modules array has ${modules.length} items`);
      
      // Check each module's structure
      modules.forEach((module, index) => {
        console.group(`Module ${index}: ${module.title || 'Unnamed'}`);
        console.log("ID:", module.id, "Type:", typeof module.id);
        console.log("Files array:", module.files);
        
        // Check if this module matches current view
        const matchesCurrentView = 
          String(module.id) === String(currentView) ||
          `module-${module.id}` === currentView;
        
        console.log("Matches current view?", matchesCurrentView);
        
        // If we're in this module's view, check which files should belong to it
        if (matchesCurrentView) {
          const moduleFiles = files.filter(file => {
            if (!file) return false;
            
            const byModuleId = file.module?.id !== undefined && 
              String(file.module.id) === String(module.id);
              
            const byFilesList = module.files && Array.isArray(module.files) && 
              (module.files.includes(file.file_id) || module.files.includes(String(file.file_id)));
            
            return byModuleId || byFilesList;
          });
          
          console.log(`Found ${moduleFiles.length} files that should belong to this module:`);
          moduleFiles.forEach(file => console.log("- File:", file.title, "ID:", file.file_id));
        }
        
        console.groupEnd();
      });
    } else {
      console.warn("Modules is not an array:", modules);
    }
    
    // Check the structure of the files array
    if (Array.isArray(files)) {
      const filesWithModules = files.filter(f => f.module?.id);
      console.log(`Files array has ${files.length} items (${filesWithModules.length} with module_id)`);
      
      // Group files by module_id for analysis
      const filesByModule = {};
      filesWithModules.forEach(file => {
        const moduleId = String(file.module.id);
        if (!filesByModule[moduleId]) filesByModule[moduleId] = [];
        filesByModule[moduleId].push(file);
      });
      
      // Log the results
      console.log("Files grouped by module_id:", filesByModule);
    } else {
      console.warn("Files is not an array:", files);
    }
    
    console.groupEnd();
  }

  // Handle URL changes
  useEffect(() => {
    const moduleId = searchParams.get('moduleId');
    if (moduleId && moduleId !== currentView && moduleId !== 'all') {
      setCurrentView(moduleId);
    } else if (!moduleId && currentView !== 'all') {
      setCurrentView('all');
    }
  }, [location.search]);

  useEffect(() => {
    setRows(files);
  }, [files]);

  useEffect(() => {
    console.log("Rows updated:", selected);
  }, [selected]);

  const handleRequestSort = (event, property) => {
    if (property === 'active' || property === 'optional') return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //Calculate selectable rows
  const selectableRowCount = stableSort(displayedRows, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .filter(row => isRowSelectable(row)).length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = stableSort(displayedRows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .filter(row => isRowSelectable(row));
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, file) => {
    if (!isRowSelectable(file)) {
      return; // Do nothing if the file is processing or has an error
    }

    const selectedIndex = selected.findIndex((f) => f.id === file.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, file);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleModuleClick = (moduleId, moduleName) => {
    // Strip "module-" prefix if present to store clean IDs
    const cleanModuleId = moduleId.replace('module-', '');
  
    console.log(`Module clicked: ${moduleName} (ID: ${cleanModuleId})`);
  
    // Navigate into the module
    setCurrentView(cleanModuleId);
    // Reset pagination and selection
    setPage(0);
    setSelected([]);
    
    // Call the parent component's handler to update breadcrumbs and URL
    if (onModuleSelect) {
      onModuleSelect(cleanModuleId, moduleName);
    } else {
      // Fallback if onModuleSelect is not provided
      navigate(`/files?module=${encodeURIComponent(moduleName)}&moduleId=${cleanModuleId}`, { replace: true });
    }
  };

  const handleBackToAllFiles = () => {
    // Set to all files view
    setCurrentView('all');
    
    // Reset pagination and selection
    setPage(0);
    setSelected([]);
    
    // Call the parent component's handler
    if (onReturnToAllFiles) {
      onReturnToAllFiles();
    } else {
      // Fallback if onReturnToAllFiles is not provided
      navigate('/files', { replace: true });
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage.toString(), 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  // Functions to open modals
  const handleEditClick = (row) => {
    //console.log("Edit clicked for row ID:", row.id, row.title);
    setSelectedRow(row);
    if (row.content_type === "note") {
      setEditNoteModalOpen(true);
    } else if (row.issyllabus) {
      setEditSyllabusModalOpen(true);
    } else {
      setEditModalOpen(true);
    }
  };

  const handleReplaceFile = (fileId, newFileName) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, status: "Replacing...", filename: newFileName || file.filename } : file
      )
    );
    setReplacingFileId(fileId);
  };

  const handleUpdateTitle = (fileId, newFileName) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, status: "Updating Title...", filename: newFileName || file.filename } : file
      )
    );
    setReplacingFileId(fileId);
  };
  
  return (
    <Sheet variant="plain">
      <EnhancedTableToolbar
        totalItems={totalItems}
        totalModules={totalModules}
        totalFiles={totalFiles}
        rowsPerPage={rowsPerPage}
        page={page}
        statusCounts={statusCounts}
        currentView={currentView}
        onBackToAllFiles={handleBackToAllFiles}
        modules={modules}
      />
      {/* Show the NoModuleFiles component if in a module view with no files */}
      {currentView !== 'all' && displayedRows.length === 0 ? (
        <NoModuleFiles />
      ) : (
      <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} className="table-toolbar">
        { /* Table Actions Component */}
        <FileTableActions 
          selected={selected} 
          setSelected={setSelected}
          files={files}
          setFiles={setFiles}
          modules={modules}
          setModules={setModules}
        />
        { /* Pagination Component */}
        <Pagination 
          rowsPerPage={rowsPerPage}
          page={page}
          totalItems={totalFiles + totalModules}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
        />
        { /* End Pagination Component */}
      </Stack>
        <Alert
          variant="soft"
          color="neutral"
          startDecorator={<TouchAppIcon />}
          sx={{
            display: 'none', // Hidden by default
            '@media (max-width: 820px)': {
              marginTop:'1rem',
              display: 'flex',
              justifyContent: 'center',
            }
          }}
          >
          Scroll horizontally to view full table
        </Alert>
      <Box sx={{ 
        width: '100%', 
        overflowX: 'auto', 
        '@media (max-width: 820px)': {
          width: '100vw',
          margin: '0 -1rem',
        }
      }}>
      <Table
        hoverRow
        className="file-table"
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={files.length}
          page={page}
          rowsPerPage={rowsPerPage}
          selectableRowCount={selectableRowCount}  
        />
        <tbody>
          {stableSort(displayedRows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
              const isItemSelected = selected.some((selectedItem) => selectedItem.id === row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              const hasErrorStatus = hasError(row.status);
              const isProcessing = isFileProcessing(row);
              const isModule = row.isModule;

              return (
                <tr
                  tabIndex={-1}
                  role="row"
                  aria-rowtype={isModule ? "module" : "file"}
                  key={row.id}
                  style={{
                    ...(isItemSelected || (isProcessing && row.status !== "Enhancing")
                      ? {
                          '--TableCell-dataBackground':
                            'var(--joy-palette-neutral-200)',
                          '--TableCell-headBackground':
                            'var(--joy-palette-neutral-200)',
                        }
                      : {}),
                  }}
                >
                  <td>
                    <Stack direction="row" spacing={1} alignItems="flex-start">
                    <Checkbox
                      color="primary"
                      variant="outlined"
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, row)}
                      aria-label={`Select ${row.title}`}
                      disabled={!isRowSelectable(row)}
                      slotProps={{
                        input: {
                          'aria-labelledby': labelId,
                        },
                      }}
                    />
                    </Stack>
                  </td>
                  <th id={labelId} scope="row">
                    <Stack direction="row" spacing={1} alignItems="flex-start">
                    {isProcessing && row.status !== "Enhancing" && row.status !== "Processing" && (
                      <CircularProgress
                        color="primary"
                        size="sm"
                        variant="soft"
                      />
                    )}
                    {hasErrorStatus && (
                      <ErrorOutlineIcon color="danger"/>
                    )}
                      <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" gap={0}>
                        {isModule ? (
                          <Link
                            component="button"
                            startDecorator={<FolderIcon fontSize="xl"/>}
                            onClick={() => handleModuleClick(row.id.replace('module-', ''), row.title)}
                            role="button"
                            aria-label={`Open module: ${row.title}`}
                            sx={{ 
                              '&:hover': { backgroundColor: 'none' }
                            }}
                          >
                            {row.title}
                          </Link>
                        ) : isProcessing ? (
                          <Typography>
                            {row.title}
                          </Typography>
                        ) : (
                          <Link
                            component="button"
                            onClick={() => handleEditClick(row)}
                            disabled={isProcessing}
                            role="button"
                            aria-label={`Edit file: ${row.title}`}
                          >
                            {row.title}
                          </Link>
                        )}
                        {row.status === "success" ? (
                          null 
                        ) : (
                          <Typography level="component-sm" role="status">
                          {row.status}
                          </Typography>
                        )}
                      </Stack>
                      {row.issyllabus && (  
                        <Chip  
                          size="sm"  
                          variant="soft"  
                          color="primary"  
                          aria-label={`${row.title} is a syllabus`}
                        >  
                          Syllabus  
                        </Chip>  
                      )}
                      {row.content_type === "note" && ( 
                        <Chip  
                          size="sm"  
                          variant="soft"  
                          color="primary" 
                          aria-label={`${row.title} is a note`} 
                        >  
                          Note  
                        </Chip>  
                      )}
                    </Stack>
                  </th>
                  <td>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                    {isModule || isProcessingStatus(row.status) || hasError(row.status) ? (
                      <Typography level='component-sm' aria-hidden="true">-</Typography>
                    ) : (
                      <>
                        {row.isactive ? (
                          <>
                            <CheckIcon aria-hidden="true"/>
                            <Typography level='component-sm'>Active</Typography>
                          </>
                        ) : (
                          <Typography level='component-sm'>Inactive</Typography>
                        )}
                      </>
                    )}
                    </Stack>
                  </td>
                  <td>
                      {isModule || isProcessingStatus(row.status) || hasError(row.status) ? (
                        <Typography level='component-sm' aria-hidden="true">-</Typography>
                      ) : (
                        <Typography level='component-sm'>
                          {row.isoptional ? <>Optional</> : <>Core</>}
                        </Typography>
                      )}
                  </td>
                  <td>
                    {formatDate(row.created_at)}
                  </td>
                </tr>
              );
            })}
          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                "--TableRow-hoverBackground": "transparent",
              }}
            >
              <td colSpan={4} aria-hidden />
            </tr>
          )}
        </tbody>
      </Table>
      </Box>
      <EditFileModal 
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        file={selectedRow}
        setFiles={setFiles}
        handleReplaceFile={handleReplaceFile}
        refresh={refresh}
        handleUpdateTitle={handleUpdateTitle}
      />
      <EditSyllabusModal
        open={editSyllabusModalOpen}
        onClose={() => setEditSyllabusModalOpen(false)}
        onSave={() => {
          refresh();
          setEditSyllabusModalOpen(false); 
        }}
      />
      <CreateNoteModal
        open={editNoteModalOpen}
        onClose={() => setEditNoteModalOpen(false)}
        refresh={refresh}
        edit={true}
        file_id={selectedRow?.file_id}
        currentModuleId={currentView !== 'all' ? currentView : null}
      />
      </>
      )}
    </Sheet>
  );
}
