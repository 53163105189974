import {
  Modal,
  ModalClose,
  ModalDialog,
  DialogTitle,
  DialogContent,
} from '@mui/joy';
import { useState, useEffect } from 'react';
import axios from 'axios';
import UpdateSyllabusForm from './UpdateSyllabusForm';
import "./Modals.css";

export default function EditSyllabusModal({ open, onClose, onSave }) {
  const [currSyllabusFileId, setCurrSyllabusFileId] = useState(null);

  useEffect(() => {
    const fetchSyllabusFileId = async () => {
      const response = await axios.get(`/api/course_files_retrieve?issyllabus=true`);
      if (response.data.length > 0) {
        setCurrSyllabusFileId(response.data[0].file_id);
      }
    };
    fetchSyllabusFileId();
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        layout="fullscreen"
        variant="outlined"
        size="lg"
        className="modal-container"
      >
        <DialogTitle className="modal-name">Update Syllabus</DialogTitle>
        <ModalClose aria-label="Close Dialog" />
        <DialogContent>
          <UpdateSyllabusForm onCancel={onClose} onSave={onSave} currSyllabusFileId={currSyllabusFileId} />
        </DialogContent>
      </ModalDialog>
    </Modal>
  )
}