import React, { useState, useEffect } from 'react';
import { 
    Alert,
    Button, 
} from '@mui/joy';
import FlagIcon from '@mui/icons-material/Flag';
import { useSubscription } from '../../context/SubscriptionContext';

const TrialUploadCounter = ({ onCountChange, files }) => {
    const { subscription, isTrialExpired } = useSubscription();
    const [uploadCount, setUploadCount] = useState(0);

    useEffect(() => {
        const fetchUploadCount = async () => {
            try {
                const count = files.length || 0;
                setUploadCount(count);
                if (onCountChange) {
                    onCountChange({
                        count,
                        isDisabled: subscription?.tier_id === 1 && (count >= 10 || isTrialExpired)
                    });
                }
            } catch (error) {
                console.error('Error fetching upload count:', error);
            }
        };

        if (subscription?.start_date) {
            fetchUploadCount();
        }
    }, [subscription, onCountChange, isTrialExpired]);

    if (!subscription || subscription?.tier_id !== 1) return null;

    const isUploadDisabled = uploadCount >= 10 || isTrialExpired;

    return (
        <Alert
          color={isUploadDisabled ? "primary" : "neutral"}
          variant={isUploadDisabled ? "outlined" : "soft"}
          startDecorator={<FlagIcon />}
          endDecorator={
                isUploadDisabled ? (
                    <Button 
                        size="sm" 
                        variant="solid" 
                        color="primary"
                        component="a"
                        href="/subscriptions?upgrade-trial=true"
                    >
                        Select a Plan
                    </Button>
                ) : null
            }
          sx={{
            marginBottom: '1rem'
          }}
          >
          {isTrialExpired ? 
            "Trial period has ended. Please upgrade to continue uploading files." :
            `${uploadCount}/10 Free Files Used`
          }
        </Alert>
    );
};

export default TrialUploadCounter;