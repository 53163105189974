export default class WorkflowConfig {
  constructor(config = {}) {
    this.preprocess = {
      model: config.preprocess?.model,
      max_tokens: config.preprocess?.max_tokens,
      temperature: config.preprocess?.temperature,
      reasoning_effort: config.preprocess?.reasoning_effort,
      followup_questions: config.preprocess?.followup_questions,
    }
    this.retrieval = {
      type: config.retrieval?.type,
      max_tokens_full_docs: config.retrieval?.max_tokens_full_docs,
      window_size_full_docs: config.retrieval?.window_size_full_docs,
      cosine_chunks_to_get: config.retrieval?.cosine_chunks_to_get,
      tfidf_chunks_to_get: config.retrieval?.tfidf_chunks_to_get,
      rerank_chunks_to_get: config.retrieval?.rerank_chunks_to_get,
      tfidf_min_cutoff: config.retrieval?.tfidf_min_cutoff,
      rerank_min_cutoff: config.retrieval?.rerank_min_cutoff,
      output_format: config.retrieval?.output_format || 'text',
    }
    this.response = {
      model: config.response?.model,
      max_tokens: config.response?.max_tokens,
      temperature: config.response?.temperature,
      reasoning_effort: config.response?.reasoning_effort,
      instructions: config.response?.instructions,
      output_format: config.response?.output_format,
      style: config.response?.style,
      use_homework_filter: config.response?.use_homework_filter || false,
      use_structured_output: config.response?.use_structured_output || false,
    }
  }
}