import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    List,
    ListItem,
    Stack,
    Typography
} from '@mui/joy';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Close from "@mui/icons-material/Close";
import FlagIcon from '@mui/icons-material/Flag';
import LogoIcon from '../assets/logoMobile.svg';
import LoadingIcon from '../assets/LoadingLogo.gif';
import RotatingMessages from './RotatingMessages.js';
import LatexRenderer from './LatexRenderer';
import TextRenderer from './TextRenderer';
import FootnoteButton from './FootnoteButton';
import './Header.css';
import './QuestionAnswerCard.css';

export default React.memo(function QuestionAnswerCard({ id, question, answer, footnotes, isLoading, orgId, courseId, questionId, userId }) {
    const [copySuccess, setCopySuccess] = useState(false);
    const [copyFailure, setCopyFailure] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [isFlagged, setIsFlagged] = useState(false);
    const [showFootnotes, setShowFootnotes] = useState(false);
    const [activeFootnote, setActiveFootnote] = useState(null);
    const questionRef = useRef(null);
    const footnotesRef = useRef({});
    const announcerRef = useRef(null);
    const answerContainerRef = useRef(null);

    const messages = [
        'Response processing...',
        'Searching class documents...',
        'Hunting for the best answer...',
        'Consulting notes from the prof...',
        'Integrating handouts...',
        'Asking HAL for advice on how to answer...',
        'Reticulating splines...'
    ];

    const scrollToQuestion = () => {
        if (questionRef.current) {
            const isMobile = window.innerWidth <= 540;
            const yOffset = isMobile ? -130 : -100;
            const y = questionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            
            window.scrollTo({
                top: y,
                behavior: 'smooth'
            });
        }
    };

    const scrollAndAnchorQuestion = () => {
        if (questionRef.current) {
            const isMobile = window.innerWidth <= 540;
            const yOffset = isMobile ? -130 : -100;
            const y = questionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            
            window.scrollTo({
                top: y,
                behavior: 'smooth'
            });

            // Add a class to fix the question at the top
            questionRef.current.classList.add('anchored');
        }
    };

    useEffect(() => {
        if (question) {
            if (isLoading) {
                scrollToQuestion();
            } else if (answer) {
                scrollAndAnchorQuestion();
                if (answerContainerRef.current) {
                    announcerRef.current.textContent = "Answer loading";
                }
                if (announcerRef.current) {
                    announcerRef.current.textContent = "Answer is now available.";
                }
            }
        }
    }, [question, isLoading, answer]);

    const removeAnchoring = () => {
        if (questionRef.current) {
            questionRef.current.classList.remove('anchored');
        }
    };

    useEffect(() => {
        return () => removeAnchoring();
    }, []);

    useEffect(() => {
        removeAnchoring();
        if (question) {
            if (isLoading) {
                scrollToQuestion();
            } else if (answer) {
                scrollAndAnchorQuestion();
            }
        }
    }, [question, isLoading, answer]);

    const scrollToFootnote = useCallback((footnoteName) => {
        if (footnotesRef.current[footnoteName]) {
            footnotesRef.current[footnoteName].scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center' 
            });
            footnotesRef.current[footnoteName].focus();
            // Announce the footnote content
            if (announcerRef.current) {
            announcerRef.current.textContent = `Footnote ${footnoteName}: ${footnotes[parseInt(footnoteName) - 1]}`;
            }
        }
    }, [footnotes]);

    //Uncomment when debugging json formatting changes
    /*useEffect(() => {
      if (answer) {
        console.log('Raw answer JSON:', JSON.stringify(answer));
      }
    }, [answer]);*/

    const renderAnswer = useCallback(() => {
        if (!answer) return null;

        const hasFootnotes = footnotes && Object.keys(footnotes).length > 0;

        // Split the answer into code blocks and non-code blocks
        const blocks = answer.split(/(```[\s\S]*?```)/);

        return blocks.map((block, blockIndex) => {
            if (block.startsWith('```') && block.endsWith('```')) {
                // This is a code block
                const code = block.slice(3, -3).trim();
                const language = code.split('\n')[0].trim();
                const codeContent = code.split('\n').slice(1).join('\n');
                
                return (
                    <SyntaxHighlighter 
                        language={language || 'text'} 
                        style={a11yLight} 
                        key={`code-${blockIndex}`}
                    >
                        {codeContent}
                    </SyntaxHighlighter>
                );
            } else {
                // Split block into paragraphs and potential lists
                const lines = block.split('\n');
                let elements = [];
                let currentList = [];
                let currentListType = null; // 'ordered' or 'unordered'


                const processCurrentList = () => {
                    if (currentList.length > 0) {
                        const listItems = currentList.map((item, idx) => {
                            const itemParts = item
                                .replace(/^\d+\.\s*|-\s*|\*\s*/, '') // Remove list markers
                                .split(/(\[\d+\])/); // Split on footnotes

                            return (
                                <ListItem key={idx}>
                                    {itemParts.map((part, partIndex) => {
                                        if (part.match(/\[\d+\]/)) {
                                            if (hasFootnotes) {
                                                const footnoteName = part.slice(1, -1);
                                                return (
                                                    <FootnoteButton
                                                        key={`footnote-${partIndex}`}
                                                        footnoteName={footnoteName}
                                                        setShowFootnotes={setShowFootnotes}
                                                        setActiveFootnote={setActiveFootnote}
                                                        scrollToFootnote={scrollToFootnote}
                                                    />
                                                );
                                            }
                                            return '';
                                        }
                                        return part.match(/\\\(.*?\\\)|\\\[.*?\\\]/) ?
                                            <LatexRenderer key={`latex-${partIndex}`} text={part} /> : 
                                            <TextRenderer key={`text-${partIndex}`} text={part} />;
                                    })}
                                </ListItem>
                            );
                        });

                        elements.push(
                            <List 
                                key={`list-${elements.length}`}
                                marker={currentListType === 'ordered' ? 'decimal' : 'disc'}
                                size='lg'
                                sx={{ 
                                    paddingLeft: 2,
                                    marginBottom: 2,
                                    '& .MuiListItem-root': {
                                        paddingY: 0.5,
                                    },
                                    listStyleType: currentListType === 'ordered' ? 'decimal' : 'disc',
                                    counterReset: currentListType === 'ordered' ? 'list-item 0' : 'none'
                                }}
                            >
                                {listItems}
                            </List>
                        );

                        currentList = [];
                        currentListType = null;
                    }
                };

                lines.forEach((line, lineIndex) => {
                    const trimmedLine = line.trim();
                    
                    // Skip empty lines
                    if (!trimmedLine) {
                        processCurrentList();
                        return;
                    }

                    // Check for list items
                    const orderedListMatch = trimmedLine.match(/^\d+\.\s/);
                    const unorderedListMatch = trimmedLine.match(/^[-*]\s/);

                    if (orderedListMatch || unorderedListMatch) {
                        const newListType = orderedListMatch ? 'ordered' : 'unordered';
                        
                        // If we're starting a new type of list, process the old one
                        if (currentListType && currentListType !== newListType) {
                            processCurrentList();
                        }
                        
                        currentListType = newListType;
                        currentList.push(trimmedLine);
                    } else {
                        // Not a list item, process any existing list
                        processCurrentList();
                        
                        // Check if this paragraph is the unwanted footnotes section
                        if (trimmedLine.startsWith('Footnotes:')) return;

                        // Process regular paragraph
                        const paragraphParts = trimmedLine.split(/(\[\d+\])/);
                        elements.push(
                            <Typography level="body-lg" mb={2} key={`para-${elements.length}`}>
                                {paragraphParts.map((part, partIndex) => {
                                    if (part.match(/\[\d+\]/)) {
                                        if (hasFootnotes) {
                                            const footnoteName = part.slice(1, -1);
                                            return (
                                                <FootnoteButton
                                                    key={`footnote-${partIndex}`}
                                                    footnoteName={footnoteName}
                                                    setShowFootnotes={setShowFootnotes}
                                                    setActiveFootnote={setActiveFootnote}
                                                    scrollToFootnote={scrollToFootnote}
                                                />
                                            );
                                        }
                                        return '';
                                    }
                                    return part.match(/\\\(.*?\\\)|\\\[.*?\\\]/) ?
                                        <LatexRenderer key={`latex-${partIndex}`} text={part} /> : 
                                        <TextRenderer key={`text-${partIndex}`} text={part} />;
                                })}
                            </Typography>
                        );
                    }
                });

            // Process any remaining list items
            processCurrentList();

            return elements;
            }
        });
    }, [answer, footnotes, setShowFootnotes, setActiveFootnote, scrollToFootnote]);

    const memoizedAnswer = useMemo(() => renderAnswer(), [renderAnswer]);

    const renderFootnotes = () => {
        return footnotes.map((footnote, index) => (
          <Box 
            key={index} 
            sx={{ 
                display: 'flex', 
                paddingBottom:'.5rem', 
                backgroundColor: activeFootnote === (index + 1).toString() ? 'var(--joy-palette-neutral-100)' : 'transparent' 
            }}
            ref={el => footnotesRef.current[index + 1] = el}
            tabIndex="-1"
            role="region"
            aria-label={`Footnote ${index + 1}`}
            >
            <Box ml={0.5} mr={2} mt={1}>
              <Typography level="title-md" component="span">{index + 1}</Typography>
            </Box>
            <Box sx={{ overflow:'auto', wordWrap:'break-word',  }}>
              <Typography pt={1} level="body-sm">
                <LatexRenderer text={footnote} />
              </Typography>
            </Box>
          </Box>
        ));
      };

    const toggleFootnotes = () => {
        setShowFootnotes(!showFootnotes);
    };

    const handleFeedback = async (value) => {
        try{
            let prevFeedback = feedback;
            if (feedback === value) {
                setFeedback(null);
                //console.log(`Feedback removed for question ${id}`);
                const response = await axios.patch(`/api/update_response_rating/${courseId}/${questionId}`, {
                    rating: null
                });
                if (response.status === 200) {
                    //console.log('Successful API Response - new Rating response:', response.data?.rating);
                    //console.log('Successful API Response - message:', response.data?.message);
                    // Double ensure feedback is removed
                    setFeedback(null);
                } else {
                    //console.error('Error updating rating status:', response.data.status);
                    //console.log('Error message:', response.data?.message);
                    // Revert the feedback status to the original state
                    setFeedback(prevFeedback);
                }
            } else {
                setFeedback(value);
                //console.log(`Feedback submitted for question ${id}: ${value}`);
                const response = await axios.patch(`/api/update_response_rating/${courseId}/${questionId}`, {
                    rating: (value === "thumbs_up") ? true : (value === "thumbs_down") ? false : null
                });
                if (response.status === 200) {
                    //console.log('Successful API Response - new Rating response:', response.data?.rating);
                    //console.log('Successful API Response - message:', response.data?.message);
                    // Double ensure feedback is gone to the right new value
                    setFeedback(value);
                } else {
                    //console.error('Error updating rating status:', response.data.status);
                    //console.log('Error message:', response.data?.message);
                    // Revert the feedback status to the original state
                    setFeedback(prevFeedback);
                }
            }
        } catch (error) {
            //console.error('Error updating feedback status:', error.response ? error.response.data.message : error.message);
            // Revert the feedback status back to null
            setFeedback(null);
        }
    };

    const handleFlagUnanswered = () => {
        //console.log(`Flagged unanswered question ${id}`);
        setIsFlagged(true);
    };

    const getButtonColor = () => {
        if (copySuccess) return 'success';
        if (copyFailure) return 'danger';
        return 'primary';
    };

    const copyToClipboard = async () => {
        try {
            let textToCopy = `${question}\n\n${answer}`;

            // Add footnotes if they exist
            if (footnotes && footnotes.length > 0) {
                textToCopy += '\n\nFootnotes:\n';
                footnotes.forEach((footnote, index) => {
                    textToCopy += `[${index + 1}] ${footnote}\n`;
                });
            }
            await navigator.clipboard.writeText(textToCopy);
            setCopySuccess(true);
            setCopyFailure(false);
            setTimeout(() => setCopySuccess(false), 3000);
        } catch (err) {
            setCopyFailure(true);
            setCopySuccess(false);
            //console.error('Failed to copy: ', err);
        }
    };

    return (
        <Grid container spacing={2} sx={{ marginBottom: '1rem', paddingBottom: '1.2rem', margin: '0 auto', width:'100%' }}>
            <Grid xs={12} ref={questionRef}>
                <Card variant="plain" size="md" sx={{ display: 'inline-block', boxShadow: 'sm', "--Card-radius": '0.875rem' }}>
                    <Typography level="title-lg">{question}</Typography>
                </Card>
            </Grid>
            {isLoading ? (
                <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'center'}}>
                    <Grid>
                        <img src={LoadingIcon} alt="" style={{ width: '3rem', height: '3rem', display: 'block', marginLeft: '1.5rem' }} />
                    </Grid>
                    <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
                        <Typography level="title-lg"><RotatingMessages messages={messages} /></Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} mt={1} xs={12} sx={{ paddingTop: '1rem' }}>
                    <Grid className='hide-mobile'>
                        <img src={LogoIcon} alt="" style={{ width: '3rem', height: '3rem', display: 'block', marginLeft: '1.5rem' }} />
                    </Grid>
                    <Grid xs sx={{ height: 'auto', flexGrow: '1', outline: 'none' }}
                        ref={answerContainerRef}
                        tabIndex="-1"
                        aria-live="polite">
                        {memoizedAnswer}
                        {answer && (
                            <>
                                <Grid container spacing={2} sx={{ justifyContent:"space-between", alignItems:"center"}} mt={1}>
                                    <Grid xs={12} sm>
                                        {footnotes && Object.keys(footnotes).length > 0 && (
                                            <Button 
                                                onClick={toggleFootnotes} 
                                                variant="plain" 
                                                endDecorator={showFootnotes ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}>
                                                {showFootnotes ? 'Hide' : 'View'} footnotes
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid xs={12} sm={8} sx={{ display:'flex', alignItems:'center', justifyContent: 'flex-end'}} spacing={1}>
                                        <Button
                                            variant="plain"
                                            style={{ color: getButtonColor() }} 
                                            onClick={copyToClipboard} 
                                            startDecorator={copySuccess ? <CheckCircleIcon /> : <ContentCopyIcon />}
                                        >
                                            {copySuccess ? "Copied" : "Copy"}
                                        </Button>
                                        <Stack direction="row" spacing={2} justifyContent="space-evenly" alignItems="center"
                                            sx={{
                                                backgroundColor: 'var(--joy-palette-neutral-100)',
                                                padding: '0.25rem 1rem',
                                                borderRadius: 'sm'
                                            }}>
                                            <Typography level="component-md">Did this answer your question?</Typography>
                                            <IconButton
                                                aria-label="Answered my question"
                                                onClick={() => handleFeedback('thumbs_up')}
                                                color='primary'
                                            >
                                                {feedback === 'thumbs_up' ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                                            </IconButton>
                                            <IconButton
                                                aria-label="Did not answer my question"
                                                onClick={() => handleFeedback('thumbs_down')}
                                                color='primary'
                                            >
                                                {feedback === 'thumbs_down' ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
                                            </IconButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                {showFootnotes && footnotes && footnotes.length > 0 && (
                                    <Card variant="plain" size="sm"
                                        sx={{
                                            boxShadow: 'sm',
                                            position: 'relative',
                                            marginTop: '0.75rem'
                                        }}
                                    >
                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography level="title-md">Footnotes</Typography>
                                            <Button
                                                variant="plain"
                                                endDecorator={<Close />}
                                                onClick={toggleFootnotes}
                                            >
                                                Hide
                                            </Button>
                                        </Stack>
                                        <Box mb={1}>
                                            {renderFootnotes()}
                                        </Box>
                                    </Card>
                                )}
                            </>
                        )}
                        {!answer && (
                            <Stack direction="row" alignItems="center" spacing={1} mt={2}>
                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    startDecorator={<FlagIcon />}
                                    onClick={handleFlagUnanswered}
                                    disabled={isFlagged}
                                >
                                    {isFlagged ? "Flagged" : "Flag for Professor"}
                                </Button>
                                {isFlagged && (
                                    <Typography level="component-md" color="primary" startDecorator={<CheckCircleIcon/>}>
                                        Question flagged for professor
                                    </Typography>
                                )}
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            )}
            <div 
                ref={announcerRef}
                aria-live="polite"
                aria-atomic="true"
                style={{ position: 'absolute', height: '1px', width: '1px', overflow: 'hidden', clip: 'rect(1px, 1px, 1px, 1px)' }}
              />
        </Grid>
    );
});