import React from 'react';
import {
  Box,
  FormControl,
  Input,
  Tooltip,
  FormHelperText,
  FormLabel,
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';

export default function InputWithTooltip({
  label,
  value,
  onChange,
  tooltip,
  min,
  max,
  step
}) {
  return (
    <FormControl>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <FormLabel sx={{ marginBottom: '0px' }}>{label}</FormLabel>
        <Tooltip
          title={tooltip}
          variant="soft"
          placement="top-start"
        >
          <InfoIcon fontSize="sm" />
        </Tooltip>
      </Box>
      <Input
        type="number"
        value={value}
        onChange={onChange}
        slotProps={{
          input: {
            min: min,
            max: max,
            step: step
          }
        }}
      />
      <FormHelperText>{min}-{max}</FormHelperText>
    </FormControl>
  );
}