import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import MediaQuery from "react-responsive";
import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/joy';
import QuestionAnswerCard from '../components/QuestionAnswerCard';
import StudentAuth from '../components/StudentAuth';
import Container from '../components/Container';
import HelpIcon from '@mui/icons-material/HelpOutline';
import LogoIcon from '../assets/logoMobile.svg';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import { useSubscription } from '../context/SubscriptionContext';
import useChatbot from '../hooks/useChatbot';
import ChatbotInputForm from '../components/form-elements/ChatbotInputForm';
import { chatbotErrors } from '../config/chatbotErrors';
import { Initializing, GeneralError, CourseNotFound, CourseInactive, SubscriptionExpired } from '../components/ChatbotStates';
import { PROMPT_SETTINGS_DEFAULT } from '../config/promptSettings';

const StudentChatbot = forwardRef((props, ref) => {
  const location = useLocation();
  const courseUrl = location.pathname;
  const [courseName, setCourseName] = useState('');
  const [promptSettings, setPromptSettings] = useState(PROMPT_SETTINGS_DEFAULT);
  //Student Authentication
  const [requiresAuth, setRequiresAuth] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //Subscription Limitations
  const { subscription, isTrialExpired } = useSubscription();

  const {
    questionsAndAnswers,
    isInitializing,
    error,
    setError,
    askQuestion,
    initializeChatbot,
  } = useChatbot();

  useEffect(() => {
    document.title = "Ask a Question - All Day TA";
  }, []);

  // Add check for subscription access
  const hasAccess = useCallback(() => {
    return subscription?.tier_id !== 1 || !isTrialExpired;
  }, [isTrialExpired, subscription]);

  useEffect(() => {
    const initializeData = async () => {
      // First get course settings to check auth requirements
      try {
        const settingsResponse = await axios.get('/api/course_settings', {
          params: { course_url: courseUrl }
        });

        setCourseName(settingsResponse.data.course_name);

        const promptSettings = settingsResponse.data.prompt_default;
        if (promptSettings) {
          const [length, style] = promptSettings.split('-');
          setPromptSettings({ length, style });
        }

        let authRequired = false;
        authRequired = settingsResponse.data.auth_type && settingsResponse.data.auth_type !== 'none';
        setRequiresAuth(authRequired);

        let email = '';
        if (authRequired) {
          const urlParams = new URLSearchParams(window.location.search);
          const login_token = urlParams.get('login_token');

          try {
            if (login_token) {
              const response = await axios.post('/api/verify_login_token', {
                token: login_token
              });
              if (response.data.email) {
                email = response.data.email || '';
              }
            }
          } catch (e) {
            setIsAuthenticated(false);
            email = '';
            // token is invalid
          } finally {
            try {
              const verifyResponse = await axios.post('/api/verify_student', {
                email: email.toLowerCase(),
                course_url: courseUrl
              });
              if (verifyResponse.data.verified) {
                setIsAuthenticated(true);
              } else {
                setIsAuthenticated(false);
              }
            } catch (e) {
              setIsAuthenticated(false);
            }
          }
        } else {
          // If no auth required, just set authenticated
          setIsAuthenticated(true);
        }

        if (!hasAccess()) {
          setError('subscription_expired');
          return;
        }

        const disabled = !settingsResponse.data.active;
        const disable_start = settingsResponse.data.disable_start_date;
        const disable_end = settingsResponse.data.disable_end_date;
        // If disabled and there are no dates this course is inactive. If disabled we are between the start and end date this course is also inactive
        if (disabled && ((!disable_start && !disable_end) || (Date.parse(disable_start) < Date.now() && Date.now() < Date.parse(disable_end)))) {
          setError('course_inactive');
          return;
        }

        initializeChatbot(courseUrl)
      } catch (e) {
        if (e?.response.status === 404) setError(chatbotErrors.course_not_found);
        else setError(chatbotErrors.general_error);
      }
    };

    initializeData();
  }, [courseUrl, subscription, isTrialExpired, hasAccess, initializeChatbot, setError]);

  const CourseNameDisplay = ({ courseName }) => (
    <Box sx={{ maxWidth: '340px' }}>
      <Typography level="h1" color="primary" sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
        {courseName}
      </Typography>
    </Box>
  );

  const HelpfulTips = () => (
    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems: 'flex-start' }}>
      <Grid>
        <img
          src={LogoIcon}
          alt=""
          style={{
            width: '3rem',
            height: '3rem',
            display: 'block',
            marginLeft: '1.5rem',
          }}
        />
      </Grid>
      <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
        <Typography level="h2" mb={2}>Hello! I'm an AI assistant for {courseName}.</Typography>
        <Typography level="body-lg" mb={2}>Your professor has trained me to answer questions about anything you'd like to know about class concepts, logistics, and topics. I'll be here all day, all night, and all term.</Typography>
        <Typography level="title-lg">Helpful Tips</Typography>
        <List component="ul" marker="disc" size="lg" sx={{ "--ListItem-minHeight": "36px" }}>
          <ListItem>Ask your question in the language you are most comfortable in and I will answer in that language.</ListItem>
          <ListItem>I do my best, but just like your friend who always gets an A, I'm not perfect.</ListItem>
          <ListItem>If something seems unclear, ask me again in another way and I'll try to explain.</ListItem>
        </List>
      </Grid>
    </Grid>
  );

  // Handle authentication success
  const handleAuthenticated = (email) => {
    console.log('Authentication successful for email:', email);
    setIsAuthenticated(true);
  };

  let body = (
    <Container>
      <Grid container direction="row" justifyContent="center" sx={{ flex: 1, overflowY: 'auto', paddingBottom: '3rem' }}>
        <Grid xs={12} sm={12} md={8}>
          <Stack direction="column" spacing={3} alignItems="center">
            {questionsAndAnswers.length === 0 && <HelpfulTips />}
            {questionsAndAnswers.slice().reverse().map((qa) => (
              <QuestionAnswerCard
                key={qa.id}
                id={qa.id}
                question={qa.question}
                answer={qa.answer}
                footnotes={qa.footnotes}
                isLoading={qa.isLoading}
                orgId={qa.question_log_org_id}
                courseId={qa.question_log_course_id}
                questionId={qa.question_log_question_id}
                userId={qa.question_log_user_id}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" sx={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
        <Grid xs={12} sm={12} md={8}>
          <ChatbotInputForm askQuestion={askQuestion} promptSettings={promptSettings} isAuthenticated={isAuthenticated} />
        </Grid>
      </Grid>
    </Container>
  )

  if (isInitializing && !error) body = <Initializing />;
  if (error === chatbotErrors.course_not_found) body = <CourseNotFound />;
  if (error === chatbotErrors.general_error) body = <GeneralError />;
  if (error === chatbotErrors.subscription_expired) body = <SubscriptionExpired />;
  if (error === chatbotErrors.course_inactive) body = <CourseInactive />;
  if (requiresAuth && !isAuthenticated) body = <StudentAuth onAuthenticated={handleAuthenticated} />;

  return (
    <Box>
      <header className="header-wrapper">
        <MediaQuery minWidth={541}>
          <Box className="left-wrapper student-left-wrapper">
            <a href="https://alldayta.com" target="_blank" rel="noreferrer noopener" aria-label="All Day TA Website"><img src={Logo} alt="All Day TA" className="logo" /></a>
            <a href="https://alldayta.com" target="_blank" rel="noreferrer noopener" aria-label="All Day TA Website" className="logo-mobile"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
            <CourseNameDisplay courseName={courseName} />
          </Box>
          <Box className="right-wrapper">
            <Button
              variant="plain"
              component="a"
              target="_blank"
              href="https://alldayta.com/student-faqs"
              startDecorator={<HelpIcon />}
            >
              Help
            </Button>
          </Box>
        </MediaQuery>
        <MediaQuery maxWidth={540}>
          <Box className="left-wrapper">
            <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
            <Button
              variant="plain"
              component="a"
              target="_blank"
              href="https://alldayta.com/student-faqs"
              startDecorator={<HelpIcon />}
            >
              Help
            </Button>
          </Box>
          <Box className="right-wrapper">
            <CourseNameDisplay courseName={courseName} />
          </Box>
        </MediaQuery>
      </header>
      {body}
    </Box>
  );
});

StudentChatbot.isStudentChatbot = true;

export default StudentChatbot;
