import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormHelperText,
  FormControl,
  FormLabel,
  Grid,
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import Container from '../components/Container';
import DragAndDropSyllabus from '../components/DragAndDropSyllabus';
import CourseUrlField from '../components/form-elements/CourseUrlField';
import CourseInfoFields from '../components/form-elements/CourseInfoFields';
import FormErrorAlert from '../components/form-elements/FormErrorAlert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getCurrentAdmin } from '../ProfessorsRetrieveAPI';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingIcon from '../assets/LoadingLogo.gif';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import { getAssociatedCoursesListForAdmin } from '../CourseRetrieveAPI';
import { getOrgName } from '../OrgRetrieveAPI';

const FileTypeAlert = ({ onClose }) => {
    return (
        <Alert
          variant="outlined"
          color="primary"
          startDecorator={<InfoOutlinedIcon />}
          sx={{ mt:2, mb: 2 }}
          role="alert"
          aria-live="polite"
        >
          PDF files may take longer to process (roughly 10-15 seconds per page).
        </Alert>
  );
};

export default function CreateCourse({ headerRef }) {
  const navigate = useNavigate();
  const [adminInSession, setAdminInSession] = useState(-1);
  const [hasOtherCourses, setHasOtherCourses] = useState(false);
  const [showManualInput, setShowManualInput] = useState(false);
  const [isPopulating, setIsPopulating] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //Syllabus
  const [syllabusFile, setSyllabusFile] = useState(null);
  const [tempSyllabusFile, setTempSyllabusFile] = useState(null);
  const [syllabusText, setSyllabusText] = useState('');
  const [syllabusFileName, setSyllabusFileName] = useState('');
  const [isReplacing, setIsReplacing] = useState(false);
  const [isPdfUploaded, setIsPdfUploaded] = useState(false);
  //Course Information
  const [courseName, setCourseName] = useState('');
  const [description, setDescription] = useState('');
  const [professors, setProfessors] = useState('');
  const [teachingAssistants, setTeachingAssistants] = useState('');
  const [termName, setTermName] = useState('');
  // Used for course URL
  const [courseUrl, setCourseUrl] = useState('');
  const [fullCourseUrl, setFullCourseUrl] = useState('');
  const [urlError, setUrlError] = useState("");
  //Alerts and errors
  const [statusMessage, setStatusMessage] = useState('');
  const [showSubscriptionSnackbar, setShowSubscriptionSnackbar] = useState(false);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
  });
  const [syllabusErrors, setSyllabusErrors] = useState('');

  useEffect(() => {
      document.title = "Create a Course - All Day TA";

      const urlParams = new URLSearchParams(window.location.search);
      const success = urlParams.get('success');
      if (success === 'true') {
        setShowSubscriptionSnackbar(true);
      }
  }, []);

  useEffect(() => {
    const fetchCurrAdmin = async () => {
      try {
        const data = await getCurrentAdmin();
        //console.log("Fetched admin:", data.admin_id);
        setAdminInSession(data.admin_id);
      } catch (error) {
        //console.error('Error fetching course professor in session:', error);
      }
    }

    fetchCurrAdmin();
  }, []);

  useEffect(() => {
    const fetchAvailableCourses = async () => {
      try {
        const data = await getAssociatedCoursesListForAdmin();
        setHasOtherCourses(data.length > 0);
      } catch (error) {
        //console.error('Error fetching available courses:', error);
      }
    };

    fetchAvailableCourses();
  }, []);

  useEffect(() => {
    const checkAvailableSubscription = async () => {
      try {
        const response = await axios.get('/api/check-available-subscription');
        console.log('checkAvailableSubscription response:', response);
        if (response.data.error) {
          navigate('/subscriptions');
        }
      } catch (error) {
        navigate('/subscriptions');
      }
    };

    checkAvailableSubscription();
  }, [navigate]);

  const handleFileSelected = async (file) => {
    setIsPopulating(true);
    setShowManualInput(false);
    setTempSyllabusFile(file);
    setSyllabusFileName(file.name);
    setIsPdfUploaded(file.type === 'application/pdf'); //Check if it's a PDF
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("/api/syllabus/prepopulate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const syllabusData = response.data;
      if (response.status === 200) {
        if (syllabusData && syllabusData.metadata) {
          if (syllabusData.metadata.class_name) {
              setCourseName(syllabusData.metadata.class_name);
          }
          if (syllabusData.metadata.class_description) {
              setDescription(syllabusData.metadata.class_description);
          }
          if (syllabusData.metadata.professors) {
              setProfessors(syllabusData.metadata.professors.map(prof => prof.professor_name).join(", "));
          }
          if (syllabusData.metadata.teaching_assistants) {
              setTeachingAssistants(syllabusData.metadata.teaching_assistants.map(ta => ta.teaching_assistant_name).join(", "));
          }
          if (syllabusData.metadata.term_name) {
              setTermName(syllabusData.metadata.term_name);
          }
          setSyllabusFile(file);
          setSyllabusText(syllabusData.raw_text);
          setSyllabusFileName(file.name);  // Store the filename
        }
      } else {
        setSyllabusErrors(syllabusData.error);
        setTempSyllabusFile(null);
        setSyllabusFileName(''); 
      }
    } catch (error) {
      //console.error("Error processing syllabus:", error);
      setTempSyllabusFile(null);
      setSyllabusFileName('');
    }

    setIsPopulating(false);
    setIsPopulated(true);
    setIsReplacing(false);
  };

  const handleReplace = () => {
    setIsReplacing(true);
    setTempSyllabusFile(null);
    setSyllabusFileName('');
    setSyllabusFile(null);
    setIsPopulated(false);
    setShowManualInput(false);
  };

  const SyllabusLoading = () => (
    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'center'}}>
      <Grid>
          <img
              src={LoadingIcon}
              alt="Answer loading"
              style={{
                  width: '3rem',
                  height: '3rem',
                  display: 'block',
                  marginLeft: '1.5rem',
              }}
          />
      </Grid>
      <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
          <Typography level="title-lg">Course information populating from syllabus...</Typography>
      </Grid>
    </Grid>
  );

  const handleSave = async () => {
    //console.log('handleSave function called');
    setIsSubmitted(true);

    const newErrors = {
      courseName: !courseName.trim(),
      professors: !professors.trim(),
      url: !!urlError
    };

    setErrors(newErrors);

    // If there are any errors and no content has been entered, open manual edit
    if ((newErrors.courseName || newErrors.professors) && !isPopulated && !showManualInput) {
      setShowManualInput(true);
      return;
    }

    // If there are any errors, stop the submission
    if (Object.values(newErrors).some(error => error) || adminInSession < 0) {
      return;
    }

    setIsLoading(true);

    const trimmedDescription = description.trim();

    const courseData = {
      courseName,
      professors,
      adminInSession,
      teachingAssistants,
      termName,
      trimmedDescription,
      courseUrl: fullCourseUrl.split('/').pop(),
      syllabusUploaded: !!syllabusFile,
    };
    //console.log('courseData prepared:', courseData);

    if (syllabusFile) {
      courseData.syllabusText = syllabusText;
      courseData.syllabusFileName = syllabusFileName;
    }
  
    const formData = new FormData();
    formData.append('courseData', JSON.stringify(courseData));
    if (syllabusFile && syllabusFile.type === 'application/pdf') {
      formData.append("file", syllabusFile);
    }

    try {
      const response = await axios.post('/api/course_info', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {                    
        // file now uploaded as part of api/course_info
        setStatusMessage('Course data saved successfully');
        //console.log('Server response:', response);
        //console.log('About to navigate...');
        setIsLoading(false);
        navigate('/files', { state: { newCourseCreated: true } });
        if (headerRef.current) {
            headerRef.current.refreshCourseList();
        }
    
        // Clear the form (temporarily)
        setCourseName('');
        setDescription('');
        setProfessors('');
        setTeachingAssistants('');
        setTermName('')
        setSyllabusFile(null);
      }
      
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 400 && error.response.data.error.includes("URL already exists")) {
        setUrlError("This URL already exists. Please choose a different one.");
      } else {
      setStatusMessage('Error saving course data: ' + (error.response?.data?.error || error.message));
      //console.error('Error details:', error);
    }
  }
};

return (
  <Box>
    <header className="min-header-wrapper">
      <Box className="left-wrapper">
          <a href="/" aria-label="Files"><img src={Logo} alt="All Day TA - Files" className="logo" /></a>
          <a href="/" aria-label="Files" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Files" className="logo-mobile" /></a>
      </Box>
    </header>
  <Container>
    <Grid container spacing={2}>
      <Grid xs={12}>
        {hasOtherCourses && (
          <Button variant="outlined" component="a" href="/files" startDecorator={<ArrowBackwardIcon />} sx={{ marginBottom:2 }}>
            Back to Files
          </Button>
        )}
          <Typography level="h1" id="create-course-form" mb={2}>Create a New Course</Typography>
          <FormErrorAlert 
            isSubmitted={isSubmitted}
            errors={{
              ...errors,
              url: !!urlError
            }}
            errorMessages={{
              courseName: "Course name is required.",
              professors: "Professor name is required.",
              url: urlError
            }}
          />
        
        {(isPopulated) ? (
        <>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          mt={3}
          mb={2}
        >
          <Typography level="title-lg" sx={{ fontSize:'1.25rem'}}>Your Syllabus</Typography>
          <Typography level="body-lg">
            The course information below has been auto-populated with information from your selected syllabus:
          </Typography>
        </Stack>
        </>  
        ) : (
        <>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          mt={3}
          mb={2}
        >
          <Typography level="title-lg" sx={{ fontSize:'1.25rem'}}>Upload Your Syllabus</Typography>
          <Typography level="body-lg">
            All Day TA will pre-populate your course information using your syllabus, this helps the AI calibrate how to handle your other files.
          </Typography>
        </Stack>
        </>  
        )}

        <FormControl>
          {isPopulating ? (
            <Box>
              <SyllabusLoading />
              {isPdfUploaded && (
                <FileTypeAlert/>
              )}
            </Box>
          ) : isPopulated && !isReplacing ? (
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={2} mb={2} >
                <Typography level="body-lg" color="primary">{syllabusFileName}</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReplace}
                >
                  Replace
                </Button>
              </Stack>
              <Divider />
            </Box>   
          ) : showManualInput ? (
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowManualInput(false)}
                sx={{ marginBottom:'1rem' }}
              >
                Upload a Syllabus
              </Button>
              <Divider />
            </Box>
          ) : (
            <>
              <DragAndDropSyllabus 
                setSyllabusFile={handleFileSelected} 
                syllabusUploaded={false} 
                isProcessing={false}
              />
              <Box>
                <Stack direction="row" alignItems="center" gap={2} mb={2}>
                  <Typography level="title-md">Prefer to enter your course information manually?</Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowManualInput(true)}
                  >
                    Switch to Manual Input
                  </Button>
                </Stack>
                <Divider />
              </Box>
            </>
          )}
          <FormHelperText>
            {syllabusErrors}
          </FormHelperText>
        </FormControl>
      </Grid>
      {(isPopulated || showManualInput) && (
      <Grid className="course-information" xs={12} sm={6} pd={3} sx={{ boxSizing: 'border-box', width:'100%'}}>
          <Stack
            direction="column"
            spacing={3}
            role="group"
            aria-labelledby="create-course-form"
          >
          <CourseInfoFields 
            initialCourseName={courseName}
            initialProfessors={professors}
            initialTeachingAssistants={teachingAssistants}
            initialTermName={termName}
            isSubmitted={isSubmitted}
            onValidChange={({ courseName, professors, teachingAssistants, termName, isValid }) => {
              setCourseName(courseName);
              setProfessors(professors);
              setTeachingAssistants(teachingAssistants);
              setTermName(termName);
              setCourseUrl(courseName);
            }}
            disabled={isPopulating}
          />
          </Stack>
      </Grid>
    )}
    {(isPopulated || showManualInput) && (
    <Grid xs={12} sm={6}>
        <Stack
            direction="column"
            spacing={3}
            role="group"
            aria-labelledby="create-course-form"
          >
          <FormControl>
                <FormLabel>Course Description</FormLabel>
                <Textarea variant="outlined" color="primary" minRows={4} value={description} onChange={(e) => setDescription(e.target.value)} data-testid="course-description"/>
          </FormControl>
          <CourseUrlField
            errorMessage={urlError}
            isSubmitted={isSubmitted}
            courseUrl={courseUrl}
            onValidChange={(url) => {
              setFullCourseUrl(url)
              setUrlError("")
            }} 
          />
        </Stack>
    </Grid>
    )}
      {statusMessage && (
        <Grid xs={12}>
          <Alert
            variant="soft"
            color={statusMessage.includes('Error') ? 'danger' : 'success'}
            role="alert"
            aria-live="assertive"
            sx={{ mb: 2 }}
          >
            {statusMessage}
          </Alert>
        </Grid>
      )}
      <Grid xs={12} mb={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        {isLoading ? (
          <>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography level="component-md">
                  Please wait while your course is created...
            </Typography>
            <Button 
              variant="solid" 
              color="primary" 
              loading
              loadingPosition="start"
            >
              Saving...
            </Button>
          </Stack>
          </>
        ) : (
          <>
            {!isPopulated && !showManualInput && !courseName && !professors && (
              <Typography level="component-md" sx={{ mb: 1 }}>
                Please upload a syllabus or add course information manually to save.
              </Typography>
            )}
            <Button
              variant="solid"
              color="primary"
              endDecorator={<ArrowForwardIcon />}
              onClick={handleSave}
              disabled={!isPopulated && !showManualInput && !courseName && !professors}
            >
              Save and Continue
            </Button>
          </>
        )}
      </Grid>
    </Grid>
    <Snackbar
        variant="solid"
        color="success"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSubscriptionSnackbar}
        onClose={() => setShowSubscriptionSnackbar(false)}
        role="alert"
        aria-live="polite"
        startDecorator={<CheckCircleIcon />}
        endDecorator={
          <Button
            variant="solid"
            color="success"
            size="sm"
            onClick={() => setShowSubscriptionSnackbar(false)}
          >
            Dismiss
          </Button>
        }
      >
        Your subscription is active! You can now proceed to create your course.
    </Snackbar>
  </Container>
  </Box>
  );
}