import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Box,
  Card,
  Textarea,
  Grid,
  FormControl,
  FormLabel,
  Stack,
  Button,
  Typography,
  CircularProgress
} from '@mui/joy';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import CourseSelector from '../components/testbed/CourseSelector';
import WorkflowConfig from '../config/workflowConfig';
import WorkflowConfigForm from '../components/testbed/WorkflowConfigForm';
import WorkflowResults from '../components/testbed/WorkflowResults';
import DownloadResultsCSV from '../components/testbed/DownloadResultsCSV';
import './TestbedChat.css';

export default function Testbed() {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [models, setModels] = useState([]);
  const [questions, setQuestions] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processingStatus, setProcessingStatus] = useState('');
  const BATCH_SIZE = 1; // Process questions one at a time

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesResponse, modelsResponse, configResponse] = await Promise.all([
          axios.get('/api/testbed/get_courses'),
          axios.get('/api/testbed/get_models'),
          axios.get('/api/testbed/get_default_config')
        ]);

        setCourses(coursesResponse.data);
        setModels(modelsResponse.data);

        // Set default configurations from backend
        if (configResponse.data.workflowConfigOld || configResponse.data.workflowConfigNew) {
          setWorkflows([
            new WorkflowConfig(configResponse.data.workflowConfigOld),
            new WorkflowConfig(configResponse.data.workflowConfigNew),
          ]);
        }
        else {
          setWorkflows([
            new WorkflowConfig(),
            new WorkflowConfig(),
          ]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleRunWorkflows = async () => {
    if (!selectedCourse) {
      alert('Please select a course');
      return;
    }

    try {
      setLoading(true);
      setResults([]);
      
      // Split questions into an array
      const questionsList = questions.split(/[\n]/).map(q => q.trim()).filter(q => q !== '');
      
      if (questionsList.length === 0) {
        alert('Please enter at least one question');
        setLoading(false);
        return;
      }
      
      // Process questions in batches
      const allResults = [];
      let localFollowups = []; // Local variable to track follow-ups during processing
      
      for (let i = 0; i < questionsList.length; i += BATCH_SIZE) {
        const batchQuestions = questionsList.slice(i, i + BATCH_SIZE);
        setProcessingStatus(`Processing question ${i+1} of ${questionsList.length}: "${batchQuestions[0]}"`);
        
        // For this batch, create request payload with appropriate followups
        const requestPayload = {
          courseId: selectedCourse.id,
          questions: batchQuestions,
          workflows: workflows,
          prior_results: localFollowups // Pass local tracked results for followup handling
        };
        
        // Send the batch request
        const response = await axios.post('/api/testbed/process_questions', requestPayload);
        
        // Accumulate results
        allResults.push(...response.data);
        
        // Update the followups context for the next batch
        if (response.data.length > 0) {
          // Update followups with the most recent results
          localFollowups = allResults.slice(-10);
          localFollowups = localFollowups.map(result => ({
            question: result.question,
            workflows: result.workflows.map(workflow => ({
              answer: workflow.response.output.answer
            }))
          }));
        }
        setResults(allResults);
      }
    } catch (error) {
      console.error('Error running workflows:', error);
      alert('Error running workflows: ' + (error.response?.data?.error || error.message));
    } finally {
      setLoading(false);
      setProcessingStatus('');
    }
  };

  return (
    <Box>
      <header className="min-header-wrapper">
        <Box className="left-wrapper">
          <a href="/" aria-label="Files"><img src={Logo} alt="All Day TA - Files" className="logo" /></a>
          <a href="/" aria-label="Files" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Files" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container>
        <Stack spacing={2}>

          <Card variant="outlined">
            <FormControl>
              <FormLabel component="h2" className="label-h2">Course Selection</FormLabel>
              <CourseSelector
                selectedCourse={selectedCourse}
                setSelectedCourse={setSelectedCourse}
                courses={courses}
              />
            </FormControl>
          </Card>

          <Card variant="outlined">
            <FormControl>
              <FormLabel component="h2" className="label-h2">Questions List</FormLabel>
              <Textarea
                minRows={4}
                maxRows={10}
                value={questions}
                onChange={(e) => setQuestions(e.target.value)}
                placeholder="Enter each question on a new line, or separate with commas. For example:
What is the deadline for Assignment 1?
When is the midterm exam?
How many credits is this course?"
                sx={{
                  fontFamily: 'monospace',
                  '& textarea': {
                    lineHeight: '1.5',
                  }
                }}
              />
            </FormControl>
          </Card>

          <Grid container>
            {workflows.map((workflow, index) => (
              <Grid xs={12} md={6} key={index} pl={index === 0 ? 0 : 1} pr={index === workflows.length - 1 ? 0 : 1} >
                <WorkflowConfigForm workflow={workflow} index={index} setWorkflows={setWorkflows} models={models} />
              </Grid>
            ))}
          </Grid>

          <Button 
            variant="solid" 
            color="primary" 
            onClick={handleRunWorkflows}
            disabled={loading}
          >
            {loading ? (
              <Stack direction="row" spacing={2} alignItems="center">
                <CircularProgress size="sm" />
                <Typography level="body-sm" color="primary">
                  {processingStatus}
                </Typography>
              </Stack>
            ) : (
              'Run Workflows'
            )}
          </Button>

          {/* Results Section */}
          <Stack direction="row" spacing={2}>
            <Typography level="h3">Results</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3, mb: 2 }}>
              <DownloadResultsCSV results={results} />
            </Box>
          </Stack>
          <WorkflowResults results={results} />

          {processingStatus && (
            <Stack direction="row" spacing={2} alignItems="center">
              <CircularProgress size="sm" />
              <Typography level="body-sm" color="primary">
                {processingStatus}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
