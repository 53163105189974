import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  FormControl,
  FormLabel,
  Table,
  Grid,
  Box,
  Link,
  Autocomplete,
  AutocompleteOption,
  Sheet,
  Stack,
  Select,
  Option,
  IconButton,
  Divider,
  Alert,
  Button
} from "@mui/joy";
import Logo from "../assets/logo.svg";
import LogoMobile from "../assets/logoMobile.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AdminDetailsModal from "../components/AdminDetailsModal";
export default function ManageAdmins() {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [facultyDict, setFacultyDict] = useState({});
  const [departmentsByFaculty, setDepartmentsByFaculty] = useState({});
  const [departmentDict, setDepartmentDict] = useState({});
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [admin, setAdmin] = useState(null);

  //Pagination
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const sortFields = ['name', 'faculty', 'department'];
  const [sortOrder, setSortOrder] = useState({ field: sortFields[0], direction: 'asc' });

  //This section is used for pagination
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (admins.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? admins.length
      : Math.min(admins.length, (page + 1) * rowsPerPage);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - admins.length) : 0;

  //This section handles sorting the table
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'faculty') {
      return facultyDict[b.faculty_id].name.localeCompare(facultyDict[a.faculty_id].name);
    }
    if (orderBy === 'department') {
      return departmentDict[b.department_id].name.localeCompare(departmentDict[a.department_id].name);
    }
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // Functionality
  useEffect(() => {
    fetchOrgs();
  }, []);

  const fetchOrgs = async () => {
    try {
      const response = await axios.get("/api/organizations/all");

      const processedData = response.data
        .map((item) => ({
          id: item.org_id,
          name: item.org_name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setOrganizations(processedData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setError("Error fetching organizations: " + error);
    }
  };

  const fetchAdmins = async (orgId) => {
    try {
      const response = await axios.get(`/api/admin?org_id=${orgId}`);
      setAdmins(response.data.admins);
    } catch (err) {
      console.error("Error fetching admins:", err);
      setError("Error fetching admins: " + err);
    }
  };

  const handleOrgUpdate = (newValue) => {
    setSelectedOrg(newValue);
    if (newValue) {
      fetchAdmins(newValue.id);
      fetchFacultiesAndDepartments(newValue.id);
    } else {
      setAdmins([]);
      setFaculties([]);
      setDepartmentsByFaculty({});
      setError("");
    }
  };

  const fetchFacultiesAndDepartments = async (orgId) => {
    const facultiesResponse = await axios.get(`/api/faculty?org_id=${orgId}`);
    const faculties = facultiesResponse.data.faculties;
    faculties.push({ id: null, name: "None" });
    setFaculties(faculties);
    setFacultyDict(faculties.reduce((acc, faculty) => {
      acc[faculty.id] = faculty;
      return acc;
    }, {}));

    const departmentsResponse = await axios.get(`/api/department?org_id=${orgId}`);
    const departments = departmentsResponse.data.departments;
    departments.push({ id: null, faculty_id: null, name: "None" });
    const departmentsByFaculty = {};

    // Initialize departmentsByFaculty with empty arrays for each faculty
    faculties.forEach(faculty => {
      departmentsByFaculty[faculty.id] = [];
    });

    // Populate departmentsByFaculty with departments
    departments.forEach(dept => {
      departmentsByFaculty[dept.faculty_id].push(dept);
    });

    // Add "None" department for each faculty
    faculties.forEach(faculty => {
      departmentsByFaculty[faculty.id].push({ id: null, faculty_id: faculty.id, name: "None" });
    });

    setDepartmentsByFaculty(departmentsByFaculty);
    setDepartmentDict(departments.reduce((acc, dept) => {
      acc[dept.id] = dept;
      return acc;
    }, {}));
  };

  const handleFacultyUpdate = async (adminId, newValue) => {
    try {
      const response = await axios.patch(`/api/admin/faculty/${adminId}`, {
        faculty_id: newValue?.id
      });
      if (response.status === 200) {
        setAdmins(prevAdmins =>
          prevAdmins.map(admin =>
            admin.id === adminId ? { ...admin, faculty_id: newValue?.id } : admin
          )
        );
      }
    } catch (error) {
      console.error("Error updating faculty:", error);
      setError("Error updating faculty: " + error);
    }
  };

  const handleDepartmentUpdate = async (adminId, newValue) => {
    try {
      const response = await axios.patch(`/api/admin/department/${adminId}`, {
        department_id: newValue?.id
      });
      if (response.status === 200) {
        setAdmins(prevAdmins =>
          prevAdmins.map(admin =>
            admin.id === adminId ? { ...admin, department_id: newValue?.id } : admin
          )
        );
      }
    } catch (error) {
      console.error("Error updating department:", error);
      setError("Error updating department: " + error);
    }
  };

  const handleAdminDetails = (admin) => {
    setOpen(true);
    setAdmin(admin);
  };

  return (
    <Box>
      <header className="header-wrapper">
        <Box className="left-wrapper">
          <a href="/login/admin"><img src={Logo} alt="All Day TA" className="logo" /></a>
          <a href="/login/admin"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container spacing={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography level="h1">Manage Admins</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography level="title-sm">Need to create an admin?&nbsp;
              <Link href="/signup/admin">
                Create an Admin
              </Link>
            </Typography>
            <Typography level="title-sm">Need to create an organization, faculty, or department?&nbsp;
              <Link href="/create-org">
                Manage Organizations
              </Link>
            </Typography>
          </Grid>
          <Grid xs={12}>
            {error && (
              <Alert
                color={error ? 'danger' : 'success'}
                sx={{ mb: 2 }}
                role="alert"
                aria-live="polite"
              >
                {error}
              </Alert>
            )}
          </Grid>
          <Grid xs={12}>
            <FormControl sx={{ mb: 2 }}>
              <FormLabel id="org-select-label">Filter by Organization</FormLabel>
              <Autocomplete
                variant="outlined"
                color="primary"
                options={organizations}
                value={selectedOrg}
                placeholder="Select an organization"
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => handleOrgUpdate(newValue)}
                renderOption={(props, option) => {
                  const { key, ...restProps } = props; // Extract and exclude the key from props
                  return (
                    <AutocompleteOption key={key} {...restProps}>
                      {option.name}
                    </AutocompleteOption>
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <Sheet variant="plain">
              {/* Table Sort and Pagination */}
              <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={2} className="table-toolbar">
                <FormControl>
                  <FormLabel>Sort by:</FormLabel>
                  <Select
                    value={`${sortOrder.field}-${sortOrder.direction}`}
                    onChange={(_, newValue) => {
                      const [field, direction] = newValue.split('-');
                      setSortOrder({ field, direction });
                    }}
                    sx={{ minWidth: 200 }}
                    variant='plain'
                  >
                    {sortFields.map((field) => (
                      <React.Fragment key={field}>
                        <Option value={`${field}-asc`}>{field.charAt(0).toUpperCase() + field.slice(1)} A-Z</Option>
                        <Option value={`${field}-desc`}>{field.charAt(0).toUpperCase() + field.slice(1)} Z-A</Option>
                      </React.Fragment>
                    ))}
                  </Select>
                </FormControl>
                { /* Pagination Component */}
                <Stack direction="row" alignItems="center" gap={2}>
                  <FormControl orientation="horizontal" size="md">
                    <FormLabel>Rows/Page:</FormLabel>
                    <Select onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage}>
                      {rowsPerPageOptions.map((option) => (
                        <Option key={option} value={option}>{option}</Option>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium' }}>
                    {labelDisplayedRows({
                      from: admins.length === 0 ? 0 : page * rowsPerPage + 1,
                      to: getLabelDisplayedRowsTo(),
                      count: admins.length === -1 ? -1 : admins.length,
                    })}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      size="sm"
                      color="primary"
                      variant="outlined"
                      aria-label="Previous Page"
                      disabled={page === 0}
                      onClick={() => handleChangePage(page - 1)}
                      sx={{ bgcolor: "background.surface" }}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                      size="sm"
                      color="primary"
                      variant="outlined"
                      aria-label="Next Page"
                      disabled={
                        admins.length !== -1
                          ? page >= Math.ceil(admins.length / rowsPerPage) - 1
                          : false
                      }
                      onClick={() => handleChangePage(page + 1)}
                      sx={{ bgcolor: "background.surface" }}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </Stack>
                { /* End Pagination Component */}
              </Stack>
              <Divider />
              <Alert
                variant="soft"
                color="neutral"
                startDecorator={<TouchAppIcon />}
                sx={{
                  display: 'none', // Hidden by default
                  '@media (max-width: 820px)': {
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }
                }}
              >
                Scroll horizontally to view full table
              </Alert>
              <Box sx={{
                width: '100%',
                overflowX: 'auto',
                '@media (max-width: 820px)': {
                  width: '100vw',
                  margin: '0 -1rem',
                }
              }}
              >
                <Table
                  hoverRow
                  aria-label="collapsible organizations table"
                  size="md"
                  sx={{
                    minWidth: '1100px', // Ensure the table has a minimum width
                    '@media (max-width: 820px)': {
                      width: '100%',
                    }
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "4rem" }}>Admin ID</th>
                      <th>Admin Name</th>
                      <th>Admin Email</th>
                      <th>Faculty</th>
                      <th>Department</th>
                      <th style={{ width: "6rem" }}>Courses</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stableSort(admins, getComparator(sortOrder.direction, sortOrder.field))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((admin) => (
                        <tr key={admin.id}>
                          <td>{admin.id}</td>
                          <td>{admin.name}</td>
                          <td>{admin.email}</td>
                          <td>
                            <Autocomplete
                              variant="outlined"
                              disabled={admin.department_id !== null}
                              options={faculties || []}
                              value={facultyDict[admin.faculty_id] || null}
                              placeholder="Select an faculty"
                              getOptionLabel={(option) => option.name}
                              onChange={(e, newValue) => handleFacultyUpdate(admin.id, newValue)}
                              disableClearable={true}
                              renderOption={(props, option) => {
                                const { key, ...restProps } = props; // Extract and exclude the key from props
                                return (
                                  <AutocompleteOption key={key} {...restProps}>
                                    {option.name}
                                  </AutocompleteOption>
                                );
                              }}
                              isOptionEqualToValue={(option, value) => {
                                if (value === null && option === null) {
                                  return true;
                                }
                                return option && value && option.id === value.id
                              }}
                            />
                          </td>
                          <td>
                            <Autocomplete
                              variant="outlined"
                              disabled={admin.faculty_id === null}
                              options={departmentsByFaculty[admin.faculty_id] || []}
                              value={departmentDict[admin.department_id] || null}
                              placeholder="Select an department"
                              getOptionLabel={(option) => option.name}
                              onChange={(e, newValue) => handleDepartmentUpdate(admin.id, newValue)}
                              disableClearable={true}
                              renderOption={(props, option) => {
                                const { key, ...restProps } = props; // Extract and exclude the key from props
                                return (
                                  <AutocompleteOption key={key} {...restProps}>
                                    {option.name}
                                  </AutocompleteOption>
                                );
                              }}
                              isOptionEqualToValue={(option, value) => {
                                if (value === null && option === null) {
                                  return true;
                                }
                                return option && value && option.id === value.id
                              }}
                            />
                          </td>
                          <td>
                            <Button variant="outlined" color="primary" onClick={() => handleAdminDetails(admin)}>
                              Courses
                            </Button>
                          </td>
                        </tr>
                      ))}
                    {emptyRows > 0 && (
                      <tr
                        style={{
                          height: `calc(${emptyRows} * 40px)`,
                          "--TableRow-hoverBackground": "transparent",
                        }}
                      >
                        <td colSpan={5} aria-hidden />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Box>
            </Sheet>
          </Grid>
        </Grid>
      </Container>
      <AdminDetailsModal open={open} onClose={() => setOpen(false)} admin={admin} />
    </Box>
  );
}
