import React from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/joy';
import Container from '../components/Container';
import SubscriptionCard from '../components/SubscriptionCard';
import { useSubscription } from '../context/SubscriptionContext';

export default function Subscriptions() {
  const { 
    isLoading, 
    error, 
    subscription, 
    daysLeft, 
    isTrialExpired 
  } = useSubscription();

  // Get current tier ID from subscription
  const currentTierId = subscription?.tier_id;

  return (
    <Box>
      <Container>
        <Grid container xs={6}>
          <Stack direction="column" gap={2}>
            <Typography level="h1">Your Subscription</Typography>  
            <Typography>The subscription applied to this course.</Typography>      
            {isLoading ? (
                <Typography>Loading your subscription...</Typography>
              ) : error ? (
                <Typography color="danger">Error loading subscription</Typography>
              ) : subscription ? (
                <SubscriptionCard
                  tierId={currentTierId}
                  isActive={true}
                  daysLeft={daysLeft}
                  isTrialExpired={isTrialExpired}
                  isSubscriptionScreen={true}
                />
              ) : (
                <Typography>No active subscription</Typography>
              )}
          </Stack>  
        </Grid>
      </Container>
    </Box>
  );
}
