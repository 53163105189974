import axios from 'axios';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    Grid,
    FormControl,
    FormLabel,
    FormHelperText,
    IconButton,
    Link,
    List,
    ListItem,
    Stack,
    Typography,
    Chip,
    Tabs,
    TabList,
    Tab,
    TabPanel
  } from '@mui/joy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Container from '../components/Container';
import { AdminsListSkeleton } from '../components/LoadingSkeletons';
import TableStudentInvite from '../components/StudentTable';
import InviteStudentModal from '../components/InviteStudentsModal';
import ProfessorContactFields from '../components/form-elements/ProfessorContactFields';
import FormErrorAlert from '../components/form-elements/FormErrorAlert';
import { getCurrentAdmin, getOrgProfessors } from '../ProfessorsRetrieveAPI';
import CourseCheck from '../components/CourseCheck';
import { useSubscription } from '../context/SubscriptionContext';
import AnswerStyleForm from '../components/settings/AnswerStyleForm';
import UrlDisableForm from '../components/settings/UrlDisableForm';
import { PROMPT_SETTINGS_DEFAULT } from '../config/promptSettings';

// Auth type constants (matching backend)
const AUTH_TYPES = {
    NONE: 'none',
    EMAIL: 'email',
    EMAIL_PASSWORD: 'email_password',
    INTEGRATION: 'integration'
};

export default function Settings({ headerRef }) {
  const [orgId, setOrgId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [adminInSession, setAdminInSession] = useState(-1);
  const [courseAdmins, setCourseAdmins] = useState([]);
  const [courseUrl, setCourseUrl] = useState('');
  const [adminOptions, setAdminOptions] = useState([]);
  const [promptDefault, setPromptDefault] = useState(PROMPT_SETTINGS_DEFAULT);
  //Students
  const [authType, setAuthType] = useState(AUTH_TYPES.NONE);
  const [requireStudentLogin, setRequireStudentLogin] = useState(false);
  const studentsRef = useRef(null);
  const [refreshStudentTable, setRefreshStudentTable] = useState(); 
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [inviteAlertInfo, setInviteAlertInfo] = useState({ show: false, message: '', severity: 'info' });
  //Status and Alerts
  const { subscription } = useSubscription();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    isValid: false
  });
  const [alertInfo, setAlertInfo] = useState({ show: false, message: '', severity: 'info' });
  const [formAlertInfo, setFormAlertInfo] = useState({ show: false, message: '', severity: 'info' });
  const [disableStatus, setDisableStatus] = useState({ show: false, message: '' });
  const [urlSettings, setUrlSettings] = useState({
    disableUrl: false,
    disableUrlTimed: false,
    disableUrlStartDate: '',
    disableUrlStartTime: '',
    disableUrlEndDate: '',
    disableUrlEndTime: '',
    reactivated: false
  });
  
  useEffect(() => {
      document.title = "Settings - All Day TA";
  }, []);

  const updateDisableStatus = useCallback((active, disableStartDateUTC, disableEndDateUTC) => {
    // If URL is active, no need to show status
    if (active === 1) {
      setDisableStatus({ show: false, message: '' });
      return;
    }

    // Handle case when no date range is set
    if (!disableStartDateUTC || !disableEndDateUTC) {
      setDisableStatus({ 
        show: true, 
        message: "Student access to your course is currently disabled." 
      });
      return;
    }

    // Check if current time falls within disabled period
    const currentDate = new Date();
    const disableStartDate = new Date(disableStartDateUTC);
    const disableEndDate = new Date(disableEndDateUTC);
    const isWithinDisabledPeriod = currentDate > disableStartDate && currentDate < disableEndDate;

    if (!isWithinDisabledPeriod) {
      setDisableStatus({ show: false, message: '' });
      return;
    }

    // Show status with date range
    const localStart = formatUTCToLocalDate(disableStartDateUTC);
    const localEnd = formatUTCToLocalDate(disableEndDateUTC);
    setDisableStatus({ 
      show: true, 
      message: `Student access to your course is currently disabled from ${localStart.date} ${localStart.time} to ${localEnd.date} ${localEnd.time}.` 
    });
  }, []);

  //Gathering information about this course
  const fetchStatus = useCallback(async () => {
      setIsLoading(true);
      try {
          const response = await axios.get('/api/course');
          if (response.data && response.data[0]) {
              const courseData = response.data[0];

              setOrgId(courseData.org_id);
              setCourseId(courseData.course_id);
              setCourseUrl(courseData.course_url);

              // Fetch admins for this course using the new endpoint
              const adminsResponse = await axios.get(`/api/course_admins/${courseData.course_id}`);
              if (adminsResponse.data && adminsResponse.data.admins) {
                setCourseAdmins(adminsResponse.data.admins);
              }

              // Fetch course settings
              const settingsResponse = await axios.get(`/api/course_settings?course_url=${courseData.course_url}`);
              if (settingsResponse.data) {
                  setUrlSettings(prevSettings => ({
                    ...prevSettings,
                    disableUrl: !settingsResponse.data.active
                  }));
                  const startDateUTC = settingsResponse.data.disable_start_date;
                  const endDateUTC = settingsResponse.data.disable_end_date;
                  if (startDateUTC && endDateUTC) {
                    // Convert UTC timestamps to local date strings (YYYY-MM-DD)
                    const localStart = formatUTCToLocalDate(startDateUTC);
                    const localEnd = formatUTCToLocalDate(endDateUTC);
                    setUrlSettings(prevSettings => ({
                      ...prevSettings,
                      disableUrlStartDate: localStart.date,
                      disableUrlStartTime: localStart.time,
                      disableUrlEndDate: localEnd.date,
                      disableUrlEndTime: localEnd.time,
                      disableUrlTimed: true
                    }));
                    // Check if end date has passed and reset if necessary
                    const endDateTime = new Date(endDateUTC);
                    if (endDateTime < new Date()) {
                      setUrlSettings(prevSettings => ({
                        ...prevSettings,
                        disableUrl: false,
                        disableUrlTimed: false,
                        reactivated: true
                      }));
                      try {
                        await axios.post('/api/update_course_settings', {
                          course_id: courseData.course_id,
                          active: 1, // Re-enable course URL
                        });
                      } catch (reactivationError) {
                        console.error('Error reactivating course:', reactivationError);
                      }
                    }
                  }
                  updateDisableStatus(settingsResponse.data.active, startDateUTC, endDateUTC);
                  setAuthType(settingsResponse.data.auth_type);
                  const promptSettings = settingsResponse.data.prompt_default;
                  if (promptSettings) {
                    const [length, style] = promptSettings.split('-');
                    setPromptDefault({ length, style });
                  }
                  // Update checkbox based on auth type
                  setRequireStudentLogin(settingsResponse.data.auth_type === AUTH_TYPES.EMAIL);
              }
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      } finally {
          setIsLoading(false);
      }
  }, [updateDisableStatus]);

  // Helper function to convert UTC to local YYYY-MM-DD
  const formatUTCToLocalDate = (utcString) => {
    const date = new Date(utcString);
    
    // Use local date components (not UTC methods)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours());
    const minute = String(date.getMinutes()).padStart(2, '0');

    // Convert 24-hour time to 12-hour time 
    const period = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12 || 12;

    return { date: `${year}-${month}-${day}`, time: `${hour12}:${minute} ${period}` };
  };

  useEffect(() => {
      if (headerRef.current) {
          headerRef.current.setOnCourseChange(fetchStatus);
      }
  }, [headerRef, fetchStatus]);

  useEffect(() => {
    const fetchCurrAdmin = async () => {
      try {
        const data = await getCurrentAdmin();
        //console.log("Fetched admin:", data.admin_id);
        setAdminInSession(data.admin_id);
      } catch (error) {
        //console.error('Error fetching course professor in session:', error);
      }
    }

    fetchCurrAdmin();
  }, []);

  useEffect(() => {
      fetchStatus();
  }, [fetchStatus]);

  const fetchAdmins = useCallback(async () => {
    try {
      const adminsResponse = await axios.get(`/api/course_admins/${courseId}`);
      if (adminsResponse.data && adminsResponse.data.admins) {
        setCourseAdmins(adminsResponse.data.admins);
      }
    } catch (error) {
      console.error('Error fetching course admins:', error);
    }
  }, [courseId]);

  // Get the other admins already in your org
  useEffect(() => {
    const fetchProfessors = async () => {
      try {
        const data = await getOrgProfessors();
        //console.log("Fetched org professor options:", data);
        setAdminOptions(data);
      } catch (error) {
        //console.error('Error fetching org professors:', error);
      }
    };
    fetchProfessors();
  }, []); 

  //Adding and removing admins (already in org) to this course
  const handleAddAdmin = async (newAdmin) => {
    if (newAdmin && !courseAdmins.some(admin => admin.admin_id === newAdmin.admin_id)) {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/admin_course_association', {
                admin_id: newAdmin.admin_id,
                course_id: courseId,
                operation: 'add'
            });

            if (response.status === 200) {
                setCourseAdmins(prevAdmins => [...prevAdmins, newAdmin]);
                setAlertInfo({
                    show: true,
                    message: 'Admin added successfully to this course',
                    severity: 'success'
                });
            }
        } catch (error) {
            console.error('Error adding admin or associating course:', error);
            setAlertInfo({
                show: true,
                message: 'Error adding admin or associating course: ' + error.message,
                severity: 'danger'
            });
        } finally {
            setIsLoading(false);
        }
    }
  };

  const handleRemoveAdmin = async (adminToRemove) => {
    if (adminToRemove.admin_id === adminInSession) {
        // Prevent removing the current user
        setAlertInfo({
            show: true,
            message: "You cannot remove yourself from the course.",
            severity: 'warning'
        });
        return;
    }

    if (adminToRemove.owner) {
        // Prevent removing the owner
        setAlertInfo({
            show: true,
            message: "You cannot remove the owner from the course.",
            severity: 'warning'
        });
        return;
    }

    if (courseAdmins.length <= 1) {
        // Prevent removing the last admin
        setAlertInfo({
            show: true,
            message: "You cannot remove the last admin from the course.",
            severity: 'warning'
        });
        return;
    }

    setIsLoading(true);
    try {
        const response = await axios.post('/api/admin_course_association', {
            admin_id: adminToRemove.admin_id,
            course_id: courseId,
            operation: 'remove'
        });

        if (response.status === 200) {
            setCourseAdmins(prevAdmins => prevAdmins.filter(admin => admin.admin_id !== adminToRemove.admin_id));
            setAlertInfo({
                show: true,
                message: "Admin removed successfully.",
                severity: 'success'
            });
        }
    } catch (error) {
        console.error('Error removing admin:', error);
        setAlertInfo({
            show: true,
            message: 'Error removing admin: ' + error.response?.data?.message || error.message,
            severity: 'danger'
        });
    } finally {
        setIsLoading(false);
    }
  };

    //Inviting new admins to your org and this course
    const handleFormChange = ({ name, email, isValid }) => {
      setFormData({ name, email, isValid });
    };

    const handleInvite = async () => {
        console.log('handleInvite function called');
        setIsSubmitted(true);

        if (!formData.isValid) {
          return;
        }

        // Check if the email already exists in adminOptions
        const existingAdmin = adminOptions.find(admin => admin.admin_email.toLowerCase() === formData.email.toLowerCase());
        
        if (existingAdmin) {
          if (courseAdmins.some(admin => admin.admin_id === existingAdmin.admin_id)) {
            setFormAlertInfo({
              show: true,
              message: 'This admin is already added to the course.',
              severity: 'warning'
            });
          } else {
            setFormAlertInfo({
              show: true,
              message: 'This admin is already in your organization.',
              severity: 'warning'
            });
          }
          return;
        }

        // Check if orgId is available
        if (!orgId) {
          setFormAlertInfo({
            show: true,
            message: 'Organization ID is not available. Please try again later.',
            severity: 'danger'
          });
          return;
        }

        const inviteData = {
          professorName: formData.name,
          professorOrg: orgId,
          professorEmail: formData.email.toLowerCase(),
          courseId
        };
        console.log('inviteData prepared:', inviteData);
  
        try {
          console.log('Sending request to /api/invite_admin_collaborator');
          console.log('Request payload:', JSON.stringify(inviteData));
          
          const response = await axios.post('/api/invite_admin_collaborator', inviteData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          console.log('Response received:', response);

          if (response.status === 200) {
            setFormAlertInfo({
              show: true,
              message: 'Admin invited successfully',
              severity: 'success'
            });
            console.log('Server response:', response.data);
        
            // Reset form
            setFormData({ name: '', email: '', isValid: false });
            setIsSubmitted(false);

            // Update the list of admins
            await fetchAdmins();
            const updatedOrgProfessors = await getOrgProfessors();
            setAdminOptions(updatedOrgProfessors);
          }
        } catch (error) {
          console.error('Error details:', error.response || error);
          setFormAlertInfo({
            show: true,
            message: 'Error: ' + (error.response?.data?.error || error.message),
            severity: 'danger'
          });
        }
    };

    //Turn on student login and invitations
    const handleToggleStudentLogin = async (event) => {
        const newValue = event.target.checked;
        const newAuthType = newValue ? AUTH_TYPES.EMAIL : AUTH_TYPES.NONE;
        
        setRequireStudentLogin(newValue);
        setIsLoading(true);
        
        try {
            await axios.post('/api/update_course_settings', {
                course_id: courseId,
                auth_type: newAuthType
            });
            setAuthType(newAuthType);
        } catch (error) {
            console.error('Error updating course settings:', error);
            // Revert the checkbox on error
            setRequireStudentLogin(!newValue);
            setAuthType(authType);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
              studentsRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        }
    };

    // Check if a feature is available based on subscription
    const isFeatureAvailable = () => {
        return subscription?.tier_id !== 1;
    };

  return (
    <Container>
      <Typography mb={2} level="h1">Settings</Typography>
      <CourseCheck>
        <Tabs aria-label="Settings tabs" defaultValue={"ai-assistant"} sx={{ backgroundColor: 'background.body' }}>
          <TabList>
            <Tab value={"ai-assistant"}>AI Assistant</Tab>
            <Tab value={"professors"}>Professors/TAs</Tab>
            <Tab value={"students"}>Students</Tab>
          </TabList>
          <TabPanel value={"ai-assistant"}>
            <Box component={"section"}>
              <Stack direction="column" spacing={3}>
                <Typography level="h2">AI Assistant</Typography>
                <AnswerStyleForm courseId={courseId} promptDefault={promptDefault} />
                <UrlDisableForm courseId={courseId} isLoading={isLoading} updateDisableStatus={updateDisableStatus} urlSettings={urlSettings} setUrlSettings={setUrlSettings} />
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel value={"professors"}>
            <Grid container spacing={2}>
              <Grid xs={12} sm={12} md={6.5}>
                <Typography mb={1} level="h2">Professors/TAs</Typography>
                {isLoading ? (
                  <AdminsListSkeleton count={5} /> // Adjust the count as needed
                ) : (
                  <List sx={{
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "8px"
                  }}>
                    {courseAdmins.map((admin, index) => (
                      <ListItem
                        key={index}
                        endAction={
                          <IconButton
                            aria-label={`Remove ${admin.admin_name}`}
                            size="sm"
                            color="danger"
                            onClick={() => handleRemoveAdmin(admin)}
                            disabled={isLoading}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        {admin.admin_name} ({admin.admin_email})
                      </ListItem>
                    ))}
                  </List>
                )}
                {alertInfo.show && (
                  <Alert
                    sx={{ mt: 2, mb: 2 }}
                    variant="soft"
                    color={alertInfo.severity}
                    role="alert"
                    aria-live="polite"
                    endDecorator={
                      <Button variant="soft" size="sm" color={alertInfo.severity} onClick={() => setAlertInfo({ ...alertInfo, show: false })}>
                        Dismiss
                      </Button>
                    }
                  >
                    {alertInfo.message}
                  </Alert>
                )}
                <Divider sx={{ marginTop: '0.75rem', marginBottom: '0.75rem' }} />
                <Stack direction="column" spacing={1} alignItems="flex-start">
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>Add a Professor/TA from your organization:</FormLabel>
                    <Autocomplete
                      variant="outlined"
                      color="primary"
                      clearOnBlur
                      options={adminOptions.filter(option =>
                        !courseAdmins.some(admin => admin.admin_id === option.admin_id)
                      ).sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      getOptionLabel={(option) => `${option.admin_name} (${option.admin_email})`}
                      placeholder="Type to search Professors/TAs"
                      onChange={(event, newValue) => {
                        console.log("Autocomplete onChange:", newValue);
                        if (newValue) {
                          handleAddAdmin(newValue);
                        }
                      }}
                      value={null}
                      disabled={isLoading}
                    />
                    <FormHelperText>
                      Select a Professor/TA to add them as a course administrator
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid xs={12} sm={12} md={5.5}>
                <Card variant="soft">
                  <Stack direction="column" spacing={2} p={2}>
                    <Box>
                      <Typography level="title-lg">Don't see the Professor/TA in your organization?</Typography>
                      <Typography>Invite them to join your organization on All Day TA</Typography>
                      <FormErrorAlert
                        isSubmitted={isSubmitted}
                        errors={{
                          professorName: !formData.name.trim(),
                          professorEmail: !formData.email.trim()
                        }}
                        errorMessages={{
                          professorName: "Professor name is required.",
                          professorEmail: "Professor email is required."
                        }}
                      />
                    </Box>
                    {formAlertInfo.show && (
                      <Alert
                        sx={{ mt: 2, mb: 2 }}
                        variant="soft"
                        color={formAlertInfo.severity}
                        role="alert"
                        aria-live="polite"
                        endDecorator={
                          <Button variant="soft" size="sm" color={formAlertInfo.severity} onClick={() => setFormAlertInfo({ ...formAlertInfo, show: false })}>
                            Dismiss
                          </Button>
                        }
                      >
                        {formAlertInfo.message}
                      </Alert>
                    )}
                    <ProfessorContactFields
                      initialName={formData.name}
                      initialEmail={formData.email}
                      onValidChange={handleFormChange}
                      isSubmitted={isSubmitted}
                    />
                    <Button endDecorator={<ArrowForwardIcon />} onClick={handleInvite}>Invite Professor/TA</Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={"students"}>
            <Box id="students" component={"section"}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography level="h2">Students</Typography>
                {disableStatus.show &&
                  <>
                    <Chip color="danger">Course Disabled</Chip>
                    <Typography level="body-md">
                      {disableStatus.message}
                    </Typography>
                  </>
                }
              </Stack>
              <Typography level="body-lg">Students can access your course via
                <Link style={{ wordBreak: "break-all" }} href={`${window.location.origin}${courseUrl}`} target="_blank" underline="always" level="body-lg" variant="plain">{`app.alldayta.com${courseUrl}`}</Link>
              </Typography>
              <Typography mb={2} mt={3} level="title-lg">Restrict Public Access</Typography>
              {isFeatureAvailable() ? (
                <Box mt={3}>
                  <Checkbox
                      checked={requireStudentLogin}
                      onChange={handleToggleStudentLogin}
                      color="primary" 
                      size="md" 
                      label="Require Student Emails"
                      disabled={isLoading}
                  />
                  <Box sx={{mt: 3}} ref={studentsRef}>
                    {inviteAlertInfo.show && (
                      <Alert 
                        sx={{ mb: 2 }} 
                        variant="soft"
                        color={inviteAlertInfo.severity}
                        role="alert"
                        aria-live="polite"
                        endDecorator={
                          <Button variant="soft" size="sm" color={inviteAlertInfo.severity} onClick={() => setInviteAlertInfo({ ...inviteAlertInfo, show: false })}>
                            Dismiss
                          </Button>
                        }
                      >
                        {inviteAlertInfo.message}
                      </Alert>
                    )}
                    <TableStudentInvite 
                      courseId={courseId} 
                      refreshTrigger={refreshStudentTable}
                      onAlert={(alertInfo) => setInviteAlertInfo(alertInfo)}
                      onInvite={() => setStudentModalOpen(true)}
                      requireEmails={requireStudentLogin}
                    />
                  </Box>
                  <InviteStudentModal
                    open={studentModalOpen}
                    onClose={() => setStudentModalOpen(false)}
                    courseId={courseId}
                    orgId={orgId}
                    onInviteSuccess={(message) => {
                      setInviteAlertInfo({ show: true, message, severity: 'success' });
                      setRefreshStudentTable(prev => !prev);
                    }}
                  />
                </Box>
              ) : (
                <Alert 
                  sx={{ mt: 2 }} 
                  variant="outlined" 
                  color="primary"
                  role="alert"
                  aria-live="polite"
                  endDecorator={
                    <Button 
                      variant="soft" 
                      size="sm" 
                      color="primary" 
                      component="a" 
                      href="/subscriptions?upgrade-trial=true"
                    >
                      View Plans
                    </Button>
                  }
                >
                  Student access restrictions are available on paid plans. Upgrade to enable this feature.
                </Alert>
              )}
            </Box>
          </TabPanel>
        </Tabs>
      </CourseCheck >
    </Container >
  );
}
