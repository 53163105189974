import React from 'react';
import { 
  Button,
  Snackbar
} from '@mui/joy';

const GlobalSnackbar = ({ 
  open, 
  onClose, 
  message, 
  color = 'success',
  autoHideDuration = 10000,
  ariaLive='polite'
}) => {
  return (
    <Snackbar
      variant="soft"
      color={color}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      role="alert"
      aria-live={ariaLive}
      data-testid="global-snackbar"
      endDecorator={
        <Button 
          onClick={onClose}
          variant="plain"
          color={color}
          size="sm"
        >
          Dismiss
        </Button>
      }
    >
      {message}
    </Snackbar>
  );
};

export default GlobalSnackbar;