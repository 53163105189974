import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MediaQuery from "react-responsive";
import {
  Box,
  Button,
  Snackbar,
} from '@mui/joy';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClassSelector from '../components/ClassSelector';
import Menu from '../components/Menu';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import './Header.css';

const Header = forwardRef((props, ref) => {
    const { chatbotRef } = props;
    const classSelectorRef = useRef();
    const onCourseChangeRef = useRef(null); // Store the onCourseChange callback
    const [courseUrl, setCourseUrl] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const location = useLocation();

    useImperativeHandle(ref, () => ({
        refreshCourseList: () => {
            classSelectorRef.current.refreshCourseList();
        },
        setOnCourseChange: (callback) => {
            onCourseChangeRef.current = callback; // Store the callback
        }
    }));

    const getBaseUrl = () => {
        const origin = window.location.origin;
        if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
            return 'http://localhost:3000';
        } else if (origin.includes('staging.alldayta.com')) {
            return 'https://staging.alldayta.com';
        } else {
            return 'https://app.alldayta.com';  
        }
    };

    const setFullUrl = (url) => {
        const baseUrl = getBaseUrl();
        const fullUrl = `${baseUrl}${url}`;
        setCourseUrl(fullUrl);
    };
    
    const handleCourseChange = (newCourseId, url) => {
        const baseUrl = getBaseUrl();
        const fullUrl = `${baseUrl}${url}`;
        setCourseUrl(fullUrl);
        if (onCourseChangeRef.current) {
            onCourseChangeRef.current(newCourseId); // Call the stored callback
        }
        window.scrollTo(0, 0)
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(courseUrl);
            setSnackbarOpen(true);
        } catch (err) {
            //console.error('Failed to copy: ', err);
        }
    };

    const renderButton = () => {
        const isPreviewPage = location.pathname === '/preview';

        if (isPreviewPage) {
            return (
                <Button
                    variant="plain"
                    size="md"
                    onClick={copyToClipboard}
                    endDecorator={<ContentCopyIcon />}
                    disabled={!courseUrl}
                >
                    Copy Student URL
                </Button>
            );
        } else {
            return (
                <Button
                    component="a"
                    href="/preview"
                    variant="plain"
                    color="primary"
                    size="md"
                    endDecorator={<RemoveRedEyeIcon />}
                    className="hide-mobile"
                >
                    Preview TA
                </Button>
            );
        }
    };

    return (
        <header className="header-wrapper">
        <Button
            variant="solid" 
            color="primary"
            size="lg"
            component="a" 
            href="#main-content"
            className="skip-to-content"
            onClick={(e) => {
                e.preventDefault();
                const mainContent = document.getElementById('main-content');
                if (mainContent) {
                  // Optionally, ensure the element can receive focus.
                  mainContent.setAttribute('tabindex', '-1');
                  mainContent.focus();
                }
              }}
        >
            Skip to main content
        </Button>
            <MediaQuery minWidth={541}>
            <Box className="left-wrapper">
                <a href="/files" aria-label="Files"><img src={Logo} alt="All Day TA - Files" className="logo" /></a>
                <a href="/files" aria-label="Files" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Files" className="logo-mobile" /></a>
                <ClassSelector chatbotRef={chatbotRef} ref={classSelectorRef} onCourseChange={handleCourseChange} setFullUrl={setFullUrl} />
            </Box>
            <Box className="right-wrapper">
                {renderButton()}
                <Menu courseUrl={courseUrl} />
            </Box>
            </MediaQuery>
            <MediaQuery maxWidth={540}>
                <Box className="left-wrapper">
                    <a href="/files"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                    {renderButton()}
                    <Menu courseUrl={courseUrl} />
                    </Box>
                </Box>
                <Box className="right-wrapper">
                    <ClassSelector chatbotRef={chatbotRef} ref={classSelectorRef} onCourseChange={handleCourseChange} setFullUrl={setFullUrl} />
                </Box>
            </MediaQuery>
            <Snackbar
                autoHideDuration={3000}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                color="success"
                variant="soft"
                role="alert"
                aria-live="polite"
            >
                URL copied to clipboard!
            </Snackbar>
        </header>
    );
});

export default Header;