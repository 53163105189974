import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import '../Modals.css';

export default function ShareFilesModal( { open, onClose, shareFiles, share_code }) {
  const fileCount = shareFiles ? shareFiles.length : 0;
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    color: 'primary',
  });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(share_code);
      setAlert({
        open: true,
        message: 'Share code copied successfully!',
        color: 'success',
      });
    } catch (err) {
      setAlert({
        open: true,
        message: 'Failed to copy share code. Please try again.',
        color: 'danger',
      });
    }
  };
  
  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          layout="fullscreen"
          variant="outlined"
          size="lg"
          className="modal-container"
        >
          <DialogTitle  id="share-modal-title"  className="modal-name">Share Course Data</DialogTitle>
          <ModalClose
            aria-label="Close Dialog"
           />
          <DialogContent>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                mb={4}
                >
                    <Typography id="modal-description" level="body-lg">
                      Send this share code to a colleague to allow them to import the data from this course to their course.
                    </Typography>
                    <Stack direction={{ xs:'column', sm:'row'}} alignItems={{ xs:'flex-start', sm:'flex-end'}} gap={1} sx={{ width: '100%'}}>
                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel>Share Code</FormLabel>
                        <Input
                          variant="outlined"
                          color="primary"
                          value={share_code}
                          readOnly
                          aria-readonly="true"
                        />
                      </FormControl>
                      <Button startDecorator={<ContentCopyIcon aria-hidden="true"/>} onClick={handleCopy} variant="solid" aria-label="Copy share code">Copy</Button> 
                    </Stack>
                  {alert.open && (
                    <Alert 
                      fullWidth
                      variant="soft" 
                      color={alert.color}
                      startDecorator={
                        alert.color === 'success' ? 
                          <CheckCircleIcon aria-label="Success" /> : 
                          <ErrorOutlineIcon aria-label="Error" />
                      }
                      role="status"
                      aria-live="polite"
                    >
                      {alert.message}
                    </Alert>
                  )}                   
                  <Typography level="title-lg">
                      The following {fileCount} {fileCount === 1 ? 'file' : 'files'} will be shared (scroll to view all):
                  </Typography>
              </Stack>
              <Box
                mb={4}
                tabIndex={0}  // Make it keyboard focusable
                role="region"  // Adds semantic meaning for screen readers
                aria-label="Shared files list" 
                className="scrollable-box"
                >
                <List sx={{  
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "8px",
                    }}>
                  {shareFiles && shareFiles.length > 0 ? (
                    shareFiles.map((file, index) => (
                      <ListItem key={index}>
                        {file.title}
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <Typography level="body-md" color="neutral">
                        No files selected for sharing.
                      </Typography>
                    </ListItem>
                  )}
                </List>
              </Box>
            </DialogContent>
            <DialogActions className="dialog-actions-right">
                <Button variant="outlined" onClick={onClose}>
                  Close
                </Button>
            </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
