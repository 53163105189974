import axios from 'axios';
import { useState } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Typography 
} from '@mui/joy';
import './Modals.css';
import FormErrorAlert from '../components/form-elements/FormErrorAlert';

export default function ImportFilesModal({ open, onClose, onImport }) {
    const [shareKey, setShareKey] = useState('');
    const [errorType, setErrorType] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleImport = async () => {
        // Reset error state
        setErrorType(null);
        setErrorMessage('');

        // Check for empty input
        if (!shareKey.trim()) {
            setIsSubmitted(true);
            setErrorType('empty');
            setErrorMessage('Please enter a share code');
            return;
        }

        try {
            if (!isProcessing) {
                setIsProcessing(true)
                await axios.post('/api/import', { share_key: shareKey });
                setIsProcessing(false)
                onImport();
                handleClose();
            }
        } catch (e) {
            setErrorType('invalid');
            setErrorMessage('Invalid share code');
            setIsProcessing(false)
        }
    };

    const handleClose = () => {
        if (!isProcessing) {
            setShareKey('')
            setErrorType(null);
            setErrorMessage('');
            onClose()
        }
    }
  
  return (
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <DialogTitle>Import Files</DialogTitle>
          <ModalClose 
            aria-label="Close Dialog"
          />
          <DialogContent>
                <Typography>
                  Enter a share code to import files from another course
                </Typography>
                <FormErrorAlert 
                    isSubmitted={isSubmitted}
                    errors={{
                        errorType: !!errorMessage
                    }}
                    errorMessages={{
                        errorType: errorMessage
                    }}
                />
                <Box sx={{ my: 0.4 }}> </Box>
                <FormControl sx={{ width: '100%' }} error={Boolean(errorType)}>
                    <FormLabel>Share Code</FormLabel>
                    <Input
                        variant="outlined"
                        color={errorType ? "danger" : "primary"}
                        required                    
                        value={shareKey}
                        onChange={(e) => setShareKey(e.target.value)}
                        onFocus={() => {
                            setErrorType(null);
                            setErrorMessage('');
                        }}
                        aria-invalid={!!errorType}
                    />
                    {errorType && (
                        <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions className="dialog-actions-right">
                <Button variant="outlined" color="primary" onClick={handleClose} disabled={isProcessing}>Cancel</Button>
                {isProcessing ? (
                    <Button 
                      variant="solid" 
                      color="primary" 
                      loading
                      loadingPosition="start"
                    >
                      Importing...
                    </Button>
                ) : (
                    <>
                    <Button variant="solid" color="primary" onClick={handleImport}>                    
                        Import
                    </Button>
                    </>
                )}
            </DialogActions>
        </ModalDialog>
      </Modal>
  );
}
