import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography
} from '@mui/joy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import LatexRenderer from '../LatexRenderer';
import CreateNoteModal from '../files/CreateNoteModal';
import GlobalSnackbar from '../GlobalSnackbar';
import '../Tables.css';


function Row(props) {
  const { row, handleCreateNoteClick, handleShowSnackbar } = props;
  const [open, setOpen] = useState(props.initialOpen || false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedQuestion, setTranslatedQuestion] = useState('');
  const [translatedAnswer, setTranslatedAnswer] = useState('');

  // Function to get appropriate label for rating
  const getRatingLabel = (rating) => {
    if (rating === true) return "Rated positively";
    if (rating === false) return "Rated negatively";
    return "Not rated";
  };

  // Function to translate non-english questions and queries temporarily
  const handleTranslateClick = async () => {
    // If we already have the translation, just toggle the view
    if (translatedQuestion || translatedAnswer) {
      setIsTranslated(!isTranslated);

      // Announce the language change via snackbar
      const message = !isTranslated 
        ? `Content now showing in English` 
        : `Content now showing in original language (${row.language})`;
      
      handleShowSnackbar(message, 'success', 'polite');
      return;
    }

    // Otherwise, fetch the translation
    setIsTranslating(true);
    handleShowSnackbar(`Translating content from ${row.language} to English...`, 'neutral', 'polite');

    try {   
      // Call the API with row.id as the question_id parameter
      const response = await axios.get(`/api/get_question_translation`, {
        params: {
          question_id: row.id,
          translate: true
        }
      });
      
      const data = response.data;

      // Set translated question if available
      if (data.translated_question) {
        setTranslatedQuestion(data.translated_question);
      } else {
        setTranslatedQuestion("Translation unavailable");
      }

      // Set translated answer if available
      if (data.translated_answer) {
        setTranslatedAnswer(data.translated_answer);
      } else {
        setTranslatedAnswer("Translation unavailable");
      }

      setIsTranslated(true);
      handleShowSnackbar(`Translation complete. Content is now shown in English.`, 'success', 'polite');
    } catch (error) {
      console.error('Error fetching translation:', error);
      setTranslatedQuestion("Error fetching translation");
      setTranslatedAnswer("Error fetching translation");
      setIsTranslated(true);
      handleShowSnackbar(`Translation failed. An error occurred while translating the content.`, 'danger', 'assertive');
    } finally {
      setIsTranslating(false);
    }
  };

  // Determine which text to display
  const displayQuestion = isTranslated ? translatedQuestion : row.question;
  const displayAnswer = isTranslated ? translatedAnswer : row.answer;

  return (
    <>
      <tr className="question-row" data-testid="question-row">
        <td className="question-column">
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              alignItems="center"
              spacing={1}
              >
              {row.issyllabus && (
                <Chip 
                  variant="soft" 
                  color="primary" 
                  size="sm"
                >
                  Syllabus
                </Chip>
              )}
              {row.language && row.language !== 'English' && (
                <Chip 
                  variant="outlined" 
                  color="neutral" 
                  size="sm"
                  data-testid="language-chip"
                >
                  {row.language}
                </Chip>
              )}
              <Typography
                level="body-md"
                data-testid="question-text"
              >
                {displayQuestion}
              </Typography>
            </Stack>
        </td>
        <td className="student-response-column">
            {row.rating !== null && row.rating !== undefined && (
              <span 
                role="img" 
                aria-label={getRatingLabel(row.rating)}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {row.rating === true ? 
                  <ThumbUpIcon aria-hidden="true" /> : 
                  <ThumbDownIcon aria-hidden="true" />
                }
              </span>
            )}
        </td>
        <td className="answer-column">
          <Button
            aria-label="expand row"
            variant="plain"
            color="neutral"
            size="sm"
            data-testid="answer-button"
            onClick={() => setOpen(!open)}
          >
            Answer
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0, textAlign: 'left' }} colSpan={6}>
          {open && (
            <Sheet
              variant="plain"
              sx={{ p: 2, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)', wordWrap:'break-word', }}
              data-testid="answer-content"
            >
              <Typography 
                level="body-md"
              >
                <LatexRenderer text={displayAnswer} />
              </Typography>
              <Stack direction="row" gap={1}>
                <Button variant="soft" color="neutral" onClick={handleCreateNoteClick} data-testid="add-answer-button" sx={{mt:1}}>
                  {row.note_id ? "View & edit your answer" : "Add an answer"}
                </Button>
                {row.language && row.language !== 'English' && (
                  <Button
                    variant="plain"
                    color="primary"
                    onClick={handleTranslateClick} 
                    data-testid="translate-button" 
                    sx={{mt:1, mr:1}}
                    disabled={isTranslating}
                    aria-pressed={isTranslated}
                    aria-busy={isTranslating}
                    aria-live="polite"
                    aria-atomic="true"
                    aria-label={
                      isTranslating 
                        ? 'Translating content, please wait' 
                        : isTranslated 
                          ? `Show original text in ${row.language}`
                          : `Translate from ${row.language} to English`
                    }
                  >
                    {isTranslating 
                      ? 'Translating...' 
                    : isTranslated 
                      ? `See original text in ${row.language}` 
                      : 'Translate to English'}
                  </Button>
                )}
              </Stack>
            </Sheet>
          )}
        </td>
      </tr>
    </>
  );
}


//This section is used for pagination
function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

//This section handles sorting the table
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'rating') {
    // Convert boolean/null to numeric values for comparison
    const ratingValue = (rating) => {
      if (rating === true) return 3;
      if (rating === null || rating === undefined) return 2;
      if (rating === false) return 1;
      return 0; // for null/undefined ratings
    };
    return ratingValue(b[orderBy]) - ratingValue(a[orderBy]);
  }
  if (orderBy === 'id') {
    return b.id - a.id;  // For numeric IDs
  }
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy]);
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export default function TableCollapsibleRow( { questionAndAnswers }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState(questionAndAnswers);
  const [sortOrder, setSortOrder] = useState({ field: 'id', direction: 'desc' });
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [noteTemplate, setNoteTemplate] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'success',
    ariaLive: 'polite'
  });

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Function to show snackbar - pass this to child components
  const handleShowSnackbar = (message, color = 'success', ariaLive = 'polite') => {
    setSnackbar({
      open: true,
      message,
      color,
      ariaLive
    });
  };

  useEffect(() => {
    setRows(questionAndAnswers);

  }, [questionAndAnswers]);

  //This section is used for pagination
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const handleCreateNoteClick = (row) => {
    let note_title = row.question;
    if (note_title.length > 50) {
      note_title = note_title.substring(0, 50) + "...";
    }
    const note_text = `Question:\n${row.question}\n\nAnswer:\n`;

    const note_template = {
      title: note_title,
      text: note_text,
    };

    setNoteTemplate(note_template);
    setSelectedQuestion(row);
    setCreateNoteModalOpen(true);
  };

  const handleNoteCreated = (file_id) => {
    setRows(rows.map(row => row.id === selectedQuestion.id ? { ...row, note_id: file_id } : row));
    handleShowSnackbar("Successfully updated answer!", "success");
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Sheet variant="plain">
      {/* Table Sort and Pagination */} 
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={2} className="table-toolbar">
        <FormControl>
          <FormLabel>Sort by:</FormLabel>
            <Select
              value={`${sortOrder.field}-${sortOrder.direction}`}
              onChange={(_, newValue) => {
                const [field, direction] = newValue.split('-');
                setSortOrder({ field, direction });
              }}
              sx={{ minWidth: 200 }}
              variant='plain'
              data-testid="sort-selector"
            >
              <div data-testid="sort-options">
                <Option value="id-desc" data-testid="sort-option">Most Recent</Option>
                <Option value="id-asc" data-testid="sort-option">Oldest</Option>
                <Option value="rating-desc" data-testid="sort-option">Rating: Positive First</Option>
                <Option value="rating-asc" data-testid="sort-option">Rating: Negative First</Option>
                <Option value="question-asc" data-testid="sort-option">Alphabetically A-Z</Option>
                <Option value="question-desc" data-testid="sort-option">Alphabetically Z-A</Option>
              </div>
            </Select>
        </FormControl>
        { /* Pagination Component */}
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl orientation="horizontal" size="md">
            <FormLabel>Rows/Page:</FormLabel>
            <Select data-testid="rows-per-page-selector" onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage}>
              <div data-testid="rows-per-page-options">
                <Option value={5} data-testid="rows-per-page-option">5</Option>
                <Option value={10} data-testid="rows-per-page-option">10</Option>
                <Option value={25} data-testid="rows-per-page-option">25</Option>
              </div>
            </Select>
          </FormControl>
          <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium'}}>
            {labelDisplayedRows({
              from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
              to: getLabelDisplayedRowsTo(),
              count: rows.length === -1 ? -1 : rows.length,
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Previous Page"
              disabled={page === 0}
              onClick={() => handleChangePage(page - 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Next Page"
              disabled={
                rows.length !== -1
                  ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                  : false
              }
              onClick={() => handleChangePage(page + 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Stack>
        { /* End Pagination Component */}
      </Stack>
      <Divider />
      {/* Table Body Rows */} 
      <Table
        hoverRow
        aria-label="collapsible table"
        size="md"
        data-testid="questions-table"
      >
        <tbody>
        {stableSort(rows, getComparator(sortOrder.direction, sortOrder.field))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <Row key={row.id} row={row} initialOpen={index === 0} handleCreateNoteClick={() => handleCreateNoteClick(row)} handleShowSnackbar={handleShowSnackbar}/>
          ))}
          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                "--TableRow-hoverBackground": "transparent",
              }}
            >
              <td colSpan={4} aria-hidden />
            </tr>
          )}
        </tbody>
      </Table>
      <GlobalSnackbar
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.ariaLive}
      />
      <CreateNoteModal 
        open={createNoteModalOpen}
        onClose={() => setCreateNoteModalOpen(false)}
        refresh={(file_id) => handleNoteCreated(file_id)}
        edit={selectedQuestion?.note_id ? true : false}
        file_id={selectedQuestion?.note_id}
        note_template={noteTemplate}
        question_id={selectedQuestion?.id}
      />
    </Sheet>
  );
}