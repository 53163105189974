import React, { useState, useEffect } from 'react';
import { IMaskInput, IMask } from 'react-imask';
import FormErrorAlert from '../form-elements/FormErrorAlert';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Checkbox,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Alert,
  Typography,
} from '@mui/joy';
import axios from 'axios';

const DateMaskAdapter = React.forwardRef(
  function DateMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0000-00-00"
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const TimeMaskAdapter = React.forwardRef(
  function TimeMaskAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={"h{:}mm A"}
        blocks={{
          // HH is for 24-hour format
          HH: {
            mask: function (value) {
              if (isNaN(value)) { return false; }
              const int_value = parseInt(value);
              return value.length < 3 && 0 <= int_value && int_value <= 24;
            },
            maxLength: 2
          },
          // h is for 12-hour format
          h: {
            mask: function (value) {
              if (isNaN(value)) { return false; }
              const int_value = parseInt(value);
              return value.length < 3 && 0 <= int_value && int_value <= 12;
            },
            maxLength: 2
          },
          mm: {
            mask: IMask.MaskedRange,
            placeholderChar: "0",
            from: 0,
            to: 59,
          },
          A: {
            mask: IMask.MaskedEnum,
            enum: ["AM", "am", "PM", "pm", "aM", "Am", "pM", "Pm"],
          },
        }}
        inputRef={ref}
        // onAccept is called each time the mask accepts new valid input.
        onAccept={(value, mask) => {
          // Cast the value to uppercase
          let newValue = value.toLowerCase();
          if (onChange) {
            onChange({ target: { name: props.name, value: newValue } });
          }
        }}
        overwrite
        autofix
      />
    );
  }
);

// Helper function to convert UTC to local YYYY-MM-DD
const formatUTCToLocalDate = (utcString) => {
  const date = new Date(utcString);
  
  // Use local date components (not UTC methods)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours());
  const minute = String(date.getMinutes()).padStart(2, '0');

  // Convert 24-hour time to 12-hour time 
  const period = hour >= 12 ? 'pm' : 'am';
  const hour12 = hour % 12 || 12;

  return { date: `${year}-${month}-${day}`, time: `${hour12}:${minute} ${period}` };
};

const disableUrlFormAlerts = {
  reactivated: {
    show: true,
    message: "Course URL access has been automatically reactivated as the specified date range has passed.",
    severity: 'success'
  },
  success: {
    show: true,
    message: "Course url access settings updated successfully",
    severity: 'success'
  },
  error: {
    show: true,
    message: "Error updating course settings.",
    severity: 'danger'
  }
};

export default function UrlDisableForm({ courseId, isLoading, updateDisableStatus, urlSettings = {}, setUrlSettings = () => {} }) {
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [disableUrlFormAlert, setDisableUrlFormAlert] = useState({ show: false, message: '', severity: 'info' });

  useEffect(() => {
    if (urlSettings.reactivated) {
      setDisableUrlFormAlert(disableUrlFormAlerts.reactivated);
    }
  }, [urlSettings.reactivated]);

  const startDateErrorHelperText = {
    format: "Invalid date.",
    order: "Start date and time must be before end date and time.",
    empty: "Start date and time is required."
  };

  const endDateErrorHelperText = {
    format: "Invalid date.",
    order: "End date and time must be after start date and time.",
    empty: "End date and time is required.",
    past: "End date and time must be in the future."
  };

  // Handle disable url change
  const handleDisableUrl = async (newValue) => {
    setUrlSettings(prevSettings => ({
      ...prevSettings,
      disableUrl: newValue
    }));
    if (!newValue) handleTimedDisableUrl(false);
  }

  const validateDate = (y, m, d) => {
    const m_int = parseInt(m);
    const d_int = parseInt(d);
    if (y?.length !== 4 || m?.length !== 2 || d?.length !== 2 || m_int < 1 || m_int > 12 || d_int < 1 || d_int > 31) {
      return false;
    }
    return true;
  }

  // Handle time gated disable url change
  const handleTimedDisableUrl = async (newValue) => {
    setUrlSettings(prevSettings => ({
      ...prevSettings,
      disableUrlTimed: newValue
    }));
    if (newValue) {
      handleDisableUrl(true);
      
      // Set dates and times when timed disable is activated
      const now = new Date();
      const threeHoursLater = new Date(now.getTime() + (3 * 60 * 60 * 1000)); // 3 hours in milliseconds
      const formattedStart = formatUTCToLocalDate(now.toISOString());
      const formattedEnd = formatUTCToLocalDate(threeHoursLater.toISOString());
      
      // Set the form fields
      setUrlSettings(prevSettings => ({
        ...prevSettings,
        disableUrlStartDate: formattedStart.date,
        disableUrlStartTime: formattedStart.time,
        disableUrlEndDate: formattedEnd.date,
        disableUrlEndTime: formattedEnd.time
      }));
      
      // Clear any previous errors
      setStartDateError("");
      setEndDateError("");
    }
    else {
      setUrlSettings(prevSettings => ({
        ...prevSettings,
        disableUrlStartDate: "",
        disableUrlStartTime: "",
        disableUrlEndDate: "",
        disableUrlEndTime: ""
      }));
    } 
  }

  const handleSaveUrlDisable = async () => {
    let urlDisableData = {
      course_id: courseId,
      active: urlSettings.disableUrl ? 1 : 0,
      disable_start_date: null,
      disable_end_date: null,
    }

    if (urlSettings.disableUrlTimed) {
      // Validate start date
      const [start_y, start_m, start_d] = urlSettings.disableUrlStartDate.split("-");
      const start_date_valid = validateDate(start_y, start_m, start_d);
      if (!start_date_valid) {
        setStartDateError(startDateErrorHelperText[urlSettings.disableUrlStartDate ? "format" : "empty"]);
      }

      // Validate end date
      const [end_y, end_m, end_d] = urlSettings.disableUrlEndDate.split("-");
      const end_date_valid = validateDate(end_y, end_m, end_d);
      if (!end_date_valid) {
        setEndDateError(endDateErrorHelperText[urlSettings.disableUrlEndDate ? "format" : "empty"]);
      }

      // Convert 12-hour time to 24-hour time
      let [start_hour, start_min, start_period] = urlSettings.disableUrlStartTime.split(/[\s:]+/);
      start_hour = parseInt(start_hour);
      if (start_period === "pm" && start_hour !== 12) start_hour += 12;
      else if (start_period === "am" && start_hour === 12) start_hour = 0;
      const start_time_valid = 0 <= start_hour && start_hour <= 23 && 0 <= start_min && start_min <= 59;
      if (!start_time_valid) {
        setStartDateError(startDateErrorHelperText["empty"]);
      }

      // Convert 12-hour time to 24-hour time
      let [end_hour, end_min, end_period] = urlSettings.disableUrlEndTime.split(/[\s:]+/);
      end_hour = parseInt(end_hour);
      if (end_period === "pm" && end_hour !== 12) end_hour += 12;
      else if (end_period === "am" && end_hour === 12) end_hour = 0;
      const end_time_valid = !!end_hour && !!end_min;
      if (!end_time_valid) {
        setEndDateError(endDateErrorHelperText["empty"]);
      }

      // Return if either date is invalid
      if (!start_date_valid || !end_date_valid || !start_time_valid || !end_time_valid) {
        return;
      }

      let disableStartUTC = null;
      let disableEndUTC = null;

      try {
        const localStart = new Date(start_y, start_m - 1, start_d, start_hour, start_min, 0, 0);
        disableStartUTC = localStart.toISOString(); // Convert to UTC
      } catch (error) {
        setStartDateError(startDateErrorHelperText["format"]);
        return;
      }

      try {
        const localEnd = new Date(end_y, end_m - 1, end_d, end_hour, end_min, 0, 0);
        disableEndUTC = localEnd.toISOString(); // Convert to UTC
      } catch (error) {
        setEndDateError(endDateErrorHelperText["format"]);
        return;
      }

      if (disableEndUTC < new Date().toISOString()) {
        setEndDateError(endDateErrorHelperText["past"]);
        return;
      }

      if (disableStartUTC && disableEndUTC && disableStartUTC > disableEndUTC) {
        setStartDateError(startDateErrorHelperText["order"]);
        setEndDateError(endDateErrorHelperText["order"]);
        return;
      }

      urlDisableData.disable_start_date = disableStartUTC;
      urlDisableData.disable_end_date = disableEndUTC;
    }

    try {
      const response = await axios.post('/api/update_course_settings', {
        course_id: courseId,
        active: !urlSettings.disableUrl ? 1 : 0,
        disable_start_date: urlDisableData.disable_start_date,
        disable_end_date: urlDisableData.disable_end_date,
      });
      if (response.status === 200) {
        setDisableUrlFormAlert(disableUrlFormAlerts.success);
        updateDisableStatus(response.data.settings.active, response.data.settings.disable_start_date, response.data.settings.disable_end_date);
        setUrlSettings(prevSettings => ({
          ...prevSettings,
          disableUrl: urlSettings.disableUrl,
          disableUrlTimed: urlSettings.disableUrlTimed,
          disableUrlStartDate: urlSettings.disableUrlTimed ? urlSettings.disableUrlStartDate : "",
          disableUrlStartTime: urlSettings.disableUrlTimed ? urlSettings.disableUrlStartTime : "",
          disableUrlEndDate: urlSettings.disableUrlTimed ? urlSettings.disableUrlEndDate : "",
          disableUrlEndTime: urlSettings.disableUrlTimed ? urlSettings.disableUrlEndTime : "",
          reactivated: false
        }));
      }
    } catch (error) {
      console.error('Error updating course settings:', error);
      setDisableUrlFormAlert(disableUrlFormAlerts.error);
    }
  }

  return (
    <>
      <Card variant="outlined" role="group" aria-label="disable-url-form" sx={{ gap: 0 }}>
        <CardContent sx={{ gap: 2 }}>
          <Typography level="h3">Disable Access</Typography>
          <FormErrorAlert
            isSubmitted={urlSettings.disableUrl && urlSettings.disableUrlTimed}
            errors={{
              startDate: !!startDateError,
              endDate: !!endDateError
            }}
            errorMessages={{
              startDate: startDateError,
              endDate: endDateError
            }}
          />
          <Checkbox
            checked={urlSettings.disableUrl}
            onChange={(e) => handleDisableUrl(e.target.checked)}
            color="primary"
            size="md"
            label="Disable all access via course url"
            disabled={isLoading}
          />
          <Stack ml={4}>
            <Checkbox
              checked={urlSettings.disableUrlTimed}
              onChange={(e) => handleTimedDisableUrl(e.target.checked)}
              color="primary"
              size="md"
              label="(Optional) Set a date range where the course url will be unavailable"
              disabled={isLoading}
            />
            <Stack direction={{ xs: "column", sm: "row" }} ml={4} mt={1} spacing={1}>
              <Stack spacing={1}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                  <FormControl sx={{ width: "11rem" }} error={!!startDateError}>
                    <FormLabel>Start Date (yyyy-mm-dd)</FormLabel>
                    <Input
                      variant='outlined'
                      color={startDateError ? "danger" : "primary"}
                      value={urlSettings.disableUrlStartDate}
                      onChange={(e) => {
                        setUrlSettings(prevSettings => ({
                          ...prevSettings,
                          disableUrlStartDate: e.target.value
                        }));
                        setStartDateError(false);
                      }}
                      disabled={isLoading || !urlSettings.disableUrlTimed}
                      placeholder="yyyy-mm-dd"
                      slotProps={{ input: { component: DateMaskAdapter } }}
                      aria-describedby="start-datetime-helper-text"
                      aria-invalid={!!startDateError}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "11rem" }} error={!!startDateError}>
                    <FormLabel>Start Time (hh:mm am/pm)</FormLabel>
                    <Input
                      variant='outlined'
                      color={startDateError ? "danger" : "primary"}
                      value={urlSettings.disableUrlStartTime}
                      onChange={(e) => {
                        setUrlSettings(prevSettings => ({
                          ...prevSettings,
                          disableUrlStartTime: e.target.value
                        }));
                        setStartDateError(false);
                      }}
                      disabled={isLoading || !urlSettings.disableUrlTimed}
                      placeholder='hh:mm am/pm'
                      slotProps={{ input: { component: TimeMaskAdapter } }}
                      aria-describedby="start-datetime-helper-text"
                      aria-invalid={!!startDateError}
                    />
                  </FormControl>
                </Stack>
                <FormHelperText
                  id="start-datetime-helper-text"
                  sx={{
                    color: startDateError ? 'var(--joy-palette-danger-outlinedColor)' : 'var(--joy-palette-primary-outlinedColor)',
                    '--FormHelperText-fontSize': 'var(--joy-fontSize-sm)',
                    '--FormHelperText-lineHeight': 'var(--joy-lineHeight-sm)'
                  }}
                >
                  {startDateError}
                </FormHelperText>
              </Stack>
              <FormLabel component={"p"} sx={{
                mx: 2,
                alignContent: "baseline"
              }}>
                to
              </FormLabel>
              <Stack spacing={1}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                  <FormControl sx={{ width: "11rem" }} error={!!endDateError}>
                    <FormLabel>End Date (yyyy-mm-dd)</FormLabel>
                    <Input
                      variant='outlined'
                      color={endDateError ? "danger" : "primary"}
                      value={urlSettings.disableUrlEndDate}
                      onChange={(e) => {
                        setUrlSettings(prevSettings => ({
                          ...prevSettings,
                          disableUrlEndDate: e.target.value
                        }));
                        setEndDateError(false);
                      }}
                      disabled={isLoading || !urlSettings.disableUrlTimed}
                      placeholder="yyyy-mm-dd"
                      slotProps={{ input: { component: DateMaskAdapter } }}
                      aria-describedby="end-datetime-helper-text"
                      aria-invalid={!!endDateError}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "11rem" }} error={!!endDateError}>
                    <FormLabel>End Time (hh:mm am/pm)</FormLabel>
                    <Input
                      variant='outlined'
                      color={endDateError ? "danger" : "primary"}
                      value={urlSettings.disableUrlEndTime}
                      onChange={(e) => {
                        setUrlSettings(prevSettings => ({
                          ...prevSettings,
                          disableUrlEndTime: e.target.value
                        }));
                        setEndDateError(false);
                      }}
                      disabled={isLoading || !urlSettings.disableUrlTimed}
                      placeholder='hh:mm am/pm'
                      slotProps={{ input: { component: TimeMaskAdapter } }}
                      aria-describedby="end-datetime-helper-text"
                      aria-invalid={!!endDateError}
                    />
                  </FormControl>
                </Stack>
                <FormHelperText
                  id="end-datetime-helper-text"
                  sx={{
                    color: endDateError ? 'var(--joy-palette-danger-outlinedColor)' : 'var(--joy-palette-primary-outlinedColor)',
                    '--FormHelperText-fontSize': 'var(--joy-fontSize-sm)',
                    '--FormHelperText-lineHeight': 'var(--joy-lineHeight-sm)'
                  }}
                >
                  {endDateError}
                </FormHelperText>
              </Stack>
            </Stack>
          </Stack>
          {disableUrlFormAlert.show && (
            <Alert
              variant="soft"
              color={disableUrlFormAlert.severity}
              role="alert"
              aria-live="polite"
              endDecorator={
                <Button variant="soft" size="sm" color={disableUrlFormAlert.severity} onClick={async () => {
                  if (disableUrlFormAlert.message === disableUrlFormAlerts.reactivated.message) {
                    try {
                      await axios.post('/api/update_course_settings', {
                        course_id: courseId,
                        disable_start_date: null,
                        disable_end_date: null,
                      });
                      setUrlSettings(prevSettings => ({
                        ...prevSettings,
                        disableUrlStartDate: "",
                        disableUrlStartTime: "",
                        disableUrlEndDate: "",
                        disableUrlEndTime: "",
                        reactivated: false
                      }));
                    } catch (error) {
                      console.error('Error reactivating course:', error);
                      setDisableUrlFormAlert(disableUrlFormAlerts.error);
                    }
                  }

                  setDisableUrlFormAlert({ ...disableUrlFormAlert, show: false });
                }}
                >
                  Dismiss
                </Button>
              }
            >
              {disableUrlFormAlert.message}
            </Alert>
          )}
        </CardContent>
        <CardActions buttonFlex="row">
          <Button variant="outlined" color="primary" onClick={handleSaveUrlDisable}>Save Access Settings</Button>
        </CardActions>
      </Card>
    </>
  );
}