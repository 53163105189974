import { useState, useCallback } from "react";
import axios from "axios";
import { chatbotErrors } from "../config/chatbotErrors";
import { PROMPT_SETTINGS_DEFAULT } from "../config/promptSettings";

const MINIMUM_LOADING_TIME = 2000;

export default function useChatbot() {
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [courseId, setCourseId] = useState(0);
  const [isInitializing, setIsInitializing] = useState(true);
  const [error, setError] = useState(null);
  const [courseUrl, setCourseUrl] = useState("");

  const initializeChatbot = useCallback(async (courseUrl = "") => {
    setIsInitializing(true);
    const startTime = Date.now();

    try {
      setCourseUrl(courseUrl); // Used to tell if this is an admin preview or student chatbot
      const response = courseUrl
        ? await axios.post(`/api/initialize_chatbot`, { course_url: courseUrl }) // Student init
        : await axios.get(`/api/initialize_chatbot`); // Preview init
      if (response.status === 200) {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);

        setTimeout(() => {
          setCourseId(response.data.course_id);
          setQuestionsAndAnswers([]);
          setIsInitializing(false);
        }, remainingTime);
      }
    } catch (err) {
      if (err?.response.status === 404) setError(chatbotErrors.course_not_found);
      else setError(chatbotErrors.general_error);
      setIsInitializing(false);
    }
  }, []);

  const askQuestion = async (query, promptSettings = PROMPT_SETTINGS_DEFAULT) => {
    if (!courseId) return;

    const newQuestion = {
      id: questionsAndAnswers.length + 1,
      question: query, // Use the stored query
      answer: "Processing...",
      isLoading: true,
      hasAnswer: true,
      question_log_org_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
      question_log_course_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
      question_log_question_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
      question_log_user_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
    };

    setQuestionsAndAnswers((prev) => [newQuestion, ...prev]);

    try {
      const lastQA =
        questionsAndAnswers.length > 0
          ? JSON.stringify({
            question: questionsAndAnswers[0].question,
            answer: questionsAndAnswers[0].answer,
          })
          : "";

      const response = await axios.post("/api/chatbot_query", {
        query,
        prior_q_and_response: lastQA,
        course_id: courseId,
        student_interface: !!courseUrl,
        prompt_settings: promptSettings,
      });
      const [newq_org_id, newq_course_id, newq_question_id, newq_user_id] =
        response.data.question_log_identifiers;
      const jsonResponse = JSON.parse(response.data.response);

      const updatedQuestion = {
        ...newQuestion,
        answer: jsonResponse.answer,
        footnotes: jsonResponse.footnotes,
        isLoading: false,
        question_log_org_id: newq_org_id,
        question_log_course_id: newq_course_id,
        question_log_question_id: newq_question_id,
        question_log_user_id: newq_user_id,
      };

      setQuestionsAndAnswers((prevState) =>
        prevState.map((qa) => (qa.id === newQuestion.id ? updatedQuestion : qa))
      );
    } catch (err) {
      setQuestionsAndAnswers((prev) =>
        prev.map((qa) =>
          qa.id === newQuestion.id
            ? {
              ...qa,
              answer: err?.response?.data?.message || "An error occurred. Please try again later.",
              isLoading: false,
            }
            : qa
        )
      );
    }
  };

  return {
    questionsAndAnswers,
    isInitializing,
    error,
    setError,
    askQuestion,
    initializeChatbot,
  };
}
