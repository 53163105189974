import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import {
  Alert,
  Box,
  Card,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  Typography
} from '@mui/joy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Container from '../components/Container';
import QuestionTable from '../components/summaries/QuestionTable';
import { SummaryCardSkeleton, QuestionTableSkeleton } from '../components/LoadingSkeletons';
import CourseCheck from '../components/CourseCheck';
import EmailSettingsCheckbox from '../components/summaries/EmailSettingsCheckbox';
import DownloadCSVButton from '../components/summaries/DownloadCSVButton';

import './Summaries.css';

export default function Summaries( { setOnCourseChange } ) {
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeks, setWeeks] = useState([]);
  const [questionAnswerInWeek, setQuestionAnswerInWeek] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [prevWeekQuestionCount, setPrevWeekQuestionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    document.title = "Weekly Summaries - All Day TA";
  }, []);

  function getStartAndEndTimes(weekString) {
    const [dateRange, year] = weekString.split(', ');
    const [startDate, endDate] = dateRange.split(' - ');
    
    // Handle year transition
    let startYear = parseInt(year);
    let endYear = startYear;
    
    // If start month is December and end month is January, the start date should be in the previous year
    if (startDate.includes('Dec') && endDate.includes('Jan')) {
        startYear = startYear - 1;
    }
    
    const start = new Date(`${startDate} ${startYear}`);
    const end = new Date(`${endDate} ${endYear}`);
    
    const startTime = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')} 00:00:00.000000+00`;
    const endTime = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')} 23:59:59.999999+00`;
    
    return { startTime, endTime };
  }

  const getQuestionLogs = async (startDate, endDate) => {
    try {
        const params = {
            start_time: startDate, 
            end_time: endDate,
        };
        //console.log('Requesting question logs with params:', params);
        const response = await axios.get('/api/get_question_log', { params });
        //console.log('Response data:', response.data);

        if (response.data.results.length === 0) {
          //console.log('No question logs found for the given date range');
          setQuestionAnswerInWeek([]);
          setQuestionCount(0);
          return;
        }

        const processedData = response.data.results.map((item) => ({
          id: item.question_id,
          question: item.original_question,
          flagged: item.flag_unanswered,
          answer: item.answer.replace(/\[\d+\]\s*/g, ''),
          rating: item.response_rating,
          language: item.language,
          issyllabus: item.issyllabus,
          note_id: item.note_id
      }));
      //console.log('Processed data:', processedData);

      setQuestionAnswerInWeek(processedData);
      setQuestionCount(processedData.length)
    } catch (error) {
        //console.error('Error fetching question logs:', error);
    }
  };

  const getPreviousWeekQuestionCount = useCallback(async (currentWeekIndex) => {
    try {
      if (currentWeekIndex >= weeks.length - 1 || weeks.length <= 1) {
        setPrevWeekQuestionCount(0);
        return;
      }
      
      const prevWeekString = weeks[currentWeekIndex + 1];
      const { startTime, endTime } = getStartAndEndTimes(prevWeekString);
      
      const params = {
        start_time: startTime, 
        end_time: endTime,
      };
      
      const response = await axios.get('/api/get_question_log', { params });
      
      if (response.data.results.length === 0) {
        setPrevWeekQuestionCount(0);
        return;
      }
      
      // Set the previous week's question count
      setPrevWeekQuestionCount(response.data.results.length);
    } catch (error) {
      //console.error('Error fetching previous week question count:', error);
      setPrevWeekQuestionCount(0);
    }
  }, [weeks]);

  const fetchSummary = async (startDate, endDate) => {
    try {
        const response = await axios.get('/api/summary', {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        });
        setSummary(response.data.summary);
    } catch (error) {
      //console.error('Error fetching summary:', error);
      setSummary('');
    }
  };

  const fetchWeeks = async () => {
    try {
        setIsLoading(true);
        const response = await axios.get('/api/weeks');
        //console.log(response.data.weeks);
        
        if (response.data.weeks.length === 0) {
          setError("No weeks found. Please check back later.");
          setWeeks([]);
          setSelectedWeek("");
          setShowAlert(true);
        } else {
          const parsedWeeks = response.data.weeks.map(week => {
            const [dateRange, year] = week.split(', ');
            const [startDate, endDate] = dateRange.split(' - ');
            const startDateObj = new Date(`${startDate} ${year}`);
            const endDateObj = new Date(`${endDate} ${year}`);

            // If end date is in January and start date is in December, adjust the start date year
            if (startDate.includes('Dec') && endDate.includes('Jan')) {
              startDateObj.setFullYear(startDateObj.getFullYear() - 1);
            }

            return {
              originalString: week,
              date: endDateObj
            };
          });

        const sortedWeeks = parsedWeeks.sort((a, b) => b.date - a.date);

          setWeeks(sortedWeeks.map(week => week.originalString));
          setSelectedWeek(sortedWeeks[0].originalString);
          setShowAlert(false);
        }
        setError(null);
      } catch (error) {
          //console.error('Error fetching weeks:', error);
          setError("An error occurred while fetching weeks. Please try again later.");
          setShowAlert(true);
      } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (selectedWeek) {
      //console.log("selected week", selectedWeek);
      setIsLoading(true);
      const { startTime, endTime } = getStartAndEndTimes(selectedWeek);
      getQuestionLogs(startTime, endTime).finally(() => setIsLoading(false));
      const startDateTrimmed = startTime.split(' ')[0];
      const endDateTrimmed = endTime.split(' ')[0];
      fetchSummary(startDateTrimmed, endDateTrimmed);
      
      // Get the index of the selected week to find the previous week
      const currentWeekIndex = weeks.findIndex(week => week === selectedWeek);
      if (currentWeekIndex !== -1) {
        getPreviousWeekQuestionCount(currentWeekIndex);
      }
    }  
  }, [selectedWeek, weeks, getPreviousWeekQuestionCount]);

  useEffect(() => {
      fetchWeeks();
  }, []);

  useEffect(() => {
    if (setOnCourseChange) {
        setOnCourseChange(() => {
            fetchWeeks();
            if (selectedWeek) {
              setIsLoading(true);
              const { startTime, endTime } = getStartAndEndTimes(selectedWeek);
              getQuestionLogs(startTime, endTime).finally(() => setIsLoading(false));
              const startDateTrimmed = startTime.split(' ')[0];
              const endDateTrimmed = endTime.split(' ')[0];
              fetchSummary(startDateTrimmed, endDateTrimmed);
            }
        });
    }
  }, [setOnCourseChange, selectedWeek]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography level="h1" mb={3}>Weekly Summaries</Typography>
        </Grid>
        <CourseCheck>
        <Grid xs={12}>
          {showAlert && error && (
            <Alert
              variant="soft"
              color="warning"
              sx={{ mt: 2 }}
              role={showAlert ? "alert" : "status"} 
              aria-live={showAlert ? "polite" : "off"}
              >
              {error}
            </Alert>
          )}
          {showAlert && weeks.length === 0 && !error && (
            <Alert
              variant="soft"
              color="neutral"
              size="lg"
              sx={{ mt: 2, alignItems:'flex-start' }}
              role={showAlert ? "alert" : "status"} 
              aria-live={showAlert ? "polite" : "off"}
              startDecorator={
                <InfoOutlinedIcon fontSize="xl3" />
              }
            >
              <div>
                <Typography level="h3" component="p" mb={1}>No summaries available</Typography>
                <Typography level="body-md">
                  No questions have been asked yet for this course. Please check back later.
                </Typography>
              </div>
            </Alert>
          )}
        </Grid>
          {!showAlert && (
            <>
            <Grid xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs:'flex-start', sm:'space-between' }}
                alignItems={{ xs:'flex-start', sm:'flex-end' }}
                gap={2}
              >
                <FormControl>
                    <FormLabel>Select Week</FormLabel>
                    <Select
                      data-testid="week-selector"
                      variant='outlined'
                      color='primary'
                      value={selectedWeek}
                      onChange={(_, newValue) => setSelectedWeek(newValue)}
                      sx={{ width: '13.75rem' }}
                      >
                      <div data-testid="week-selector-options">
                        {weeks.map((week) => (
                          <Option data-testid="week-option" key={week} value={week}>
                          {week}
                          </Option>
                        ))}
                      </div>
                    </Select>
                </FormControl>
                <EmailSettingsCheckbox isLoading={isLoading} />
              </Stack>
            </Grid>
            {isLoading ? (
              <SummaryCardSkeleton />
            ) : (
              <Grid xs={12}>
                <Card variant='soft' sx={{ mb: 3, mt: 3, padding: '2.2rem' }}>
                  <Grid container>
                    <Grid xs={12} sm={2}>
                      <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{ fontSize: '2.25rem', fontWeight: '700' }} mr={2}>
                        {questionCount}
                        </Typography>
                        {prevWeekQuestionCount > 0 && (
                          questionCount > prevWeekQuestionCount ? (
                              <ArrowUpwardIcon color="success" fontSize="xl4" titleAccess="An increase in questions since last week"/>
                          ) : questionCount < prevWeekQuestionCount ? (
                              <ArrowDownwardIcon color="danger" fontSize="xl4" titleAccess="A decrease in questions since last week"/>
                          ) : null
                        )}
                      </Box>
                      <Typography level="body-sm" sx={{ fontFamily: 'componentslt', color: 'var(--joy-palette-primary-700)' }}>Questions Asked</Typography>
                    </Grid>
                    <Grid xs={12} sm={10}  className="card-separator">
                      <Typography level="h2" sx={{ fontSize: '1.5rem'}} mb={2}>
                        Summary
                      </Typography>
                      <Typography level="body-md">
                        {summary}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            <Grid xs={12} sx={{ textAlign: 'left' }}>
              <Stack direction="row" spacing={2} mb={3}>
                <Typography level="h3" component="h2">
                  Questions
                </Typography>
                {!isLoading && selectedWeek && (
                  <DownloadCSVButton 
                    startTime={getStartAndEndTimes(selectedWeek).startTime}
                    endTime={getStartAndEndTimes(selectedWeek).endTime}
                    isDisabled={questionCount === 0}
                  />
                )}
              </Stack>
              {isLoading ? (
                <QuestionTableSkeleton />
              ) : (
                <>
                <QuestionTable questionAndAnswers={questionAnswerInWeek}/>
                </>
              )}
            </Grid>
          </>
        )}
        </CourseCheck>
      </Grid>
    </Container>
  );
}

