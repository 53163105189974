import axios from 'axios';
import { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
  Alert
} from '@mui/joy';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import GlobalSnackbar from '../GlobalSnackbar';
import '../Modals.css';

export default function DeleteFileModal({ open, onClose, files, modules, onDelete, onConfirmDelete }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'neutral',
  });

  const handleDelete = async () => {
    try {
      // Update the status of all files to "Currently Deleting..."
      files.forEach(file => onDelete(file));
      modules.forEach(module => onDelete(module));

      // Close the modal
      onClose();

      // Delete the modules first
      if (modules?.length > 0) {
        try {
          await axios.delete('/api/modules', { data: { module_ids: modules.map(module => module.id) } });

          // Confirm deletion for each module
          modules.forEach(module => onConfirmDelete(module));
        } catch (error) {
          console.error('Error deleting modules:', error);
        }
      }

      // Delete the files
      if (files?.length > 0) {
        // Prepare the files for deletion
        const filesToDelete = files.map(file => ({
          org_id: file.org_id,
          course_id: file.course_id,
          file_id: file.file_id,
        }));

        // Delete the files
        await axios.delete('/api/delete_files', { data: { files: filesToDelete } });

        // Confirm deletion for each file
        files.forEach(file => onConfirmDelete(file));
      }

      setSnackbar({
        open: true,
        message: 
        (modules?.length > 0 ? modules?.length === 1 ? modules?.length + " module" : modules?.length + " modules" : "") + 
        (files?.length > 0 && modules?.length > 0 ? " and " : "") + 
        (files?.length > 0 ? files?.length === 1 ? files?.length + " file" : files?.length + " files" : "") + 
          " deleted successfully.",
        color: 'success',
      });
    } catch (error) {
      console.error('Error deleting files:', error);
      setSnackbar({
        open: true,
        message: "Error deleting files. Please try again.",
        color: 'danger',
      });
    }
  };
  
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          size="md"
          className="modal-container-mobile-fullscreen"
          sx={{
            maxWidth: '50vw',
            width: '100%',
          }}
        >
          <DialogTitle>Confirm File Deletion</DialogTitle>
          <ModalClose 
            aria-label="Close Dialog"
          />
          <DialogContent>
            <Stack direction="column" gap={3}>
            <Alert 
              component="div"
              variant="soft" 
              color="warning"
              startDecorator={<WarningRoundedIcon />}
              sx={{ alignItems: 'flex-start' }}
            >
              <Stack spacing={1}>
                <Typography level="title-md" fontWeight="bold">
                  Are you sure you want to delete the following
                  {modules?.length > 0 ? modules?.length === 1 ? " " + modules?.length + " module" : " " + modules?.length + " modules" : ""}
                  {files?.length > 0 && modules?.length > 0 ? " and" : ""}
                  {files?.length > 0 ? files?.length === 1 ? " " + files?.length + " file" : " " + files?.length + " files" : ""}
                  ?
                </Typography>
                <Typography level="body-md">
                  {files?.length > 0 && "The content from selected files will no longer contribute to answering students' questions."}
                  {modules?.length > 0 && " Deleting modules also deletes all files in the modules."}
                </Typography>
              </Stack>
            </Alert>
          
            {modules && modules.length > 0 && (
              <Stack>
                <Typography level="title-md">Modules</Typography>
                <List
                  tabIndex={0}  // Make it keyboard focusable
                  aria-label="List of modules for deletion" 
                  className="scrollable-box"
                  sx={{  
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "0px",
                    maxHeight: `calc(${3.5} * var(--ListItem-minHeight) + var(--ListItem-gap) + 2 * var(--ListItem-paddingY))`,
                    overflowY: "auto",
                    padding: "0px",
                }}>
                {modules && modules.map((module, index) => (
                  <ListItem key={module.id} sx={{width:'100%'}}>
                    {module.title}
                  </ListItem>
                ))}
              </List>
              </Stack>
            )}

            {files && files.length > 0 && (
              <Stack>
                <Typography level="title-md">Files</Typography>
                <List
                  tabIndex={0}  // Make it keyboard focusable
                  aria-label="List of files for deletion" 
                  className="scrollable-box"
                  sx={{  
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "0px",
                    maxHeight: `calc(${3.5} * var(--ListItem-minHeight) + var(--ListItem-gap) + 2 * var(--ListItem-paddingY))`,
                    overflowY: "auto",
                    padding: "0px"
                }}>
                {files && files.map((file, index) => (
                  <ListItem key={file.file_id} sx={{width:'100%'}}>
                    {file.title}
                  </ListItem>
                  ))}
                </List>
              </Stack>
            )}
            </Stack>
          </DialogContent>
          <DialogActions className="dialog-actions-right">
              <Button variant="outlined" color="primary" onClick={onClose}>Cancel</Button>
              <Button variant="solid" color="danger" onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <GlobalSnackbar
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </>  
  );
}
