import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {  
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    Input,
    Stack,
    Typography,
} from '@mui/joy';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Container from '../components/Container';
import FormErrorAlert from '../components/form-elements/FormErrorAlert';
import { AuthContext } from '../context/AuthContext';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';

export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({ newPassword: '', confirmPassword: '' });
    const navigate = useNavigate();
    const location = useLocation();
    const { login, isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Reset Password - All Day TA";
        // Check if the user is already authenticated
        if (isAuthenticated) {
            navigate('/files', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromUrl = searchParams.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        } else {
            setMessage('No reset token found. Please request a new password reset.');
            setIsError(true);
        }
    }, [location]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = { newPassword: '', confirmPassword: '' };

        if (!newPassword) {
            newErrors.newPassword = 'Password is required.';
            isValid = false;
        } else if (newPassword.length < 12) {
            newErrors.newPassword = 'Password must be at least 12 characters.';
            isValid = false;
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = 'Must confirm password.';
            isValid = false;
        } else if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords must match.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (!validateForm()) {
          return;
        }
        try {
            const response = await axios.post('/api/reset_password', {
                token,
                new_password: newPassword,
                confirm_password: confirmPassword
            });
            setMessage('Password reset successfully!');
            setIsError(false);
            setTimeout(() => navigate('/login/admin'), 3000);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setMessage(error.response.data.error);
            } else {
                setMessage('An error occurred. Please try again later.');
            }
            setIsError(true);
        }
    };

    const handleOnPasswordChange = (e) => {
        setNewPassword(e.target.value);
        if (errors.newPassword) {
            setErrors(prev => ({ ...prev, newPassword: '' }));
        }
    };

    const handleOnConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (errors.confirmPassword) {
            setErrors(prev => ({ ...prev, confirmPassword: '' }));
        }
    };

    return (
        <Box>
            <header className="min-header-wrapper">
                <Box className="left-wrapper">
                    <a href="/login/admin" aria-label="Login"><img src={Logo} alt="All Day TA - Login" className="logo" /></a>
                    <a href="/login/admin" aria-label="Login" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Login" className="logo-mobile" /></a>
                </Box>
                <Box className="right-wrapper">
                    <Button
                        variant="plain"
                        component="a"
                        href="https://alldayta.com/support"
                        startDecorator={<HelpIcon />}
                        >
                        Contact Support
                    </Button>
                </Box>
            </header>
            <Container>
                <Grid container xs={12} mx='auto' spacing={2} justifyContent="space-around"
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh',
                }}>
                    <Grid container xs={12} sm={4}>
                        <Stack
                            direction="column"
                            alignItems="left"
                            spacing={3}
                            sx={{ width:'100%' }}
                            role="group"
                            aria-labelledby="reset-password"
                            >
                            <Box>
                                <Typography level="h1" mb={1} id="reset-password">Reset Password</Typography>
                            </Box>
                            {message && (
                                <Alert
                                    color={isError ? 'danger' : 'success'} 
                                    sx={{ mb: 2 }}
                                    role="alert"
                                    aria-live="polite"
                                    >
                                    {message}
                                </Alert>
                            )}
                            <FormErrorAlert 
                                isSubmitted={isSubmitted}
                                errors={{
                                    newPassword: !!errors.newPassword,
                                    confirmPassword: !!errors.confirmPassword
                                }}
                                errorMessages={{
                                    newPassword: errors.newPassword,
                                    confirmPassword: errors.confirmPassword
                                }}
                            />
                            <form onSubmit={handleSubmit}>
                                <FormControl sx={{ mb: 2 }} error={!!errors.newPassword}>
                                    <FormLabel>New Password</FormLabel>
                                    <Input 
                                        type="password"
                                        variant="outlined" 
                                        color={errors.newPassword ? "danger" : "primary"}
                                        fullWidth  
                                        value={newPassword}
                                        onChange={handleOnPasswordChange}
                                        disabled={!token}
                                        aria-invalid={!!errors.newPassword}
                                    />
                                    <FormHelperText>
                                        {errors.newPassword || 'Must be at least 12 characters'}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl sx={{ mb: 2 }} error={!!errors.confirmPassword}>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <Input 
                                        type="password"
                                        variant="outlined" 
                                        color={errors.confirmPassword ? "danger" : "primary"}
                                        fullWidth 
                                        value={confirmPassword}
                                        onChange={handleOnConfirmPasswordChange}
                                        disabled={!token}
                                        aria-invalid={!!errors.confirmPassword}
                                    />
                                    <FormHelperText>{errors.confirmPassword || ' '}</FormHelperText>
                                </FormControl>
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                                    <Button type="submit" disabled={!token}>Reset Password</Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}