import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        neutral : {
          50: '#FCFCF8',
          100: '#F8F6EF',
          200: '#F0EFE1',
          300: '#D3DFD1',
          400: '#F1D5B0',
          500: '#636B74',
          600: '#555E68',
          700: '#161125',
          800: '#171A1C',
          900: '#0B0D0E',
          /*Define where these high level variables are used*/
          outlinedBorder: 'var(--joy-palette-neutral-300)',
          outlinedColor:'var(--joy-palette-neutral-700)',
          outlinedDisabledBorder:'var(--joy-palette-neutral-200)',
          outlinedDisabledColor:'var(--joy-palette-neutral-400)',
          outlinedHoverBg:'var(--joy-palette-neutral-100)',
        },
        primary: {
          50: '#EFF8ED',
          100: '#D3DFD1',
          200: '#928AC2',
          300: '#D3DFD1',
          400: '#0E5536',
          500: '#0E5536',
          600: '#38483C',
          700: '#161125',
          800: '#161125',
          900: '#051423',
          /*Define where these high level variables are used*/
          outlinedBorder: 'var(--joy-palette-primary-200)',
          outlinedColor:'var(--joy-palette-primary-700)',
          outlinedDisabledBorder:'var(--joy-palette-neutral-200)',
          outlinedDisabledColor:'var(--joy-palette-neutral-400)',
          outlinedHoverBg:'var(--joy-palette-neutral-100)',
          outlinedActiveBg:'var(--joy-palette-neutral-200)',
          solidBg: 'var(--joy-palette-primary-600)',
          solidColor:'#FFFFFF',
          solidDisabledBorder:'var(--joy-palette-neutral-200)',
          solidDisabledColor:'var(--joy-palette-neutral-400)',
          solidHoverBg:'var(--joy-palette-primary-500)',
          softBg: 'var(--joy-palette-neutral-400)',
          softColor:'var(--joy-palette-primary-700)',
          softDisabledBorder:'var(--joy-palette-neutral-200)',
          softDisabledColor:'var(--joy-palette-neutral-400)',
          softHoverBg:'var(--joy-palette-neutral-300)',
          softActiveBg:'var(--joy-palette-neutral-200)',
          plainColor:'var(--joy-palette-primary-500)',
          plainDisabledColor:'var(--joy-palette-neutral-400)',
          plainHoverBg:'var(--joy-palette-neutral-100)',
          plainActiveBg:'var(--joy-palette-neutral-200)',
        },
        danger: {
          500: '#A80505',
        },
        background : {
          body:'var(--joy-palette-neutral-50)',
          surface: '#fff',
          level1: 'var(--joy-palette-neutral-50)',
          level2: 'var(--joy-palette-neutral-100)',
          level3: 'var(--joy-palette-neutral-200)',
        },
        text : {
          primary: 'var(--joy-palette-primary-700)',
          secondary: 'var(--joy-palette-primary-700)',
          tertiary: 'var(--joy-palette-primary-600)',
          icon: 'var(--joy-palette-primary-500)',
        }
      },
    },
  },
  typography: {
    'title-md':{
      fontWeight: 'var(--joy-fontWeight-xl, 700)'
    },
    'body-sm':{
      color: 'var(--joy-palette-primary-700)'
    },
    'component-md': {
      fontSize: '.875rem',
      fontWeight: '700',
      fontFamily: 'UbuntuMedium, var(--joy-fontFamily-fallback)'
    },
    'component-sm': {
      fontSize: '.875rem',
      fontWeight: '500',
      fontFamily: 'UbuntuRegular, var(--joy-fontFamily-fallback)'
    },
  },
  fontFamily: {
    display: 'Lora, var(--joy-fontFamily-fallback)', // applies to `h1`–`h4`
    body: 'Lora, var(--joy-fontFamily-fallback)', // applies to `title-*` and `body-*`
    components: 'UbuntuMedium, var(--joy-fontFamily-fallback)',
    componentslt: 'UbuntuRegular, var(--joy-fontFamily-fallback)',
  },
  components: {
    JoyBreadcrumbs: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-components)',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-components)',
        },
      },
    },
    JoyCheckbox: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-components)',
        },
      },
    },
    JoyChip: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-components)',
        },
      },
    },
    JoyMenuButton: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-components)',
        },
      },
    },
    JoyFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-components)',
        },
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--joy-fontFamily-componentslt)',
        },
      },
    },
    JoySheet: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--joy-palette-background-body)',
        },
      },
    },
    JoySkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    JoyTable: {
      styleOverrides: {
        root: {
          "--TableCell-headBackground": "transparent",
          "--TableCell-selectedBackground": (theme) =>
            theme.vars.palette.success.softBg,
        },
      },
    },
  },
});

export default theme;