export const PROMPT_SETTINGS = {
  length: {
    short: "short",
    medium: "medium",
    long: "long"
  },
  style: {
    direct: "direct",
    balanced: "balanced",
    instructive: "instructive"
  }
}

export const PROMPT_SETTINGS_DEFAULT = {
  length: PROMPT_SETTINGS.length.medium,
  style: PROMPT_SETTINGS.style.balanced
}