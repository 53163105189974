import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Alert,
  Button,
  Stack,
  Typography,
  Link,
  Chip,
} from '@mui/joy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Container from '../components/Container';
import DragAndDrop from '../components/files/DragAndDrop.js';
import FileTable from '../components/files/FileTable.js';
import TipPopUp from '../components/files/TipsPopUp.js';
import { TableLoadingSkeleton } from '../components/LoadingSkeletons';
import { NoFiles } from '../components/EmptyStates.js';
import CourseCheck from '../components/CourseCheck';
import TrialUploadCounter from '../components/files/TrialUploadCounter.js';
import { useSubscription } from '../context/SubscriptionContext';
import GlobalSnackbar from '../components/GlobalSnackbar';
import useFileUpdates, { FILE_EVENTS } from '../hooks/useFileUpdates.js';

const SlowProcessingAlert = ({ onClose }) => (
  <Alert
    variant="soft"
    color="neutral"
    startDecorator={<InfoOutlinedIcon />}
    endDecorator={
      <Button variant="plain" onClick={onClose}>
        Close Tip
      </Button>
    }
    sx={{ mb: 2 }}
    role="alert"
    aria-live="polite"
  >
    <strong>Tip:</strong> PDF and audio files may take longer to process (roughly 10-15 seconds per minute of audio or page of pdf).
  </Alert>
);

export default function Files({ headerRef }) {
  // State management
  const [showTips, setShowTips] = useState(false);
  const [files, setFiles] = useState([]);
  const [modules, setModules] = useState([]);
  const [processingFiles, setProcessingFiles] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [hasCourse, setHasCourse] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [courseDisabled, setCourseDisabled] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', color: 'success' });
  const [slowProcessingAlert, setSlowProcessingAlert] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [currentModuleId, setCurrentModuleId] = useState(null);

  // Hooks
  const { subscription } = useSubscription();
  const navigate = useNavigate();
  const { 
    subscribeFileUpdates, 
    unsubscribeFileUpdates
  } = useFileUpdates({ 
    setFiles, 
    onEvent: (notifyAdmin, data) => {
      console.log(`File Update ${data.type}: `, {notifyAdmin, data});
      if (data.type === FILE_EVENTS.COURSE_UPDATE || data.type === FILE_EVENTS.COURSE_DELETE) {
        headerRef.current.refreshCourseList();
      }
      
      if (data.type === FILE_EVENTS.ADD || data.type === FILE_EVENTS.UPDATE) {
        const file_id = data.file?.file_id || data.file_id; // Update event may not have a file object
        const status = data.updated_fields?.status || "";
        const newProcessingFiles = new Set(processingFiles);

        if (data.type === FILE_EVENTS.ADD || (data.type === FILE_EVENTS.UPDATE && status !== 'success' && !status.includes('Error'))) {
          // Add events fire when a file begins processing
          newProcessingFiles.add(file_id);
        }

        if (status === 'success' || status.includes('Error')) {
          newProcessingFiles.delete(file_id);
        }

        setProcessingFiles(newProcessingFiles);
      }

      if (notifyAdmin) {
        const messages = {
          [FILE_EVENTS.UPDATE]: "Course files have been updated by another admin",
          [FILE_EVENTS.ADD]: "New files have been uploaded by another admin",
          [FILE_EVENTS.DELETE]: "Course files have been deleted by another admin",
          [FILE_EVENTS.COURSE_UPDATE]: "Course information has been updated by another admin",
          [FILE_EVENTS.COURSE_DELETE]: "Course has been deleted by another admin",
        }
        setSnackbar({ open: true, message: messages[data.type], color: 'success' });
      }
    }
  });
  
  function isSlowFileProcessing() {
    const slowProcessingTypes = ['.pdf', '.mp3', '.mp4', '.wav', '.ogg', '.m4a'];
    const processingFileData = files.filter(file => processingFiles.has(file.file_id));
    return processingFileData.some(file => slowProcessingTypes.some(type => file.filetype.toLowerCase().endsWith(type)));
  }

  // Convert backend data to display format
  function convertToDisplay(data) {
    return data
      .filter(file => file.content_type !== 'homework')
      .map((item, index) => ({ ...item, id: index + 1 }));
  }

  // Fetch course status and files
  async function initializeData() {
    setIsLoading(true);
    try {
      // Check course settings and existence
      const settingsResponse = await axios.get(`/api/course_settings`);
      setHasCourse(true);
      // Handle course disabled status
      const disabled = !settingsResponse.data.active;
      const disableStart = settingsResponse.data.disable_start_date;
      const disableEnd = settingsResponse.data.disable_end_date;
      const isCurrentlyDisabled = disabled && (
        (!disableStart && !disableEnd) ||
        (Date.parse(disableStart) < Date.now() && Date.now() < Date.parse(disableEnd))
      );
      setCourseDisabled(isCurrentlyDisabled);
      
      // Fetch course files
      const filesResponse = await axios.get('/api/course_files_retrieve');
      let processedFiles = [];
      
      if (filesResponse.data) {
        processedFiles = convertToDisplay(filesResponse.data);
        setProcessingFiles(new Set(processedFiles.filter(file => 
          file.status !== 'success' && !file.status.includes('Error')
        ).map(file => file.file_id)));
        
        setShowTips(processedFiles.length === 0 || processedFiles.every(file => file.issyllabus));
      }
      
      // Fetch modules
      const modulesResponse = await axios.get('/api/modules');
      let modulesList = [];
      
      if (modulesResponse.data && modulesResponse.data.modules) {
        // Map modules to the format expected by the FileTable component
        modulesList = modulesResponse.data.modules.map(module => ({
          id: module.id,
          title: module.name,
          files: [] // Initialize empty files array
        }));
      }
      
      // Update state with processed data
      setFiles(processedFiles);
      setModules(modulesList);
      
    } catch (error) {
      console.error("Error initializing data:", error);
      setHasCourse(false);
      setCourseDisabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Set up course change handler
  if (headerRef.current) {
    headerRef.current.setOnCourseChange(() => {
      unsubscribeFileUpdates();
      initializeData();
      subscribeFileUpdates();
    });
  }

  useEffect(() => {
    setSlowProcessingAlert(isSlowFileProcessing());
  }, [processingFiles]);

  // Function to handle module navigation
  const handleModuleSelect = (moduleId, moduleName) => {
    setCurrentModule(moduleName);
    setCurrentModuleId(moduleId);
    // Navigate to the module view
    navigate(`/files?module=${encodeURIComponent(moduleName)}&moduleId=${moduleId}`, { replace: true });
  };

  // Function to return to all files view
  const handleReturnToAllFiles = () => {
    setCurrentModule(null);
    setCurrentModuleId(null);
    // Reset any filters and update URL
    navigate('/files', { replace: true });
  };

  // Initial setup
  useEffect(() => {
    document.title = "Files - All Day TA";

    // Check for subscription success message
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    const module = urlParams.get('module');
    const moduleId = urlParams.get('moduleId')

    if (success === 'true') {
      setSnackbar({ open: true, message: "Your subscription has been activated - Thank you for choosing All Day TA!", color: "success" });
    }

    // If module parameter exists in URL, set it as current module
    if (module && moduleId) {
      setCurrentModule(decodeURIComponent(module));
      setCurrentModuleId(moduleId);
    }
    
    initializeData(); // Fetch course status and files
    subscribeFileUpdates();

    // Cleanup on unmount
    return () => {
      unsubscribeFileUpdates();
    };
  }, []);

  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ flexWrap: 'wrap' }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems={courseDisabled ? "center" : "baseline"}
          spacing={2}
        >
          <Typography marginRight="1.5rem" level="h1">
            Files
          </Typography>
          {hasCourse && (
            <Stack direction="row" spacing={1}>
              {courseDisabled ?
                <Chip color='danger'>Course Disabled</Chip> :
                <Typography level="body-md">
                  Want to temporarily disable the student AI assistant?
                </Typography>
              }
              <Link
                href="/settings#ai-assistant"
                underline="always"
                onClick={(() => {
                  navigate("/settings");
                  setTimeout(() => {
                    const element = document.querySelector('[role="group"][aria-label="disable-url-form"]');
                    if (element) element.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                })}
              >
                {courseDisabled ? "Enable" : "Disable"} in Settings
              </Link>
            </Stack>
          )}
        </Stack>

        {hasCourse && !showTips && (
          <Button
            variant="plain"
            color="primary"
            size="md"
            endDecorator={<InfoOutlinedIcon />}
            onClick={() => setShowTips(true)}
          >
            Tips for Getting Started
          </Button>
        )}
      </Stack>

      <CourseCheck>
        {showTips && <TipPopUp onClose={() => setShowTips(false)} />}

        <DragAndDrop
          setFiles={setFiles}
          refresh={initializeData}
          disabled={isUploadDisabled}
          disabledMessage="File uploads are disabled. Please upgrade your subscription to continue uploading files."
          subscriptionTier={subscription?.tier_id}
          remainingUploads={10 - uploadCount}
          currentModuleId={currentModuleId}
        />

        {slowProcessingAlert && (
          <SlowProcessingAlert onClose={() => setSlowProcessingAlert(false)} />
        )}

        {isLoading ? (
          <TableLoadingSkeleton />
        ) : (
          <>
            <TrialUploadCounter
              files={files}
              onCountChange={({ count, isDisabled }) => {
                setUploadCount(count);
                setIsUploadDisabled(isDisabled);
              }}
            />

            {files.length > 0 ? (
              <FileTable
                files={files}
                modules={modules}
                refresh={initializeData}
                setFiles={setFiles}
                setModules={setModules}
                onModuleSelect={handleModuleSelect}
                onReturnToAllFiles={handleReturnToAllFiles}
              />
            ) : (
              <NoFiles />
            )}
          </>
        )}
      </CourseCheck>

      <GlobalSnackbar 
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </Container>
  );
}