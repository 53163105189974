import * as React from "react";
import { useState } from "react";
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  Checkbox,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/joy';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoTooltip from './InfoTooltip';

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'File Name',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'Active',
    sortable: false,
    info: 'Active files are used by All Day TA to answer student questions.',
  },
  {
    id: 'optional',
    numeric: false,
    disablePadding: false,
    label: 'Weight',
    sortable: false,
    info: 'Files marked as optional are downweighted by All Day TA when answering student questions.',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Added on',
  },
];

const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  page,
  rowsPerPage,
  selectableRowCount
}) => {
  const [openTooltip, setOpenTooltip] = useState(null);
  
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isAllSelected = numSelected === selectableRowCount && selectableRowCount > 0;
  const isPartiallySelected = numSelected > 0 && numSelected < selectableRowCount;

  const handleInfoClick = (event, headCell) => {
    event.stopPropagation();
    setOpenTooltip(headCell.id);
  };

  // Function to get the text alignment based on column index
  const getHeaderAlignment = (index) => {
    if (index === 0) return "left"; // Checkbox
    if (index === 1) return "left"; // Filename
    if (index === 2 || index === 3) return "center"; //Active, Weight)
    return "right"; // Added on
  };

  return (
    <thead>
      <tr>
        <th>
          <Checkbox
            color="primary"
            variant="outlined"
            indeterminate={isPartiallySelected}
            checked={isAllSelected}
            onChange={onSelectAllClick}
            slotProps={{
              input: {
                "aria-label": "Select all files and modules",
              },
            }}
          />
        </th>
        {headCells.map((headCell, index) => {
          const active = orderBy === headCell.id;
          const alignment = getHeaderAlignment(index + 1);
          return (
            <th
              key={headCell.id}
              aria-sort={
                active ? { asc: 'ascending', desc: 'descending' }[order] : undefined
              }
            > 
            <Stack direction="row" alignItems="center" spacing={1} justifyContent={alignment === 'left' ? 'flex-start' : alignment === 'center' ? 'center' : 'flex-end'}>
              {headCell.sortable !== false ? (
              <Link
                underline="none"
                color="primary"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(headCell.id)}
                fontWeight="lg"
                startDecorator={
                  headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                endDecorator={
                  !headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                sx={{
                  height:'32px',
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },
                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
              ) : (
                  headCell.label // If not sortable, just display the label
              )}
              {headCell.info && (
                <IconButton
                  size="sm"
                  variant="plain"
                  color="neutral"
                  aria-label={`Additional information about ${headCell.label}`}
                  onClick={(event) => handleInfoClick(event, headCell)}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
            {headCell.info && (
              <InfoTooltip
                info={headCell.info}
                open={openTooltip === headCell.id}
                onClose={() => setOpenTooltip(null)}
              />
            )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default EnhancedTableHead;