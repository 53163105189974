import React from 'react';
import {
Alert 
} from '@mui/joy';

const FormErrorAlert = ({ 
  isSubmitted, 
  errors,
  errorMessages = {},
  className = ''
}) => {
  //console.log('FormErrorAlert props:', { isSubmitted, errors, errorMessages });
  if (!isSubmitted || !Object.values(errors).some(Boolean)) {
    return null;
  }

  const errorCount = Object.values(errors).filter(Boolean).length;
  const errorText = Object.entries(errors)
    .filter(([_, hasError]) => hasError)
    .map(([field]) => errorMessages[field] || `${field} is required`)
    .join(' ');

  return (
    <Alert
      role="alert"
      aria-live="assertive"
      color="danger"
      className={className}
      sx={{ mt: 1, mb: 1 }}
    >
      {`Form has ${errorCount} ${errorCount === 1 ? 'error' : 'errors'}. ${errorText}`}
    </Alert>
  );
};

export default FormErrorAlert;