import React from 'react';
import { Autocomplete, AutocompleteOption, Typography, Box } from '@mui/joy';

export default function CourseSelector({
  selectedCourse,
  setSelectedCourse,
  courses
}) {
  return (
    <Autocomplete
      value={selectedCourse || null}
      onChange={(_, newValue) => setSelectedCourse(newValue || null)}
      options={courses}
      getOptionLabel={(option) => option.name}
      placeholder="Search for a course"
      variant="outlined"
      sx={{
        minWidth: '100%'
      }}
      renderOption={(props, option) => {
        const { key, ...restProps } = props; // Extract and exclude the key from props
        return (
          <AutocompleteOption key={option.id} {...restProps}>
            <Box style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <Typography
                level="body-md"
                sx={{
                  fontWeight: 500,
                  flex: 1
                }}
              >
                {option.name}
              </Typography>
              <Typography
                level="body-sm"
                sx={{
                  color: 'neutral.500',
                  ml: 2,
                  fontSize: '0.85rem',
                  flexShrink: 0
                }}
              >
                ID: {option.id} • Org: {option.org_id}
              </Typography>
            </Box>
          </AutocompleteOption>
        );
      }}
    />
  );
}