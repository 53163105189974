import axios from 'axios';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  Box,
  Option,
  Select,
  Stack,
} from '@mui/joy';
import {getAssociatedCoursesListForAdmin} from '../CourseRetrieveAPI';
import './Header.css';

const ClassSelector = forwardRef((props, ref) => {
    const { onCourseChange, chatbotRef, setFullUrl } = props;
    const [courseList, setCourseList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null); // Initialize to null
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchClasses();
    }, []);

    const fetchClasses = async () => {
        setLoading(true);
        try {
            const data = await getAssociatedCoursesListForAdmin();
            setCourseList(data.sort((a, b) => a.course_name.localeCompare(b.course_name)));

            // Fetch the last selected course from the session
            const response = await axios.get('/api/check-auth', { withCredentials: true });
            const lastCoursePresent = response.data.last_course_present;


            // Log the last course present to debug
            //console.log('Last course present from session:', lastCoursePresent);

            if (lastCoursePresent) {
                const selectedCourse = data.find(course => course.course_id === Number(lastCoursePresent));
                if (selectedCourse) {
                  // if the selected course is all ready the last course present, then set the course url but don't call onCourseChange to avoid duplicate api calls.
                  setSelectedCourse(selectedCourse.course_id);
                  setFullUrl(selectedCourse.course_url);
                } else {
                  await setDefaultCourse(data);
                }
              } else {
                await setDefaultCourse(data);
              }
        } catch (error) {
            //console.error('Error fetching course list:', error);
        } finally {
            setLoading(false);
        }
    };

    const setDefaultCourse = async (data) => {
        if (data.length > 0) {
          setSelectedCourse(data[0].course_id);
          await handleCourseChange(null, data[0].course_id, true);
        }
      };

      useImperativeHandle(ref, () => ({
        refreshCourseList: fetchClasses,
        setSelectedCourseId: async (courseId) => {
          setSelectedCourse(courseId);
          await handleCourseChange(null, courseId, true);
        }
      }));
    


    const handleCourseChange = async (event, newValue, isInitial = false) => {
        setSelectedCourse(newValue);
        const selectedCourseData = courseList.find(course => course.course_id === newValue);
        try {
          await axios.post('/api/change_course_sessions', { newSelectedCourse: newValue });
          if (onCourseChange) {
            onCourseChange(newValue, selectedCourseData?.course_url || '');
          }
          if (chatbotRef && chatbotRef.current) {
            chatbotRef.current.resetChatbot(newValue);
          }
          // Dispatch event for subscription update
          window.dispatchEvent(new Event('courseChanged'));
        } catch (error) {
          //console.error('Error changing course:', error);
        }
      };

    if (loading) {
        return <Box sx={{ height: '44px', display: 'flex', alignItems: 'center', marginLeft: '1.125rem' }}>Fetching Courses...</Box>;
    }

    if (courseList.length === 0) {
        return (
            <Stack direction="row" alignItems="center" gap={2}>
            </Stack>
        );
    }

    return (
        <Select 
            value={selectedCourse} 
            onChange={(event, newValue) => handleCourseChange(event, newValue)} 
            variant="outlined" 
            size="lg" 
            color="primary"
            aria-label="Selected Course"
            className="class-selection"
            sx={{ 
                maxWidth:412,
                '& .MuiSelect-select': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }
            }}
            slotProps={{
              listbox: {
                placement: 'bottom-start',
                sx: { minWidth: 345 },
              },
            }}
        >   
          {courseList.map((course) => (
              <Option key={course.course_id} value={course.course_id}>
                  {course.course_name}
              </Option>
          ))}
        </Select>
    );
});

export default ClassSelector;