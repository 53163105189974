import React from 'react';
import { Button } from '@mui/joy';

export default function DownloadResultsCSV({ results }) {
  const exportToCSV = () => {
    if (!results || results.length === 0) {
      alert('No results to export');
      return;
    }

    // Create CSV header
    const headers = [
      'Question',
      'Answer 1',
      'Total Time 1 (ms)',
      'Tokens Used (Input + Completion) 1',
      'Answer 2',
      'Total Time 2 (ms)',
      'Tokens Used (Input + Completion) 2',
      'Language 1',
      'Syllabus 1',
      'Language 2',
      'Syllabus 2'
    ];

    // Create CSV rows
    const csvRows = results.map(questionResult => {
      const row = {
        question: questionResult.question,
      };

      // Extract data from each workflow
      questionResult.workflows.forEach((workflow, index) => {
        const workflowNum = index + 1;
        
        // Extract answer text, removing any markdown or special formatting
        const answer = workflow.response?.output?.answer || '';
        
        // Calculate total time across all stages
        let totalTime = 0;
        totalTime += workflow.preprocess?.metrics?.execution_time || 0;
        totalTime += workflow.retrieval?.metrics?.execution_time || 0;
        totalTime += workflow.response?.metrics?.execution_time || 0;
        
        // Add times from nested homework revision queries if they exist
        totalTime += workflow.response?.metrics?.initial_query?.execution_time || 0;
        totalTime += workflow.response?.metrics?.revision_query?.execution_time || 0;
        
        // Calculate total tokens across all stages and queries
        let inputTokens = 0;
        let completionTokens = 0;
        
        // Add tokens from main stages
        inputTokens += workflow.preprocess?.metrics?.prompt_tokens || 0;
        completionTokens += workflow.preprocess?.metrics?.completion_tokens || 0;
        inputTokens += workflow.retrieval?.metrics?.prompt_tokens || 0;
        completionTokens += workflow.retrieval?.metrics?.completion_tokens || 0;
        inputTokens += workflow.response?.metrics?.prompt_tokens || 0;
        completionTokens += workflow.response?.metrics?.completion_tokens || 0;
        
        // Add tokens from nested homework revision queries if they exist
        inputTokens += workflow.response?.metrics?.initial_query?.prompt_tokens || 0;
        completionTokens += workflow.response?.metrics?.initial_query?.completion_tokens || 0;
        inputTokens += workflow.response?.metrics?.revision_query?.prompt_tokens || 0;
        completionTokens += workflow.response?.metrics?.revision_query?.completion_tokens || 0;
        
        const tokensUsed = inputTokens + completionTokens;
        
        // Extract language and syllabus from config if available
        const language = workflow.preprocess?.output?.language || '';
        const syllabus = workflow.preprocess?.output?.is_syllabus;
        
        // Add workflow data to row
        row[`answer${workflowNum}`] = answer;
        row[`totalTime${workflowNum}`] = totalTime;
        row[`tokensUsed${workflowNum}`] = tokensUsed;
        row[`language${workflowNum}`] = language;
        row[`syllabus${workflowNum}`] = syllabus;
      });

      return [
        row.question,
        row.answer1 || '',
        row.totalTime1 || '',
        row.tokensUsed1 || '',
        row.answer2 || '',
        row.totalTime2 || '',
        row.tokensUsed2 || '',
        row.language1 || '',
        row.syllabus1 || '',
        row.language2 || '',
        row.syllabus2 || ''
      ];
    });

    // Combine header and rows
    const csvContent = [
      headers.join(','),
      ...csvRows.map(row => 
        row.map(cell => {
          // Escape quotes and wrap in quotes if needed
          const cellStr = String(cell);
          return cellStr.includes(',') || cellStr.includes('"') || cellStr.includes('\n')
            ? `"${cellStr.replace(/"/g, '""')}"`
            : cellStr;
        }).join(',')
      )
    ].join('\n');

    // Create and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `workflow_results_${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Only render the button if there are results
  if (!results || results.length === 0) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      color="neutral"
      onClick={exportToCSV}
      sx={{ ml: 'auto' }}
    >
      Save as CSV
    </Button>
  );
};
