import { useState } from 'react';
import {
  Dropdown,
  Menu,
  MenuButton,
  MenuItem
} from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { 
  // STATUS, 
  // isProcessingStatus, 
  // isSuccessStatus, 
  hasError, 
  // canPerformActions 
} from './fileStatusUtils';
import CreateModuleModal from './CreateModuleModal';
import MoveFilesToModuleModal from './MoveFilesToModuleModal';

export default function ModulesMenu({ selected, modules, setModules, setFiles }) {
  const [openCreateModule, setOpenCreateModule] = useState(false);
  const [openMoveFilesToModule, setOpenMoveFilesToModule] = useState(false);

  const selectedFiles = Array.isArray(selected) 
    ? selected.filter(item => !item.isModule) 
    : [];

  // Check if any of the selected files has an error status
  const hasAnyError = selectedFiles.some(file => hasError(file.status));

  // Check if actions should be disabled
  const shouldDisableActions = hasAnyError || selectedFiles.length === 0;

  return (
    <>
      <Dropdown>
        <MenuButton
          variant="outlined"
          color="primary"
          endDecorator={<ArrowDropDownIcon />}
        >
          Modules
        </MenuButton>
        <Menu placement="bottom-start">
          <MenuItem 
            onClick={() => setOpenCreateModule(true)}
          >
            Create Module
          </MenuItem>
          <MenuItem 
            onClick={() => setOpenMoveFilesToModule(true)}
            disabled={shouldDisableActions}
          >
            Move Files to Module
          </MenuItem>
        </Menu>
      </Dropdown>
      <CreateModuleModal open={openCreateModule} onClose={() => setOpenCreateModule(false)} existingModules={modules} setModules={setModules} />
      <MoveFilesToModuleModal open={openMoveFilesToModule} onClose={() => setOpenMoveFilesToModule(false)} modules={modules} selected={selectedFiles} setFiles={setFiles} />
    </>
  );
};