import React from 'react';
import {  
  Breadcrumbs, 
  Link, 
  Stack, 
  Typography 
} from '@mui/joy';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import FolderIcon from '@mui/icons-material/FolderOutlined';

/*Enhanced Table Toolbar with breadcrumbs navigation
 * totalItems Total number of items (files + modules)
 * totalModules Total number of modules in the current view
 * totalFiles Total number of files in the current view
 * rowsPerPage Number of rows per page
 * page Current page
 * statusCounts Object containing counts of files in various statuses
 * currentView Current view identifier ('all' or module ID)
 * onBackToAllFiles Function to handle navigation back to all files
 * modules Array of module objects
*/
const EnhancedTableToolbar = (props) => {
  const { 
    totalFiles, 
    totalModules, 
    rowsPerPage, 
    page, 
    statusCounts, 
    currentView, 
    onBackToAllFiles, 
    modules = [] 
  } = props;
  
  const pageRowCount = Math.min(rowsPerPage, totalFiles - page * rowsPerPage);
  const activeFiles = totalFiles - (statusCounts.loading + statusCounts.processing + statusCounts.updating + statusCounts.replacing);
  const hasNonActiveFiles = activeFiles < totalFiles;

  // Find the current module name if viewing a specific module
  const currentModule = Array.isArray(modules) ? 
    modules.find(m => String(m.id) === String(currentView)) : null;
  const currentModuleName = currentModule ? currentModule.title : '';

  // Get status counts string
  const getStatusString = () => {
    const statuses = [];
    if (statusCounts.loading > 0) statuses.push(`${statusCounts.loading} Loading`);
    if (statusCounts.processing > 0) statuses.push(`${statusCounts.processing} Processing`);
    if (statusCounts.updating > 0) statuses.push(`${statusCounts.updating} Updating`);
    if (statusCounts.replacing > 0) statuses.push(`${statusCounts.replacing} Replacing`);
    
    return statuses.join(', ');
  };

  // Get the summary text
  const getSummaryText = () => {
    const countText = `${pageRowCount} of ${totalFiles} ${totalFiles === 1 ? 'file' : 'files'}`;
    const statusText = hasNonActiveFiles ? ` (${getStatusString()})` : '';
    
    return `${countText}${statusText}`;
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2} mb={2}>
      <Breadcrumbs
        sx={{
          paddingLeft: 0
        }}
        slotProps={{
          separator: {
            sx: { mx: 1 }
          }
        }}
      >
        <Link
          component="button"
          underline="hover"
          onClick={() => {
            if (currentView !== 'all') {
              onBackToAllFiles();
            }
          }}
          startDecorator={currentView !== 'all' ? <ArrowBackwardIcon fontSize="xl"/> : null}
          color={currentView === 'all' ? 'primary' : null }
        >
          All Files
        </Link>
        
        {currentView !== 'all' && currentModuleName && (
          <Typography 
            startDecorator={<FolderIcon fontSize="xl"/>}
            color="primary"
          >
            {currentModuleName}
          </Typography>
        )}
      </Breadcrumbs>
      
      <Typography level="body-md">
        Viewing&nbsp;
        {currentView === 'all' && totalModules > 0 ? 
          `${totalModules} ${totalModules === 1 ? 'module' : 'modules'}, ` : ''}
        {getSummaryText()}
      </Typography>
    </Stack>
  );
};

export default EnhancedTableToolbar;