import * as React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

const Pagination = ({ 
  rowsPerPage, 
  page, 
  totalItems, 
  onChangeRowsPerPage, 
  onChangePage 
}) => {
  const handleChangeRowsPerPage = (event, newValue) => {
    if (newValue) {
      // This is how the Select component in Joy UI calls it
      onChangeRowsPerPage(newValue);
    } else {
      // Fallback for direct event handling
      onChangeRowsPerPage(parseInt(event.target.value, 10));
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <FormControl orientation="horizontal" size="md">
        <FormLabel>Rows/Page:</FormLabel>
        <Select onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage}>
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
          <Option value={50}>50</Option>
        </Select>
      </FormControl>
      <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium'}}>
        {labelDisplayedRows({
          from: totalItems === 0 ? 0 : page * rowsPerPage + 1,
          to: Math.min((page + 1) * rowsPerPage, totalItems),
          count: totalItems,
        })}
      </Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton
          size="sm"
          color="primary"
          variant="outlined"
          aria-label="Previous Page"
          disabled={page === 0}
          onClick={() => onChangePage(page - 1)}
          sx={{ bgcolor: "background.surface" }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          size="sm"
          color="primary"
          variant="outlined"
          aria-label="Next Page"
          disabled={
            totalItems !== -1
              ? page >= Math.ceil(totalItems / rowsPerPage) - 1
              : false
          }
          onClick={() => onChangePage(page + 1)}
          sx={{ bgcolor: "background.surface" }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default Pagination;