import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Snackbar,
  Stack,
  Textarea,
  Typography,
  FormHelperText
} from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormErrorAlert from '../form-elements/FormErrorAlert';
import '../Modals.css';

export default function CreateNoteModal({ open, onClose, refresh, edit = false, file_id = null, note_template = null, question_id = null, currentModuleId = null }) {
  const [title, setTitle] = useState('');
  const [noteText, setNoteText] = useState('');
  const [errorType, setErrorType] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'primary',
  });
  const [loading, setLoading] = useState(false);
  const snackbarRef = useRef(null);

  useEffect(() => {
    if (open) {
      if (edit && file_id) {
        setLoading(true);
        axios.get(`/api/get_note_text/${file_id}`)
        .then(response => {
          setTitle(response.data.title);
          setNoteText(response.data.text);
        })
        .catch(error => {
        //   console.error('Error fetching note:', error);
          setTitle('');
          setNoteText('');
          setSnackbar({
            open: true,
            message: 'Failed to load note',
            color: 'danger',
          });
        })
        .finally(() => {
          setLoading(false);
        });
      } else if (note_template && note_template.title && note_template.text) {
        setTitle(note_template.title);
        setNoteText(note_template.text);
      } else {
        setTitle('');
        setNoteText('');
      }
    }
  }, [open, edit, file_id]);

  const handleSave = async () => {
    setIsSubmitted(true);
    if (!title.trim()) {
      setErrorType('title');
      setErrorMessage('Title is required');
      return;
    }

    if (!noteText.trim()) {
      setErrorType('noteText');
      setErrorMessage('Note text is required');
      return;
    }
    
    setErrorType(null);
    setErrorMessage('');

    try {
      let uploadResponse;
      console.log(`${edit ? 'Updating' : 'Creating'} note with module_id:`, currentModuleId);
      if (edit) {
        uploadResponse = await axios.post(`/api/replace_note/${file_id}`, {
          new_title: title,
          new_text: noteText,
          question_id: question_id,
          module_id: currentModuleId
        }, {
          headers: { 'Content-Type': 'application/json' }
        });
      } else {
        uploadResponse = await axios.post('/api/upload_note', {
            title: title,
            text: noteText,
            question_id: question_id,
            module_id: currentModuleId
          }, {
            headers: { 'Content-Type': 'application/json' }
          });
      }


      if (uploadResponse.status === 200) {
        if (question_id) {
          refresh(uploadResponse.data?.result?.file_id || file_id);
        } else {
          refresh();
        }
        handleClose();
      } else {
        setSnackbar({
        open: true,
        message: 'Failed to save note',
        color: 'danger',
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to save note',
        color: 'danger',
      });
    }
  };

  const handleClose = () => {
    setTitle('');
    setNoteText('');
    setErrorType(null);
    setErrorMessage('');
    onClose();
  };

  const moduleInfo = currentModuleId ? (
    <Typography>
      This note will be {edit ? 'kept in' : 'added to'} the current module
    </Typography>
  ) : null;

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog
          layout="fullscreen"
          variant="outlined"
          size="lg"
          className="modal-container"
          data-testid="note-dialog"
        >
          <DialogTitle className="modal-name">{edit ? 'Edit' : 'Create'} Note</DialogTitle>
          {moduleInfo}
          {!edit ?
            <Typography>Enhance your AI Assistant's knowledge or provide more suitable answers to specific student questions by adding additional information in this text area. Simply type your content directly or copy/paste content from documents or emails. Note: This content won't override any incorrect content in your uploaded files—you should still maintain a properly curated file collection.</Typography>
            : null
          }
          <FormErrorAlert 
              isSubmitted={isSubmitted}
              errors={{
                  errorType: !!errorMessage
              }}
              errorMessages={{
                  errorType: errorMessage
              }}
          />
          <ModalClose
            aria-label="Close Dialog"
           />
          <DialogContent>
            <Grid xs={12}>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <Stack 
                  direction="column"
                  spacing={3} 
                >
                  <FormControl error={errorType === 'title'}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      variant="outlined"
                      color="primary"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ width: "100%" }}
                      aria-invalid={!!errorType}
                      data-testid="note-title-input"
                    />
                    {errorType === 'title' && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl error={errorType === 'noteText'}>
                    <FormLabel>Note Text</FormLabel>
                    <Textarea
                      variant="outlined"
                      color="primary"
                      value={noteText}
                      onChange={(e) => setNoteText(e.target.value)}
                      minRows={12}
                      sx={{ maxHeight:'100vh'}}
                      aria-invalid={!!errorType}
                      data-testid="note-text-input"
                    />
                    {errorType === 'noteText' && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              )}
            </Grid>
            </DialogContent>
            <DialogActions className="dialog-actions-right">
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="solid" onClick={handleSave} disabled={loading}>
                  Save
                </Button>
            </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        open={snackbar.open}
        color={snackbar.color}
        size="md"
        variant="soft"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        startDecorator={snackbar.color === 'success' ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
        ref={snackbarRef}
        tabIndex={-1}
        role="status"
        aria-live="polite"
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        {snackbar.message}
      </Snackbar>
    </React.Fragment>
  );
}
