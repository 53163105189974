import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, 
  Checkbox,
  Typography 
} from '@mui/joy';
import GlobalSnackbar from '../GlobalSnackbar';

const EmailSettingsCheckbox = ({ isLoading }) => {
  const [receiveEmails, setReceiveEmails] = useState(true);
  const [updateError, setUpdateError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'success'
  });

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const fetchEmailSettings = async () => {
    try {
      const response = await axios.get('/api/course_settings');
      setReceiveEmails(response.data.receive_emails);
    } catch (error) {
      console.error('Error fetching email settings:', error);
      setUpdateError('Failed to load email preferences');
    }
  };

  // Fetch on mount and when isLoading changes (which happens on course change)
  useEffect(() => {
    if (!isLoading) {
      fetchEmailSettings();
    }
  }, [isLoading]);

  const handleEmailPreferenceChange = async (event) => {
    const newValue = event.target.checked;
    try {
      const currentCourse = await axios.get('/api/course_settings');
      const courseId = currentCourse.data.course_id;
      
      await axios.post('/api/update_course_settings', {
        course_id: courseId,
        receive_emails: newValue
      });
      setReceiveEmails(newValue);
      setUpdateError(null);
      setSnackbar({
        open: true,
        message: `Weekly summary emails ${newValue ? 'enabled' : 'disabled'} successfully`,
        color: 'success'
      });
    } catch (error) {
      console.error('Error updating email settings:', error);
      setUpdateError('Failed to update email preferences');
      // Revert the checkbox state on error
      setReceiveEmails(!newValue);
      setSnackbar({
        open: true,
        message: 'Failed to update email preferences',
        color: 'danger'
      });
    }
  };

  return (
    <Box>
      <Checkbox
        checked={receiveEmails}
        onChange={handleEmailPreferenceChange}
        color={updateError ? "danger" : "primary"}
        size="md"
        label="Receive Weekly Summary Emails"
        disabled={isLoading}
        data-testid="email-preference-checkbox"
      />
      {updateError && (
        <Typography level="body-sm" color="danger" mt={1}>
          {updateError}
        </Typography>
      )}
      <GlobalSnackbar
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        color={snackbar.color}
      />
    </Box>
  );
};

export default EmailSettingsCheckbox;