// Centralized file status constants and helper functions

// Status constants
export const STATUS = {
  SUCCESS: "success",               // File is ready for use
  PROCESSING: "Processing",         // General processing state
  ENHANCING: "Enhancing existing data quality",           // Initial version is available, second pass is running to improve data
  UPLOADING: "Uploading",           // File is being uploaded (keep if needed)
  UPDATING: "Updating",          // Any modification in progress
  DELETING: "Deleting"           // Keep separate for deletion feedback
};

//Checks if a file status indicates it's currently being processed
export const isProcessingStatus = (status) => {
  return status && (
    status === STATUS.PROCESSING ||
    status === STATUS.ENHANCING ||
    status === STATUS.UPLOADING ||
    status === STATUS.UPDATING ||
    status === STATUS.DELETING ||
    status.includes("...")  // Catch any legacy statuses with ellipses
  );
};

//Checks if a file status indicates successful processing
export const isSuccessStatus = (status) => {
  return status === STATUS.SUCCESS;
};

//Checks if a file status indicates an error
export const hasError = (status) => {
  return status?.includes("Error");
};

//Checks if a file can have actions performed on it
export const canPerformActions = (file) => {
  return isSuccessStatus(file.status) && !hasError(file.status);
};

//Checks if a file is in a state where it can be deleted
export const canDelete = (file) => {
  return isSuccessStatus(file.status) || hasError(file.status);
};

//Checks if a file can be selected in the UI
export const isRowSelectable = (file) => {
  if (isProcessingStatus(file.status)) {
    return false;
  }
  
  return isSuccessStatus(file.status) || hasError(file.status);
};