import { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, Button, Textarea, FormControl, FormLabel, FormHelperText, Alert } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormErrorAlert from './form-elements/FormErrorAlert';

export default function HomeworkForm({ courseId }) {
  const [homeworkText, setHomeworkText] = useState('');
  const [orgId, setOrgId] = useState(null);
  const [homeworkFileId, setHomeworkFileId] = useState(null);
  const [submissionAlert, setSubmissionAlert] = useState({
    show: false,
    message: '',
    color: 'primary',
  });
  const [formErrorText, setFormErrorText] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  //const MAX_TEXT_LENGTH = 2500;

  useEffect(() => {
    document.title = "Current Homework - All Day TA";

    const fetchNotes = async () => {
      setFormErrorText('');
      setSubmissionAlert({ show: false });
      let homeworkFileId = null;
      try {
        const response = await axios.get('/api/course_files_retrieve');
        if (response.status === 200) {
          const homeworkFile = response.data.filter(file => file.content_type === 'homework')[0];
          homeworkFileId = homeworkFile?.file_id;
          setHomeworkFileId(homeworkFileId);
          setOrgId(homeworkFile?.org_id);
        }
        else {
          throw response;
        }
      } catch (error) {
        console.error('Error fetching homework:', error);
        setSubmissionAlert({
          show: true,
          message: error.response.data.message || error.message,
          color: 'danger',
        });
      }

      if (homeworkFileId) {
        try {
          const response = await axios.get(`/api/get_note_text/${homeworkFileId}`);
          if (response.status === 200) {
            setHomeworkText(response.data.text);
          }
          else {
            throw response;
          }
        } catch (error) {
          console.error('Error fetching homework text:', error);
          setSubmissionAlert({
            show: true,
            message: error.response.data.message || error.message,
            color: 'danger',
          });
        }
      } else {
        setHomeworkText('');
      }
    };
    fetchNotes();
  }, [courseId]);


  const handleTextChange = (e) => {
    setHomeworkText(e.target.value);
    setFormErrorText('');
    setSubmissionAlert({ show: false });
    /*if (e.target.value.length <= MAX_TEXT_LENGTH) {
      setHomeworkText(e.target.value);
      setFormErrorText('');
    } else {
      setHomeworkText(e.target.value.slice(0, MAX_TEXT_LENGTH));
      setFormErrorText(`Homework text cannot exceed ${MAX_TEXT_LENGTH} characters. Text has been truncated.`);
    }
    setSubmissionAlert({ show: false });*/
  };

  const handleSave = async () => {
    setIsSubmitted(true);
    // basic validation
    /*if (homeworkText.length > MAX_TEXT_LENGTH) {
      setFormErrorText(`Homework text cannot exceed ${MAX_TEXT_LENGTH} characters.`);
      return;
    }
    setFormErrorText('');*/

    try {
      let response;
      if (homeworkFileId) {
        if (homeworkText.trim()) {
          response = await axios.post(`/api/replace_note/${homeworkFileId}`, {
            new_title: 'Current Homework',
            new_text: homeworkText,
            content_type: 'homework',
          });
          setHomeworkFileId(prev => response.data?.result?.file_id || prev);
        } else {
          response = await axios.delete('/api/delete_files', { data: { files: [{ 
              org_id: orgId,
              course_id: courseId,
              file_id: homeworkFileId
            }]
          }})
          setHomeworkFileId(null);
        }
      } else {
        if (!homeworkText.trim()) {
          setFormErrorText(`Homework text is required.`);
          return;
        }
        response = await axios.post('/api/upload_note', {
          title: 'Current Homework',
          text: homeworkText,
          content_type: 'homework',
        });
        setHomeworkFileId(response.data.result.file_id);
      }

      if (response.status === 200) {
        setSubmissionAlert({
          show: true,
          message: 'Homework saved successfully!',
          color: 'success',
        });
      }
    } catch (error) {
      console.error('Error saving homework:', error);
      setSubmissionAlert({
        show: true,
        message: error.response.data.message || error.message,
        color: 'danger',
      });
    }
  };

  return (
    <Stack direction="column" spacing={3}>
      <FormErrorAlert 
        isSubmitted={isSubmitted}
        errors={{
          homework: !!formErrorText
        }}
        errorMessages={{
          homework: formErrorText
        }}
      />
      <FormControl error={!!formErrorText}>
        <FormLabel>Current Homework</FormLabel>
        <Textarea
          placeholder="Enter your homework here..."
          color={formErrorText ? "danger" : "primary"}
          value={homeworkText}
          onChange={handleTextChange}
          minRows={15}
          maxRows={15}
          aria-invalid={!!formErrorText}
        />
        <FormHelperText>
          {formErrorText}
        </FormHelperText>
      </FormControl>
      {submissionAlert.show && (
        <Alert
          color={submissionAlert.color}
          size="md"
          variant="soft"
          startDecorator={submissionAlert.color === 'success' ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
          role="alert"
          aria-live="polite"
          endDecorator={
            <Button 
              variant="soft" 
              color={submissionAlert.color}
              onClick={() => setSubmissionAlert({ show: false })}
            >
              Dismiss
            </Button>
          }
        >
          {submissionAlert.message}
        </Alert>
      )}
      <Button
        variant="solid"
        color="primary"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSave}
      >
        Save
      </Button>
    </Stack>
  );
}