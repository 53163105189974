// this file is for editing a course
// Note: Safari requires special date handling due to its strict date parsing.
// The date utilities and validation in this component have been specifically
// designed to work across all browsers including Safari's stricter requirements.
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DialogTitle, 
  DialogContent, 
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  Modal, 
  ModalDialog, 
  ModalClose, 
  Snackbar,
  Stack,
  Textarea,
  Divider,
  Typography,
} from "@mui/joy";
import Container from "../components/Container";
import EditSyllabusModal from "../components/EditSyllabusModal";
import LoadingSkeleton from "../components/LoadingSkeletons";
import CourseInfoFields from '../components/form-elements/CourseInfoFields';
import CourseUrlField from '../components/form-elements/CourseUrlField';
import FormErrorAlert from '../components/form-elements/FormErrorAlert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CourseCheck from '../components/CourseCheck';

export default function CourseInfo({ headerRef }) {
  //console.log("CourseInfo component mounted");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [org_Id, setOrgId] = useState(null);
  const [course_Id, setCourseId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [modules, setModules] = useState([""]);
  const [courseName, setCourseName] = useState("");
  const [description, setDescription] = useState('');
  const [professors, setProfessors] = useState('');
  const [teachingAssistants, setTeachingAssistants] = useState("");
  const [termName, setTermName] = useState('');
  // Used for course URL
  const [courseUrl, setCourseUrl] = useState('');
  const [fullCourseUrl, setFullCourseUrl] = useState('');
  const [urlChanged, setUrlChanged] = useState(false);
  const [urlError, setUrlError] = useState("");
  //Alerts and errors
  const [statusMessage, setStatusMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
  });
  // Updating syllabus
  const [isUpdateSyllabusOpen, setIsUpdateSyllabusOpen] = useState(false);

  useEffect(() => {
      document.title = "Edit Course Information - All Day TA";
  }, []);

  const retrieveCourseInfo = useCallback(async () => {
    //console.log("retrieveCourseInfo called");
    setIsLoading(true);
    try {
      const response = await axios.get("/api/course");
      //console.log("Response received from /api/course", response);
      if (response.data && response.data.length > 0) {
        //console.log("Response data:", response.data);
        const courseData = response.data[0];
        //console.log("Course data received:", courseData);

        setOrgId(courseData.org_id);
        setCourseId(courseData.course_id);
        setCourseName(courseData.course_name);
        setProfessors(courseData.professor_names.toString().replaceAll(",", ", "));
        setModules(courseData.topics);
        setTermName(courseData.term);
        setDescription(courseData.description);
        setTeachingAssistants(courseData.ta_names.toString().replaceAll(",", ", "));
        setCourseUrl(courseData.course_url.split('/').pop());
        //console.log("Original course URL set:", courseUrl);

      } else {
        //console.log("Response data is empty");
      }
    } catch (error) {
      //console.error("Error fetching course info:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
       //console.log("useEffect called");
    retrieveCourseInfo();
    if (headerRef.current) {
        headerRef.current.setOnCourseChange(retrieveCourseInfo);
    }
  }, [headerRef, retrieveCourseInfo]);

const handleSave = async () => {
  //console.log("Saving the updated course information...");
  setIsSubmitted(true);

  const newErrors = {
    courseName: !courseName.trim(),
    professors: !professors.trim(),
  };

  setErrors(newErrors);

  if (newErrors.courseName || newErrors.professors) {
    return;
  }

  const trimmedDescription = description.trim();

  const courseData = {
    courseName,
    trimmedDescription,
    professors,
    teachingAssistants,
    modules,
    termName,
    courseUrl: urlChanged ? fullCourseUrl.split('/').pop() : undefined,
    urlChanged
  };

  //console.log("courseData prepared:", courseData);

  try {
    //console.log("Sending POST request to /api/course_info");
    const response = await axios.patch("/api/course_info", courseData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    //console.log("Response received:", response);

    if (response.status === 200) {
      const courseId = response.data.course.course_id;

      navigate("/files");
      setStatusMessage("Course data updated successfully");
      //console.log("Server response:", response);
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error.includes("URL already exists")) {
      setUrlError("This URL already exists. Please choose a different one.");
      if (error.response.data.suggested_url) {
          setCourseUrl(error.response.data.suggested_url.split('/').pop());
        }
    } else {
      setStatusMessage("Error saving course data: " + (error.response?.data?.message || error.message));
    }
  }
};

const handleDeleteCourse = async () => {
  try {
    //console.log(`Attempting to delete course: /api/delete/course/${org_Id}/${course_Id}`);
    const response = await axios.delete(`/api/delete/course/${org_Id}/${course_Id}`);
    if (response.status === 200) {
      setStatusMessage("Course deleted successfully");
      navigate("/files");
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    } else {
      setStatusMessage("Error deleting course: " + response.statusText);
    }
  } catch (error) {
    setStatusMessage("Error deleting course: " + error.response.data.message);
    //console.error("Error details:", error.response.data);
  }
  setIsDeleteDialogOpen(false);
};

return (
  <Container>
    {isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography level="h1" id="edit-course-form" mb={2}>Edit Course Information</Typography>
          <FormErrorAlert 
            isSubmitted={isSubmitted}
            errors={{
              ...errors,
              url: !!urlError
            }}
            errorMessages={{
              courseName: "Course name is required.",
              professors: "Professor name is required.",
              url: urlError
            }}
          />
        </Grid>
        <CourseCheck> 
        <Grid xs={12}>
          <Button variant="outlined" color="primary" onClick={() => setIsUpdateSyllabusOpen(true)}>
            Update Syllabus
          </Button>
          <Divider sx={{ marginTop: 2 }} />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          pd={3}
          sx={{ boxSizing: "border-box", width: "100%" }}
        >
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <CourseInfoFields 
              initialCourseName={courseName}
              initialProfessors={professors}
              initialTeachingAssistants={teachingAssistants}
              initialTermName={termName}
              isSubmitted={isSubmitted}
              onValidChange={({ courseName, professors, teachingAssistants, termName, isValid }) => {
                setCourseName(courseName);
                setProfessors(professors);
                setTeachingAssistants(teachingAssistants);
                setTermName(termName);
              }}
            />
          </Stack>
        </Grid>

        <Grid xs={12} sm={6}>
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <FormControl>
              <FormLabel>Course Description</FormLabel>
              <Textarea variant="outlined" color="primary" minRows={4} value={description} onChange={(e) => setDescription(e.target.value)} data-testid="course-description" />
            </FormControl>
            <CourseUrlField 
              courseUrl={courseUrl} 
              errorMessage={urlError} 
              isSubmitted={isSubmitted} 
              onValidChange={(url) => {
                setFullCourseUrl(url)
                setUrlError("")
              }} 
              onUrlChange={(changed) => setUrlChanged(changed)} />
          </Stack>
        </Grid>
        <Grid
          xs={12}
          mb={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="plain" color="danger" onClick={() => setIsDeleteDialogOpen(true)} endDecorator={<DeleteOutlineIcon />}>
              Delete Course
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate("/files")}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography>{statusMessage}</Typography>
        </Grid>
        </CourseCheck>
      </Grid>
    )}
    
    <Modal open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
      <ModalDialog>
        <DialogTitle>Confirm Course Deletion</DialogTitle>
        <ModalClose aria-label="Close Dialog" />
        <DialogContent>
          Are you sure you want to delete this course? This action cannot be undone.
        </DialogContent>
        <DialogActions className="dialog-actions-right">
          <Button variant="outlined" color="primary" onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="solid" color="danger" onClick={handleDeleteCourse}>
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
    <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
        className="url-copied"
        data-cy="copy-success-snackbar"
    >
        URL copied to clipboard!
    </Snackbar>
    <EditSyllabusModal open={isUpdateSyllabusOpen} onClose={() => setIsUpdateSyllabusOpen(false)} onSave={() => navigate("/files")}/>
  </Container>
);
}
