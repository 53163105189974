import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import './Modals.css';

export default function DuplicateFilesModal( { modalOpen, closeModal, duplicatedFiles }) {

  return (
    <React.Fragment>
      <Modal open={modalOpen} onClose={closeModal}>
        <ModalDialog
          layout="center"
          variant="outlined"
          size="lg"
          className="modal-container modal-container-mobile-fullscreen"
        >
          <DialogTitle className="modal-name">Duplicate Files Identified</DialogTitle>
          <ModalClose
            aria-label="Close Dialog"
           />
          <DialogContent>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                mb={4}
                >
                <Box>
                  <Typography level="body-lg">
                    Uploading duplicate files negatively impacts All Day TA’s ability to correctly answer questions. 
                    Please rename the file you are trying to upload if it has unique content and try again.
                  </Typography>
                </Box>
                <Alert
                  startDecorator={<InfoIcon />}
                  variant="soft"
                  size="lg"
                >
                 You can replace the source file of existing files by clicking the edit button at the end  of the file's row 
                </Alert>
                <Typography level="title-lg">
                    The following files will not be uploaded:
                </Typography>
                <Box
                  tabIndex={0}  // Make it keyboard focusable
                  role="region"  // Adds semantic meaning for screen readers
                  aria-label="List of duplicate files" 
                  className="scrollable-box"
                >
                  <List sx={{  
                      "--ListItem-paddingX": "0px",
                      "--ListItem-paddingY": "8px",
                      }}>
                    {duplicatedFiles && duplicatedFiles.map((name, index) => (
                        <ListItem
                          key={index}
                        >
                          {name}
                        </ListItem>
                    ))}
                  </List>
                </Box>
              </Stack>
            </DialogContent>
            <DialogActions className="dialog-actions-right">
              <Button
                variant="outlined"
                onClick={closeModal}>
                Close
              </Button>
            </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
