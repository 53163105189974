import axios from 'axios';
import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/joy';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import Container from '../components/Container';
import SubscriptionCard from '../components/SubscriptionCard';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import { getAllTierIds } from '../config/subscriptionConfig';

export default function Subscriptions() {

  // Get all tier IDs except trial
  const availableTiers = getAllTierIds().filter(id => 
    id !== 1
  );

  const goToCheckout = (tierId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const isUpgradeTrial = urlParams.get('upgrade-trial') === 'true';

    axios.post('/api/create-checkout-session', { tierId, isUpgradeTrial })
    .then(response => {
      if (response.data.url) {
          // Redirect to the Stripe checkout page
          window.location.href = response.data.url;
      } else {
          console.error("No URL returned from the server.");
      }
    })
    .catch(error => {
      console.error("Error creating checkout session:", error);
    });
  };

  return (
    <Box>
      <header className="min-header-wrapper">
        <Box className="left-wrapper">
            <a href="/" aria-label="Files"><img src={Logo} alt="All Day TA - Files" className="logo" /></a>
            <a href="/" aria-label="Files" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Files" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container>
        <Grid container xs={12}>
          <Stack direction="column" alignItems="flex-start" gap={2}>
            <Button variant="outlined" component="a" href="/files" startDecorator={<ArrowBackwardIcon />}>
              Back to Files
            </Button>
            <Typography level="h1">Select a Subscription</Typography>
            <Typography level="body-lg" mb={4}>All subscriptions have a 50% launch discount applied to them.</Typography>
          </Stack>
          <Stack 
            direction="row" 
            justifyContent="center" 
            alignItems="stretch" 
            gap={2}
            sx={{
              flexWrap: { xs: 'wrap', md: 'nowrap' }
            }}
          >
            {availableTiers.map(tierId => (
              <SubscriptionCard
                tierId={tierId}
                isActive={false}
                onSubscribe={() => goToCheckout(tierId)}
                sx={{ 
                  flex: 1,
                  minWidth: { xs: '100%', md: 0 }
                }}
              />
            ))}
          </Stack> 
        </Grid>
      </Container>
    </Box>
  );
}
