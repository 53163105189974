import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import {
  Alert,
  Button,
  FormHelperText,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Textarea,
  Typography,
  Divider,
} from '@mui/joy';
import DragAndDropSyllabus from '../components/DragAndDropSyllabus';
import CourseInfoFields from '../components/form-elements/CourseInfoFields';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingIcon from '../assets/LoadingLogo.gif';

export default function UpdateSyllabusForm({ onCancel, onSave, currSyllabusFileId }) {
  // Course Metadata
  const [courseMetadata, setCourseMetadata] = useState({
    courseName: '',
    description: '',
    professors: '',
    teachingAssistants: '',
    termName: '',
  });

  // Original Course Info
  const [showOriginal, setShowOriginal] = useState(false);
  const [originalCourseInfo, setOriginalCourseInfo] = useState({
    orgId: '',
    courseId: '',
    courseName: '',
    professors: '',
    teachingAssistants: '',
    termName: '',
    description: '',
    courseUrl: '',
  });

  // Syllabus File State
  const fileStates = {
    notUploaded: 'notUploaded',
    processing: 'processing',
    uploaded: 'uploaded',
  }
  const [fileState, setFileState] = useState(fileStates.notUploaded);
  const [syllabusFile, setSyllabusFile] = useState(null);
  const [syllabusText, setSyllabusText] = useState('');
  const [syllabusFileName, setSyllabusFileName] = useState('');
  const [syllabusErrors, setSyllabusErrors] = useState('');
  const [pdfUploadTimeAlert, setPdfUploadTimeAlert] = useState(false);

  // Course Info Form
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveAlert, setSaveAlert] = useState({ show: false, message: '', color: 'primary' });

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  const retrieveOriginalCourseInfo = useCallback(async () => {
    try {
      const response = await axios.get("/api/course");

      if (response.data && response.data.length > 0) {
        const courseData = response.data[0];

        setOriginalCourseInfo(prev => ({
          ...prev,
          orgId: courseData.org_id,
          courseId: courseData.course_id,
          courseName: courseData.course_name,
          professors: courseData.professor_names.toString().replaceAll(",", ", "),
          teachingAssistants: courseData.ta_names.toString().replaceAll(",", ", "),
          termName: courseData.term,
          description: courseData.description,
          courseUrl: courseData.course_url.split('/').pop(),
        }));
      }
    } catch (error) {
      console.error("Error fetching course info:", error);
    }
  }, []);

  useEffect(() => {
    retrieveOriginalCourseInfo();
  }, [retrieveOriginalCourseInfo]);

  const handleFileSelected = async (file) => {
    setFileState(fileStates.processing);
    setPdfUploadTimeAlert(file.type === 'application/pdf'); // if it's a PDF warn user it may take longer to process

    try {
      const formData = new FormData();
      formData.append("file", file);

      // process metadata from syllabus
      const response = await axios.post("/api/syllabus/prepopulate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const syllabusData = response.data;
      if (response.status === 200) {
        setCourseMetadata(prev => ({
          ...prev,
          courseName: syllabusData?.metadata?.class_name,
          description: syllabusData?.metadata?.class_description,
          professors: syllabusData?.metadata?.professors?.map(prof => prof.professor_name).join(", "),
          teachingAssistants: syllabusData?.metadata?.teaching_assistants?.map(ta => ta.teaching_assistant_name).join(", "),
          termName: syllabusData?.metadata?.term_name,
        }));
        setFileState(fileStates.uploaded);
        setSyllabusFile(file);
        setSyllabusText(syllabusData.raw_text);
        setSyllabusFileName(file.name);
      } else {
        setSyllabusErrors(syllabusData.error);
        throw syllabusData.error;
      }
    } catch (error) {
      console.error("Error processing syllabus:", error);
      setFileState(fileStates.notUploaded);
    }
  };

  const handleSave = async () => {
    setIsSubmitted(true);
    setSaveAlert({ show: false, message: '', color: 'primary' });

    // Check if the course name and professors exist// Check if the course name and professors exist
    if (!courseMetadata.courseName.trim() || !courseMetadata.professors.trim()) {
      return;
    }

    // Update course info
    const trimmedDescription = courseMetadata.description.trim();
    const courseData = {
      courseName: courseMetadata.courseName,
      trimmedDescription,
      professors: courseMetadata.professors,
      teachingAssistants: courseMetadata.teachingAssistants,
      termName: courseMetadata.termName,
    };

    setSaving(true);
    try {
      await axios.patch("/api/course_info", courseData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.error("Error saving course data: " + (error.response?.data?.message || error.message));
      setSaveAlert({ show: true, message: "Error saving course data: " + (error.response?.data?.message || error.message), color: 'danger' });
      setSaving(false);
      return;
    }

    // Add/Update syllabus
    const syllabusFormData = new FormData();
    syllabusFormData.append("syllabusFile", syllabusFile);
    syllabusFormData.append("syllabusFileName", syllabusFileName);
    syllabusFormData.append("syllabusText", syllabusText);
    syllabusFormData.append("courseName", courseMetadata.courseName);
    syllabusFormData.append("description", courseMetadata.description);

    try {
      let syllabusResponse;
      if (!currSyllabusFileId) {
        syllabusResponse = await axios.post("/api/upload_syllabus", syllabusFormData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      } else {
        syllabusFormData.append("fileId", currSyllabusFileId);
        syllabusResponse = await axios.patch("/api/upload_syllabus", syllabusFormData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }
      if (syllabusResponse.status === 200) {
        onSave();
      }
    } catch (uploadError) {
      console.error("Error uploading syllabus file:", uploadError);
      setSaveAlert({ show: true, message: "Error uploading syllabus file: " + uploadError.message, color: 'danger' });
    } finally {
      setSaving(false);
    }
  };

  const SyllabusUpload = (
    <>
      <FormControl>
        <DragAndDropSyllabus
          setSyllabusFile={handleFileSelected}
          syllabusUploaded={false}
          isProcessing={false}
        />
        <FormHelperText>
          {syllabusErrors}
        </FormHelperText>
      </FormControl>
    </>
  );

  const SyllabusLoading = (
    <>
      <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems: 'center' }}>
        <Grid>
          <img
            src={LoadingIcon}
            alt="Syllabus loading"
            style={{
              width: '3rem',
              height: '3rem',
              display: 'block',
              marginLeft: '1.5rem',
            }}
          />
        </Grid>
        <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
          <Typography level="title-lg">Course information populating from syllabus...</Typography>
        </Grid>
      </Grid>
      {pdfUploadTimeAlert && (
        <Alert
          variant="outlined"
          color="primary"
          startDecorator={<InfoOutlinedIcon />}
          sx={{ mt: 2, mb: 2 }}
          role="alert"
          aria-live="polite"
        >
          PDF files may take longer to process (roughly 10-15 seconds per page).
        </Alert>
      )}
    </>
  );

  const UpdateCourseInfo = (
    <>
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography level="body-lg" sx={{ textAlign: 'right' }}>
                {syllabusFileName}
              </Typography>
              <Button variant="outlined" onClick={() => setFileState(fileStates.notUploaded)}>Replace</Button>
            </Stack>
            <Button variant="plain" startDecorator={<VisibilityIcon />} onClick={() => setShowOriginal(prev => !prev)}>
              {showOriginal ? "Hide Original Course Info" : "Show Original Course Info"}
            </Button>
          </Stack>
          <Divider />

        </Grid>
        <Grid xs={12} sm={6}>
          <Stack
            spacing={2}
            role="group"
            aria-label="update-syllabus-form"
          >
            <CourseInfoFields
              initialCourseName={courseMetadata.courseName}
              initialProfessors={courseMetadata.professors}
              initialTeachingAssistants={courseMetadata.teachingAssistants}
              initialTermName={courseMetadata.termName}
              isSubmitted={false}
              onValidChange={(updatedMetadata) => {
                setCourseMetadata(prev => ({
                  ...prev,
                  ...updatedMetadata,
                }));
              }}
            />
            <FormControl>
              <FormLabel>Course Description</FormLabel>
              <Textarea
                variant="outlined"
                color="primary"
                minRows={4}
                value={courseMetadata.description}
                onChange={(e) => {
                  setCourseMetadata(prev => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
                data-testid="course-description" />
            </FormControl>
          </Stack>
        </Grid>
        {showOriginal && (
          <Grid xs={12} sm={6}>
            <Stack
              spacing={2}
              role="group"
              aria-label="original-syllabus-values"
            >
              <FormControl component={"div"}>
                <FormLabel>Original Course Name</FormLabel>
                <Typography level="body-md" minHeight={"2.25rem"} alignContent={"center"} sx={{ marginBottom: "calc(var(--joy-fontSize-sm) + 0.375rem * 2)" }}>
                  {originalCourseInfo.courseName}
                </Typography>
              </FormControl>
              <FormControl component={"div"}>
                <FormLabel>Original Professor Name(s)</FormLabel>
                <Typography level="body-md" minHeight={"2.25rem"} alignContent={"center"} sx={{ marginBottom: "calc(var(--joy-fontSize-sm) + 0.375rem * 2)" }}>
                  {originalCourseInfo.professors}
                </Typography>
              </FormControl>
              <FormControl component={"div"}>
                <FormLabel>Original Teaching Assistant Name(s)</FormLabel>
                <Typography level="body-md" minHeight={"2.25rem"} alignContent={"center"} sx={{ marginBottom: "calc(var(--joy-fontSize-sm) + 0.375rem * 2)" }}>
                  {originalCourseInfo.teachingAssistants}
                </Typography>
              </FormControl>
              <FormControl component={"div"}>
                <FormLabel>Original Term Name</FormLabel>
                <Typography level="body-md" minHeight={"2.25rem"} alignContent={"center"}>{originalCourseInfo.termName}</Typography>
              </FormControl>
              <FormControl component={"div"}>
                <FormLabel>Original Course Description</FormLabel>
                <Typography level="body-md" minHeight={"2.25rem"}>{originalCourseInfo.description}</Typography>
              </FormControl>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <Stack spacing={2}>
      <Typography level="body-lg">
        {
          fileState === fileStates.uploaded ?
            "The course information below has been auto-populated with information from your selected syllabus:" :
            "All Day TA will pre-populate your course information using your syllabus, this helps the AI calibrate how to handle your other files."
        }
      </Typography>
      {fileState === fileStates.notUploaded && SyllabusUpload}
      {fileState === fileStates.processing && SyllabusLoading}
      {fileState === fileStates.uploaded && UpdateCourseInfo}
      {fileState !== fileStates.uploaded &&
        <Typography level="component-md" mb={1} sx={{ textAlign: 'right' }}>
          {"Please upload a syllabus to save."}
        </Typography>}
      {saveAlert.show && (
        <Alert
          color={saveAlert.color}
          startDecorator={<InfoOutlinedIcon />}
          endDecorator={<Button variant="soft" color={saveAlert.color} onClick={() => setSaveAlert({ show: false, message: '', color: 'primary' })}>Dismiss</Button>}
          sx={{ mt: 2, mb: 2 }}
          role="alert"
          aria-live="polite">
          {saveAlert.message}
        </Alert>
      )}
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        {saving ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <img
              src={LoadingIcon}
              alt="Syllabus loading"
              style={{
                width: '2rem',
                height: '2rem',
                display: 'block',
                marginLeft: '1.5rem',
              }}
            />
            <Typography level="component-md" mb={1} sx={{ textAlign: 'right' }}>
              Saving your new syllabus...
            </Typography>
          </Stack>
        ) : (
          <>
            {onCancel &&
              <Button
                variant="outlined"
                color="primary"
                onClick={onCancel}
                disabled={saving}
              >
                Cancel
              </Button>
            }
            <Button
              variant="solid"
              color="primary"
              onClick={handleSave}
              disabled={fileState !== fileStates.uploaded}
            >
              {saving ? "Saving..." : "Save"}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
}