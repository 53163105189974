import { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Select,
  Option,
  Stack,
  Typography,
  Card,
  CardContent,
} from "@mui/joy";
import axios from "axios";
import GlobalSnackbar from "../GlobalSnackbar";
import { PROMPT_SETTINGS, PROMPT_SETTINGS_DEFAULT } from "../../config/promptSettings";

const answerStyle = {
  [PROMPT_SETTINGS.style.direct]: "More direct",
  [PROMPT_SETTINGS.style.balanced]: "Balanced",
  [PROMPT_SETTINGS.style.instructive]: "More instructive"
};

// const answerLength = {
//   [PROMPT_SETTINGS.length.short]: "Short",
//   [PROMPT_SETTINGS.length.medium]: "Medium",
//   [PROMPT_SETTINGS.length.long]: "Long"
// };

const answerSample = {
  question: "What role do telomeres play in aging and cancer?",
  answers: {
    direct: [`
    Telomeres are repetitive DNA sequences capping chromosome ends, protecting genetic material. They shorten with each
    cell division, eventually causing cell senescence or death, contributing to aging. Cancer cells bypass this limit 
    by activating telomerase, an enzyme extending telomeres, enabling endless replication. This occurs in ~90% of cancers, 
    making telomerase a therapeutic target.
    `],
    balanced: [`
    Telomeres are repetitive DNA-protein structures that protect chromosome ends. Due to the "end replication problem," 
    telomeres shorten progressively with each cell division, eventually triggering senescence or apoptosis. This shortening 
    serves as a biological clock, helping prevent cancer but also contributing to aging as cells accumulate damage or cease 
    to function effectively.`,
    `Cancer cells, however, often evade this limitation (~85-90%) by reactivating telomerase, an enzyme typically inactive in
    adult somatic cells, or by employing alternative lengthening mechanisms (ALT). By maintaining telomere length, cancer 
    cells achieve unlimited replication. Targeting telomere biology is therefore promising both in treating cancer and addressing 
    age-related diseases.
    `],
    instructive: [`
    Think of telomeres as protective caps on chromosomes—similar to the plastic tips on shoelaces. Every time a cell divides, 
    these telomeres shorten because the ends of DNA can't be fully copied. Eventually, telomeres become too short, and cells either 
    stop dividing (senescence) or self-destruct (apoptosis). Can you think of why the body evolved this mechanism? It's actually 
    helpful in preventing cells from becoming cancerous, but there's a downside too—this shortening contributes to aging as more 
    cells become inactive.`,
    `Cancer cells, though, found a workaround. Most (~85-90%) activate an enzyme called telomerase that rebuilds telomeres, 
    allowing endless division. Others use a different method, called ALT. Why do you think cancer cells need telomeres to stay long? 
    Understanding this reveals an interesting balancing act: the same mechanism that helps prevent cancer also contributes to aging. 
    Can you see why scientists might want to target telomeres to treat both cancer and aging-related diseases?
    `]
  }
}

export default function AnswerStyleForm({ promptDefault = PROMPT_SETTINGS_DEFAULT, courseId }) {
  const [length, setLength] = useState(promptDefault.length);
  const [style, setStyle] = useState(promptDefault.style);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    color: "danger"
  });

  useEffect(() => {
    setLength(promptDefault.length);
    setStyle(promptDefault.style);
  }, [promptDefault]);

  // const handleLengthChange = async (_, newValue) => {
  //   try {
  //     await axios.post("/api/update_course_settings", {
  //       course_id: courseId,
  //       prompt_default: `${newValue}-${style}`
  //     });
  //     setLength(newValue);
  //     setSnackbar({
  //       open: true,
  //       message: "Answer length updated successfully",
  //       color: "success"
  //     });
  //   } catch (error) {
  //     console.error("Error updating course settings:", error);
  //     const errorMsg = error.response?.data?.error|| error.message;
  //     setSnackbar({
  //       open: true,
  //       message: `Error updating answer length: ${errorMsg}`,
  //       color: "danger"
  //     });
  //   }
  // }

  const handleStyleChange = async (_, newValue) => {
    try {
      await axios.post("/api/update_course_settings", {
        course_id: courseId,
        prompt_default: `${length}-${newValue}`
      });
      setStyle(newValue);
      setSnackbar({
        open: true,
        message: "Answer style updated successfully",
        color: "success"
      });
    } catch (error) {
      console.error("Error updating course settings:", error);
      const errorMsg = error.response?.data?.error || error.message;
      setSnackbar({
        open: true,
        message: `Error updating answer style: ${errorMsg}`,
        color: "danger"
      });
    }
  } 

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography level="h3">Answer Style</Typography>
          <Typography level="body-lg">
            Customize the default answer style for your course's AI Assistant. This style will be applied to all student interactions through the AI Assistant.
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          {/* <FormControl>
            <FormLabel>Length</FormLabel>
            <Select color="primary" value={length} sx={{ minWidth: '14rem' }} onChange={handleLengthChange}>
              {Object.keys(answerLength).map((key) => (
                <Option key={key} value={key}>{answerLength[key]}</Option>
              ))}
            </Select>
          </FormControl> */}
          <FormControl>
            <FormLabel>Style</FormLabel>
            <Select color="primary" value={style} sx={{ minWidth: '14rem' }} onChange={handleStyleChange}>
              {Object.keys(answerStyle).map((key) => (
                <Option key={key} value={key}>{answerStyle[key]}</Option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Card variant="outlined" role="group" aria-label="disable-url-form" sx={{ gap: 0 }}>
          <CardContent sx={{ gap: 2 }}>
            <Stack direction="row" spacing={1}>
              <Typography level="title-md">Review a sample of the selected answer style:</Typography>
              <Typography level="body-md">{answerStyle[style]}</Typography>
            </Stack>
            <Stack spacing={1} pl={2}>
              <Typography level="title-md">{'Q: ' + answerSample.question}</Typography>
              <Stack spacing={1}>
                {answerSample.answers[style].map((paragraph, index) => (
                  paragraph.trim() && 
                  <Typography key={index} level="body-md">
                    {(index === 0 ? 'A: ' : '') + paragraph.trim()}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <GlobalSnackbar 
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </>
  );
}