import * as React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';

const InfoTooltip = ({ info, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>Additional Information</DialogTitle>
        <ModalClose
          aria-label="Close Dialog"
         />
        <DialogContent>
          <Typography level="body-lg" mt={2}>
            {info}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} sx={{maxWidth:'max-content'}}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default InfoTooltip;