import axios from 'axios';
import React, { useState } from 'react';
import {
  Button
} from '@mui/joy';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import GlobalSnackbar from '../GlobalSnackbar';

const DownloadCSVButton = ({ startTime, endTime, isDisabled }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    
    try {
      const response = await axios({
        url: '/api/download_question_log_csv',
        method: 'GET',
        params: {
          start_time: startTime,
          end_time: endTime
        },
        responseType: 'blob'
      });
      
      /*console.log('Response received:', {
        status: response.status,
        headers: response.headers,
        dataType: response.data.type,
        dataSize: response.data.size
      });*/

      // Check if we got a JSON response indicating an error
      const contentType = response.headers['content-type'];
      //console.log('Content type:', contentType);
      
      if (contentType && contentType.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          const errorData = JSON.parse(reader.result);
          setSnackbar({
            open: true,
            message: 'Download failed. Please contact support.',
            color: 'danger'
          });
        };
        reader.readAsText(response.data);
        return;
      }

      // Create and download the file
      const blob = new Blob([response.data], { 
        type: 'text/csv;charset=utf-8;' 
      });
      
      // Extract filename from Content-Disposition header if available
      let filename = '';
      const contentDisposition = response.headers['content-disposition'];
      
      if (contentDisposition) {
        const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }
      }
      
      // Fallback to our standard format if no filename found
      if (!filename) {
        const startDate = startTime.split(' ')[0];
        const endDate = endTime.split(' ')[0];
        filename = `weekly_questions_${startDate}_to_${endDate}.csv`;
      }
      
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      setSnackbar({
        open: true,
        message: 'CSV file downloaded successfully!',
        color: 'success'
      });
      
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Download failed. Please contact support.',
        color: 'danger'
      });
      
      // If we have a blob response in the error, try to read it
      if (error.response?.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorData = JSON.parse(reader.result);
            //console.error('Server error details:', errorData);
          } catch (e) {
            //console.error('Could not parse error response as JSON:', reader.result);
          }
        };
        reader.readAsText(error.response.data);
      }
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleDownload}
        disabled={isDisabled || isDownloading}
        variant="soft"
        size="sm"
      >
        Download Questions as CSV
      </Button>
      <GlobalSnackbar
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        color={snackbar.color}
      />
    </>
  );
};

export default DownloadCSVButton;