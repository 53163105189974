// similar to drag and drop but doesn't upload on drag
// and also displays all the files that will be uploaded
// this is specifically for the edit file modal!
// can only select one file
import { useRef, useState } from "react";
import { Alert, Box, Button, Typography } from "@mui/joy";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import "./DragDrop.css";


export default function FileSelector({ onFileSelected }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const supportedFileTypes = [
    'pdf', 'pptx', 'docx', 'txt', 'tex', 'md', 'html', 'htm', 'mp3', 'mp4', 'wav', 'ogg', 'm4a'
  ];

  const isFileTypeSupported = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    return supportedFileTypes.includes(extension);
  };

  // this is a function that is responsible for processing and adding the selected files to 
  // the components state after they are selected
  const addFile = (file) => {
    if (!isFileTypeSupported(file)) {
      setErrorMessage('Unsupported file type');
      return;
    }

    const newFile = {
      id: Date.now() + Math.random(), // Generate a unique ID for each file
      title: file.name,
      status: "Selected",
      file, // Keep the file object for further processing, and so that I can pass it and process in DB
    };

    setSelectedFile(newFile); // Replace the previous files with the new single file
    onFileSelected(newFile); // pass the updated file list to the parent component!
    setErrorMessage('');
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      addFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      setErrorMessage('Please drop only one file');
      return;
    }

    const file = files[0];
    addFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      component="section"
      className="drag-drop-container"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <Typography level="body-lg">Drag and drop your file here </Typography>
      <Typography level="body-lg">or</Typography>
      <Button variant="soft" color="primary" onClick={handleButtonClick}>
        Select a File
      </Button>
      <Typography level="component-sm">Supported filetypes: .pdf, .pptx, .docx, .txt, .tex, .md, .html, .htm, .mp3, .mp4, .wav, .ogg, .m4a</Typography>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        multiple={false} // Allow only one file
        accept=".pdf,.pptx,.docx,.txt,.tex,.md,.html,.htm,.mp3, .mp4, .wav, .ogg, .m4a"
      />
      {errorMessage && (
        <Alert 
          variant="soft" 
          color="danger" 
          size="md" 
          startDecorator={<ErrorOutlineIcon/>}
          role="alert"
          aria-live="polite"
        >
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
}
