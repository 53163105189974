import React from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { Typography } from '@mui/joy';

const LatexRenderer = ({ text }) => {
  const renderLatex = (input) => {
    try {
      // Match:
      // 1. Block LaTeX: \[...\]
      // 2. Inline LaTeX: \(...\)
      // 3. /$ LaTeX $ format \/\$.*?\$
      // 4. Bare numbers with exponents ^ (but not footnote-style numbers in brackets)
      const parts = input.split(/((?<!\\)\\\[[\s\S]*?(?<!\\)\\\]|(?<!\\)\\\(.*?(?<!\\)\\\)|\/\$.*?\$|(?<!\[)\d+\^-?\d+(?!\]))/g);
      
      return parts.map((part, index) => {
        if (part.startsWith('\\[') && part.endsWith('\\]')) {
          // Block LaTeX
          return <BlockMath key={index} math={part.slice(2, -2)} />;
        } else if (part.startsWith('\\(') && part.endsWith('\\)')) {
          // Inline LaTeX
          return <InlineMath key={index} math={part.slice(2, -2)} />;
        } else if (part.startsWith('/$') && part.endsWith('$')) {
          // /$ LaTeX $ format - treat as inline math
          return <InlineMath key={index} math={part.slice(2, -1)} />;
        } else if (/^\d+\^-?\d+$/.test(part)) {
          // Convert bare number^exponent to LaTeX
          const [base, exponent] = part.split('^');
          return <InlineMath key={index} math={`${base}^{${exponent}}`} />;
        } else {
          // Regular text - render as is, preserving newlines
          return part.split('\n').map((line, lineIndex) => (
            <React.Fragment key={`${index}-${lineIndex}`}>
              {line}
              {lineIndex < part.split('\n').length - 1 && <br />}
            </React.Fragment>
          ));
        }
      });
    } catch (error) {
      console.error('Error rendering LaTeX for input:', input, error);
    }
  };

  return <>{renderLatex(text)}</>;
};

export default LatexRenderer;