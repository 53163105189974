import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Stack, 
  Typography, 
} from '@mui/joy';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import Container from '../components/Container';
import HomeworkForm from '../components/HomeworkForm';

export default function Homework({ headerRef }) {
  const [courseId, setCourseId] = useState(null);

  useEffect(() => {
    if (headerRef.current) {
        headerRef.current.setOnCourseChange(setCourseId);
    }
  }, [headerRef, setCourseId]);

  return (
    <Container>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2} alignItems="center" mb={3}>
          <MenuBookIcon fontSize="xl4" />
          <Typography level="h1" >
            Current Homework
          </Typography>
        </Stack>
        <Typography level="body-lg">
          Prevent your students from avoiding work by copying and pasting your most recent homework questions into the text area below. The AI Assistant will refuse to answer these questions directly until you clear the text area or replace it with updated homework. This field currently only accepts simple text input.        </Typography>
        <HomeworkForm courseId={courseId}/>
      </Stack>
    </Container>
  );
}