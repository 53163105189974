import axios from 'axios';
import { useState } from 'react';
import {
  Dropdown,
  ListDivider,
  Menu,
  MenuButton,
  MenuItem
} from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GlobalSnackbar from '../GlobalSnackbar';
import { 
  STATUS, 
  // isProcessingStatus, 
  // isSuccessStatus, 
  hasError, 
  canPerformActions 
} from './fileStatusUtils';

const ActionsMenu = ({ selected, setFiles }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'neutral',
  });
  
  // Check if any of the selected files has an error status
  const hasAnyError = Array.isArray(selected) && selected.some(file => hasError(file.status));
  
  // Check if actions should be disabled
  const shouldDisableActions = hasAnyError || selected.length === 0;

  // Check if any selected files are notes
  const hasSelectedNotes = Array.isArray(selected) && selected.some(file => file.content_type === "note");

  // Update active status for multiple files
  const handleActiveChange = async (newIsActive) => {
    const filesArray = Array.isArray(selected) ? selected : [];
    
    if (filesArray.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select files to update.",
        color: "danger",
      });
      return;
    }

    // Only allow files that don't have another action taking place
    const eligibleFiles = filesArray.filter(file => canPerformActions(file));

    if (eligibleFiles.length === 0) {
      setSnackbar({
        open: true,
        message: "None of the selected files can be modified at this time.",
        color: "danger",
      });
      return;
    }
    
    // Update UI immediately to show processing state
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        eligibleFiles.some(selectedFile => selectedFile.file_id === file.file_id)
          ? { ...file, isactive: newIsActive, status: STATUS.UPDATING }
          : file
      )
    );

    // Process each file in parallel
    const updatePromises = eligibleFiles.map(async (file) => {
      try {
        const response = await axios.patch(`/api/update_isactive/${file.org_id}/${file.course_id}/${file.file_id}/`, {
          isactive: newIsActive
        });
        
        return {
          file_id: file.file_id,
          success: response.status === 200
        };
      } catch (error) {
        console.error(`Error updating file ${file.file_id}:`, error);
        return {
          file_id: file.file_id,
          success: false
        };
      }
    });
    
    const results = await Promise.all(updatePromises);
    
    // Update file statuses based on results
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        const result = results.find(r => r.file_id === file.file_id);
        if (result) {
          if (result.success) {
            return { ...file, status: STATUS.SUCCESS, isactive: newIsActive };
          } else {
            return { ...file, status: "Error updating active status", isactive: !newIsActive };
          }
        }
        return file;
      })
    );

    // Show success message if all files were updated successfully
    if (results.every(file => file.success)) {
      setSnackbar({
        open: true,
        message: "All files updated successfully.",
        color: "success",
      });
    } else {
      setSnackbar({
        open: true,
        message: "Some files could not be updated. Please try again.",
        color: "danger",
      });
    }
  };

  // Consolidated function to update optional status for multiple files
  const handleOptionalChange = async (newIsOptional) => {
    const filesArray = Array.isArray(selected) ? selected : [];
    
    if (filesArray.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select files to update.",
        color: "danger",
      });
      return;
    }

    // Only allow files that don't have another action taking place
    const eligibleFiles = filesArray.filter(file => canPerformActions(file));

    if (eligibleFiles.length === 0) {
      setSnackbar({
        open: true,
        message: "None of the selected files can be modified at this time.",
        color: "danger",
      });
      return;
    }
    
    // Update UI immediately to show processing state
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        eligibleFiles.some(selectedFile => selectedFile.file_id === file.file_id)
          ? { ...file, isoptional: newIsOptional, status: STATUS.UPDATING }
          : file
      )
    );

    // Process each file in parallel
    const updatePromises = eligibleFiles.map(async (file) => {
      try {
        const response = await axios.patch(`/api/update_isoptional/${file.org_id}/${file.course_id}/${file.file_id}/`, {
          isoptional: newIsOptional
        });
        
        return {
          file_id: file.file_id,
          success: response.status === 200
        };
      } catch (error) {
        console.error(`Error updating file ${file.file_id}:`, error);
        return {
          file_id: file.file_id,
          success: false
        };
      }
    });
    
    const results = await Promise.all(updatePromises);
    
    // Update file statuses based on results
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        const result = results.find(r => r.file_id === file.file_id);
        if (result) {
          if (result.success) {
            return { ...file, status: STATUS.SUCCESS, isoptional: newIsOptional };
          } else {
            return { ...file, status: "Error updating weight", isoptional: !newIsOptional };
          }
        }
        return file;
      })
    );

    // Show success message if all files were updated successfully
    if (results.every(file => file.success)) {
      setSnackbar({
        open: true,
        message: "All files updated successfully.",
        color: "success",
      });
    } else {
      setSnackbar({
        open: true,
        message: "Some files could not be updated. Please try again.",
        color: "danger",
      });
    }
  };

  // Function to download notes
  const handleDownloadNotes = async () => {
    const filesArray = Array.isArray(selected) ? selected : [];
    
    if (filesArray.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select notes to download.",
        color: "danger",
      });
      return;
    }

    // Only allow notes that can be downloaded
    const eligibleNotes = filesArray.filter(file => 
      file.content_type === "note" && canPerformActions(file)
    );

    if (eligibleNotes.length === 0) {
      setSnackbar({
        open: true,
        message: "None of the selected files are notes that can be downloaded.",
        color: "danger",
      });
      return;
    }

    // Set status to updating to show loading state
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        eligibleNotes.some(selectedFile => selectedFile.file_id === file.file_id)
          ? { ...file, status: STATUS.UPDATING }
          : file
      )
    );

    // Process each note in sequence (to avoid too many parallel downloads)
    for (const note of eligibleNotes) {
      try {
        const response = await axios.get(`/api/get_note_text/${note.file_id}`);
        const file_content = response.data.text;
        const blob = new Blob([file_content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${response.data.title || note.title}.txt`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        
        // Reset status after successful download
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === note.file_id
              ? { ...file, status: STATUS.SUCCESS }
              : file
          )
        );
      } catch (error) {
        console.error(`Error downloading note ${note.file_id}:`, error);
        
        // Update status to show error
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_id === note.file_id
              ? { ...file, status: "Error downloading note" }
              : file
          )
        );
        
        setSnackbar({
          open: true,
          message: `Error downloading note: ${note.title}`,
          color: "danger",
        });
      }
    }
  };

  return (
    <>
      <Dropdown>
        <MenuButton
          variant="outlined"
          color="primary"
          endDecorator={<ArrowDropDownIcon />}
        >
          Actions
        </MenuButton>
        <Menu placement="bottom-start">
          <MenuItem 
            onClick={() => handleActiveChange(true)}
            disabled={shouldDisableActions}
          >
            Set as Active
          </MenuItem>
          <MenuItem 
            onClick={() => handleActiveChange(false)}
            disabled={shouldDisableActions}
          >
            Set as Inactive
          </MenuItem>
          <ListDivider />
          <MenuItem 
            onClick={() => handleOptionalChange(false)}
            disabled={shouldDisableActions}
          >
            Update weight to Core
          </MenuItem>
          <MenuItem 
            onClick={() => handleOptionalChange(true)}
            disabled={shouldDisableActions}
          >
            Update weight to Optional
          </MenuItem>
          <ListDivider />
          <MenuItem 
            onClick={handleDownloadNotes}
            disabled={!hasSelectedNotes || shouldDisableActions}
          >
            Download Notes
          </MenuItem>
        </Menu>
      </Dropdown>
      <GlobalSnackbar 
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </>
  );
};

export default ActionsMenu;