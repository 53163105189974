import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  DialogActions,
  Stack,
  Table,
  Sheet,
  Typography,
  Link
} from "@mui/joy";
import './Modals.css';
import GlobalSnackbar from './GlobalSnackbar';

export default function AdminDetailsModal({
  open,
  onClose,
  admin
}) {
  const [ownerCourses, setOwnerCourses] = useState([]);
  const [collaboratorCourses, setCollaboratorCourses] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', color: 'success' });

  useEffect(() => {
    const fetchCourses = async () => {
      if (admin?.id) {
        try {
          const response = await axios.get(`/api/admin-courses/${admin.id}`);
          setOwnerCourses(response.data.owner_courses);
          setCollaboratorCourses(response.data.collaborator_courses);
        } catch (error) {
          console.error('Error fetching admin courses:', error);
        }
      }
    };
    
    fetchCourses();
  }, [open, admin]);

  const handleDownloadCourseData = async (courseId) => {
    try {
      const response = await axios.get(`/api/course-export/${courseId}`);
      const dataStr = JSON.stringify(response.data, null, 2);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `course-${courseId}-data.json`;
      link.click();
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      await axios.delete(`/api/delete/course/${selectedCourse.org_id}/${selectedCourse.course_id}`);
      setOwnerCourses(prev => prev.filter(c => c.course_id !== selectedCourse.course_id));
      setCollaboratorCourses(prev => prev.filter(c => c.course_id !== selectedCourse.course_id));
      setSnackbar({ open: true, message: 'Course deleted successfully', color: 'success' });
    } catch (error) {
      console.error('Delete course error:', error);
      const errorMsg = error.response?.data?.details || error.message;
      setSnackbar({ open: true, message: `Delete failed: ${errorMsg}`, color: 'danger' });
    } finally {
      setDeleteConfirmOpen(false);
      setSelectedCourse(null);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        layout="fullscreen"
        variant="outlined"
        size="lg"
        className="modal-container"
      >
        <DialogTitle className="modal-name">Admin Details: {admin?.admin_name}</DialogTitle>
        <ModalClose
          aria-label="Close Dialog"
        />
        <DialogContent>
          <Stack spacing={2} sx={{ mb: 4 }}>
            <Typography level="body-md">Name: {admin?.name}</Typography>
            <Typography level="body-md">Email: {admin?.email}</Typography>
          </Stack>
          <Typography level="h3">Courses:</Typography>
          <Sheet variant="plain" sx={{backgroundColor: 'var(--joy-palette-background-surface)'}}>
            <Table 
              hoverRow
              aria-label="Course details table" 
              size="md"
              sx={{
                minWidth: '1100px',
                '@media (max-width: 820px)': {
                  width: '100%',
                }
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: '4rem' }}>ID</th>
                  <th>Course Name</th>
                  <th>Admin Role</th>
                  <th>Course URL</th>
                  <th style={{ width: '12rem' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {[...ownerCourses, ...collaboratorCourses].map((course) => (
                  <tr key={course.course_id}>
                    <td>{course.course_id}</td>
                    <td>{course.course_name}</td>
                    <td>
                      {ownerCourses.some(oc => oc.course_id === course.course_id) 
                        ? 'Owner' : 'Collaborator'}
                    </td>
                    <td>
                      <Link 
                        href={course.course_url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline' }}
                      >
                        {course.course_url}
                      </Link>
                    </td>
                    <td>
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                          <>
                            <Button 
                              variant="outlined" 
                              onClick={() => handleDownloadCourseData(course.course_id)}
                            >
                              Download
                            </Button>
                            <Button
                              variant="outlined"
                              color="danger"
                              onClick={() => {
                                setSelectedCourse(course);
                                setDeleteConfirmOpen(true);
                              }}
                            >
                              Delete
                            </Button>
                          </>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>

          {/* Delete Confirmation Modal */}
          <Modal open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
            <ModalDialog>
              <DialogTitle>Confirm Course Deletion</DialogTitle>
              <ModalClose aria-label="Close Dialog" />
              <DialogContent>
                Are you sure you want to permanently delete {selectedCourse?.course_name}? 
                This will remove all course data and cannot be undone.
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => setDeleteConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button variant="solid" color="danger" onClick={handleDeleteCourse}>
                  Confirm Delete
                </Button>
              </DialogActions>
            </ModalDialog>
          </Modal>

          <GlobalSnackbar 
            open={snackbar.open}
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            message={snackbar.message}
            color={snackbar.color}
            ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
          />
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
