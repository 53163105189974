import * as React from "react";
import { useState } from "react";
import {
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ActionsMenu from './ActionsMenu';
import ModulesMenu from './ModulesMenu';
import ShareFilesModal from './ShareFilesModal';
import DeleteFileModal from './DeleteFileModal';
import { canDelete } from './fileStatusUtils';
import GlobalSnackbar from "../GlobalSnackbar";

const FileTableActions = ({ 
  selected, 
  setSelected,
  files,
  setFiles,
  modules,
  setModules,
}) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'neutral',
  });
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedForSharing, setSelectedForSharing] = useState([]);
  const [shareCode, setShareCode] = useState('');
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [modulesToDelete, setModulesToDelete] = useState([]);

  const handleShareClick = async () => {
    if (selected.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select files to share.",
        color: 'danger',
      });
      return;
    }

    try {
      const share_code = uuidv4();
      const file_ids = selected.map((file) => file.file_id);
      const file_titles = selected.map(file => {return {title: file.title}});
      await axios.post('/api/share', { data: { file_ids: file_ids, share_key: share_code } });
      setSelectedForSharing(file_titles);
      setShareCode(share_code);
      setShareModalOpen(true);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to share files. Please try again.",
        color: 'danger',
      });
    }
  };

  const handleDeleteSelectedFiles = async () => {
    if (selected.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select files to delete.",
        color: 'danger',
      });
      return;
    }

    // Get files and modules to delete
    const selectedFiles = []
    const selectedModules = []
    for (const item of selected) {
      if (item?.isModule) {
        const moduleId = parseInt(item.id.split('module-')[1]);
        const moduleFiles = files.filter(file => file.module && file.module.id === moduleId);
        if (moduleFiles.every(file => canDelete(file)) || moduleFiles.length === 0) {
          // If all files in the module are eligible or the module is empty, add the module to the list of modules to delete
          selectedFiles.push(...moduleFiles);
          selectedModules.push({...item, id: moduleId});
        }
        else {
          setSnackbar({
            open: true,
            message: "Module '" + item.title + "' contains files that are currently being processed. Please wait for them to finish processing before deleting the module.",
            color: 'danger',
          });
          return;
        }
      } else {
        if (canDelete(item)) {
          selectedFiles.push(item);
        }
      }
    }

    if (selectedFiles.length === 0 && selectedModules.length === 0) {
      setSnackbar({
        open: true,
        message: "None of the selected files can be deleted at this time.",
        color: 'danger',
      });
      return;
    }

    setFilesToDelete(selectedFiles);
    setModulesToDelete(selectedModules);
    setDeleteModalOpen(true);
  };

  const handleDelete = (item) => {
    console.log("Deleting item", item);
    if (item?.isModule) {
      setModules((prevModules) =>
        prevModules.map((m) =>
          m.id === item.id ? { ...m, status: 'Currently Deleting...' } : m
        )
      );
    } else {
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.file_id === item.file_id ? { ...f, status: 'Currently Deleting...' } : f
        )
      );
    }
  };

  const handleConfirmDelete = (item) => {
    setSelected([]);
    if (item?.isModule) {
      setModules((prevModules) =>
        prevModules.filter((m) => m.id !== item.id)
      );
    } else {
      setFiles((prevFiles) => prevFiles.filter((f) => f.file_id !== item.file_id));
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ActionsMenu 
          selected={selected} 
          setFiles={setFiles}
        />
        <ModulesMenu
          selected={selected}
          setFiles={setFiles}
          modules={modules}
          setModules={setModules}
        />
        <Button
          variant="outlined"
          onClick={handleShareClick}
        >
          Share
        </Button>
        <Button
          variant="outlined"
          onClick={handleDeleteSelectedFiles}
        >
          Delete
        </Button>
        {selected.length > 0 && (
          <Typography level="component-sm">
            {selected.length} {selected.length === 1 ? 'file' : 'files'} selected
          </Typography>
        )}
      </Stack>

      <ShareFilesModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        shareFiles={selectedForSharing}
        share_code={shareCode}
      />
      <DeleteFileModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setFilesToDelete([]);
        }}
        files={filesToDelete}
        modules={modulesToDelete}
        onDelete={handleDelete}
        onConfirmDelete={handleConfirmDelete}
      />
      <GlobalSnackbar
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </>
  );
};

export default FileTableActions;