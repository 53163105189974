import React from "react";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState, useContext, useRef } from "react";
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Stack,
  Typography,
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import Close from "@mui/icons-material/Close";
import InboxIcon from "@mui/icons-material/Inbox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionIcon from "@mui/icons-material/CardMembership";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { AuthContext } from '../context/AuthContext';
import './Menu.css'

export default function Menu({courseUrl}) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const menuButtonRef = useRef(null);
  const closeButtonRef = useRef(null);

  const toggleDrawer = useCallback((inOpen) => {
    setOpen(inOpen);
    if (inOpen) {
      setTimeout(() => closeButtonRef.current?.focus(), 100);
    } else {
      menuButtonRef.current?.focus();
    }
  }, []);

  const handleNavigation = useCallback((path, isExternal = false) => {
    toggleDrawer(false);
    if (typeof path === 'function') {
      path(); // This will call handleLogout for the Logout item
    } else if (isExternal) {
      window.open(path, '_blank', 'noopener,noreferrer');
    } else {
      navigate(path);
    }
  }, [toggleDrawer, navigate]);

  const handleLogout = useCallback(async () => {
    //console.log("Currently trying to log out...");
    try {
      const response = await logout();
      setMessage(response.message);
      if (response.message === "Success") {
        toggleDrawer(false);
        //console.log(response);
        navigate('/login/admin');
      }
    } catch (error) {
      setMessage('Error logging out');
    }
  }, [logout, navigate, toggleDrawer]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        toggleDrawer(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [toggleDrawer]);

  const menuItems = [
    ["Files", <InboxIcon />, '/files'],
    ["Course Information", <InfoOutlinedIcon />, '/course-info'],
    ["Current Homework", <MenuBookIcon />, '/homework'],
    ["Weekly Summaries", <SendIcon />, '/summaries'],
    ["Settings", <SettingsIcon />, '/settings'],
    ["Your Subscription", <SubscriptionIcon />, '/your-subscription'],
    ["Preview Your All Day TA", <RemoveRedEyeIcon />, '/preview'],
    ["Logout", <OpenInNewIcon />, handleLogout],
  ];

  return (
    <Box className="menu-wrapper">
      <Button
        variant="plain"
        color="primary"
        onClick={() => toggleDrawer(true)}
        endDecorator={<MenuIcon/>}
        ref={menuButtonRef}
        aria-haspopup="true"
        aria-expanded={open}
        aria-label="Open menu"
      >
        Menu
      </Button>
      <Drawer
        open={open} 
        anchor="right" 
        variant="plain" 
        size="md"
        role="dialog" 
        aria-label="Menu Drawer" 
        onClose={() => toggleDrawer(false)}
        slotProps={{
          content: {
            role: 'dialog',
            'aria-modal': true,
            'aria-label': 'Navigation menu'
          }
        }}
      >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding:'1rem 1rem 0 2rem' }}>
            <Typography
              id=":r2f:"
              level="component-md"
              color="primary"
            >
              Menu
            </Typography>
            <Button
              variant="plain"
              className="close-btn"
              onClick={() => toggleDrawer(false)}
              color="primary"
              ref={closeButtonRef}
              aria-label="Close menu"
            >
              <Close/>
            </Button>
            </Stack>
          <nav aria-label="Menu options">
          <List className="items-list" component="ul" sx={{"--List-gap": "1rem"}}>
            {menuItems.map(([text, icon, path, isExternal]) => (
              <React.Fragment key={text}>
                <ListItem>
                  <ListItemButton
                    onClick={() => handleNavigation(path, isExternal)}
                    className="list-item"
                    component={isExternal ? "a" : "button"}
                    href={isExternal ? path : undefined}
                    target={isExternal ? "_blank" : undefined}
                    rel={isExternal ? "noopener noreferrer" : undefined}
                    >   
                      <ListItemDecorator color="primary">
                        {icon}
                      </ListItemDecorator>
                      <Typography color="primary">{text}</Typography>
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            ))}
            <ListItem>
                <ListItemButton
                  variant="plain"
                  color="primary"
                  className="list-item-last"
                  component="a"
                  href='https://alldayta.com/faqs'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemDecorator color="primary">
                    <HelpIcon />
                  </ListItemDecorator>
                  <Typography color="primary">FAQs</Typography>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                  variant="plain"
                  color="primary"
                  className="list-item"
                  component="a"
                  href='https://alldayta.com/support'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemDecorator color="primary">
                    <HelpIcon />
                  </ListItemDecorator>
                  <Typography color="primary">Contact Support</Typography>
                </ListItemButton>
            </ListItem>
            {courseUrl && (
              <ListItem>
                <ListItemButton
                  variant="plain"
                  color="primary"
                  onClick={() => handleNavigation(courseUrl, true)}
                  className="list-item"
                  component="a"
                  href={courseUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemDecorator color="primary">
                    <ShareIcon />
                  </ListItemDecorator>
                  <Typography color="primary">View Student All Day TA</Typography>
                </ListItemButton>
              </ListItem>
            )}
          </List>
          </nav>
          <Box className="bottom-wrapper">
              <Button
                onClick={() => handleNavigation('/create-course')}
                variant="soft"
                color="primary"
                className="new-course-btn"
                startDecorator={<AddIcon/>}
              >
                Create a New Course
              </Button>
          </Box>
      </Drawer >
    </Box>
  );
}
