import { useEffect, useRef } from 'react';
import axios from 'axios';

export const FILE_EVENTS = {
  UPDATE: 'update',
  ADD: 'add',
  DELETE: 'delete',
  COURSE_UPDATE: 'course_update',
  COURSE_DELETE: 'course_delete'
};

/**
 * Custom hook for managing files state and real-time file updates using EventSource
 * @param {Function} setFiles - Function to set the files state
 * @param {Function} onEvent(notifyAdmin, data) - Callback for file update events
 */
export default function useFileUpdates({
    setFiles,
    onEvent = (notifyAdmin, data) => {}
  }) {
  const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
  const eventSourceRef = useRef(null);
  const beforeUnloadListenerRef = useRef(null);

  // Convert backend data to display format
  const convertToDisplay = (data) => {
    return data
      .filter(file => file.content_type !== 'homework')
      .map((item, index) => ({ ...item, id: index + 1 }));
  };

  // Event source connection for real-time updates
  const subscribeFileUpdates = () => {
    try {
      eventSourceRef.current = new EventSource(
        `${baseUrl}/stream/file_updates`,
        process.env.REACT_APP_API_URL ? { withCredentials: true } : undefined
      );

      eventSourceRef.current.onmessage = (event) => {
        try {
          const notifyAdmin = event.data.split(";")[0] === "True";
          const data = JSON.parse(event.data.split(";")[1]);

          switch (data.type) {
            case FILE_EVENTS.UPDATE:
              setFiles(prevFiles =>
                prevFiles.map(file =>
                  file.file_id === data.file_id && file.course_id === data.course_id
                    ? { ...file, ...data.updated_fields }
                    : file
                )
              );
              break;
            case FILE_EVENTS.ADD:
              const newFile = convertToDisplay([data.file])[0];
              setFiles((prevFiles) => {
                newFile.id = prevFiles.length + 1;
                return [...prevFiles, newFile];
              });
              break;
            case FILE_EVENTS.DELETE:
              setFiles((prevFiles) => {
                const newFiles = prevFiles.filter(
                  file => file.file_id !== data.file_id && file.course_id === data.course_id
                );
                return newFiles.map((file, index) => ({ ...file, id: index + 1 }));
              });
              break;
            default:
              break;
          }

          onEvent(notifyAdmin, data);
        } catch (error) {
          console.error("Error processing file update event:", error);
        }
      };

      eventSourceRef.current.onerror = (error) => {
        console.error("EventSource error:", error);
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
          // Try to unsubscribe but handle any errors
          try {
            axios.post('/api/unsubscribe_file_updates').catch(err => {
              // Silently handle 401 errors during unsubscribe
              if (err.response && err.response.status !== 401) {
                console.error("Error unsubscribing file updates:", err);
              }
            });
          } catch (unsubError) {
            console.error("Error during unsubscribe:", unsubError);
          }
        }
      };
    } catch (error) {
      console.error("Error setting up EventSource:", error);
    }
  };

  const unsubscribeFileUpdates = () => {
    try {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        // Use axios with error handling for the unsubscribe request
        axios.post('/api/unsubscribe_file_updates').catch(error => {
          // Silently handle 401 errors during unsubscribe
          if (error.response && error.response.status !== 401) {
            console.error("Error unsubscribing file updates:", error);
          }
        });
      }
    } catch (error) {
      console.error("Error during unsubscribe:", error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      try {
        if (navigator.sendBeacon) {
          navigator.sendBeacon(baseUrl + '/api/unsubscribe_file_updates'); // sendBeacon is more reliable than axios on unload, prevents NS_BINDING_ABORTED from appearing in the console
        } else {
          axios.post('/api/unsubscribe_file_updates').catch(error => {
            // We don't need to log errors during page unload as they're expected
          });
        }
      } catch (error) {
        // Ignore errors during page unload
      }
    };
    
    beforeUnloadListenerRef.current = handleBeforeUnload;
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    // Clean up on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return { subscribeFileUpdates, unsubscribeFileUpdates };
}