import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import axios from 'axios';
import {
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  IconButton
} from '@mui/joy';
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

export default function CreateOrgDrawer({ setMessage, getOrgs, setIsError, initState }) {
  const [open, setOpen] = useState(false);
  const [orgName, setOrgName] = useState(initState?.name || '');
  const [orgContact, setOrgContact] = useState(initState?.org_contact || '');
  const [orgEmail, setOrgEmail] = useState(initState?.org_email || '');
  const [errors, setErrors] = useState({
    orgName: false,
    orgContact: false,
    orgEmail: false
  });
  const addButtonRef = useRef(null);
  const closeButtonRef = useRef(null);

  const resetForm = useCallback(() => {
    setOrgName(initState?.name || '');
    setOrgContact(initState?.org_contact || '');
    setOrgEmail(initState?.org_email || '');
    setErrors((prevErrors) =>
      Object.keys(prevErrors).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
  }, [initState]);

  const toggleDrawer = useCallback((inOpen) => {
    setOpen(inOpen);
    if (inOpen) {
      setTimeout(() => closeButtonRef.current?.focus(), 100);
    } else {
      addButtonRef.current?.focus();
      setTimeout(() => resetForm(), 100);
    }
  }, [resetForm]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        toggleDrawer(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [toggleDrawer]);

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
    if (errors.orgName) {
      setErrors(prev => ({ ...prev, orgName: false }));
    }
  };

  const handleOrgContactChange = (e) => {
    setOrgContact(e.target.value);
    if (errors.orgContact) {
      setErrors(prev => ({ ...prev, orgContact: false }));
    }
  };

  const handleOrgEmailChange = (e) => {
    setOrgEmail(e.target.value);
    if (errors.orgEmail) {
      setErrors(prev => ({ ...prev, orgEmail: false }));
    }
  };

  const handleSave = async () => {
    const newErrors = {
      orgName: !orgName.trim(),
      orgContact: !orgContact.trim(),
      orgEmail: !orgEmail.trim(),
    };

    setErrors(newErrors);

    // If there are any errors, stop the submission
    if (newErrors.orgName || newErrors.orgContact || newErrors.orgEmail) {
      return;
    }

    const orgData = {
      orgName,
      orgContact,
      orgEmail: orgEmail.toLowerCase()
    };
  
    const formData = new FormData();
    formData.append('orgData', JSON.stringify(orgData));
  
    try {
      let response;
      if(initState) { // Update
        response = await axios.patch(`/api/organizations/${initState.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else { // Create
        response = await axios.post('/api/create_org', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      
      if (response.status === 200) {
        setMessage(initState ? 'Organization updated successfully!' : 'Organization created successfully!');
        setIsError(false);
        console.log('Server response:', response);
      }
      
    } catch (error) {
      setMessage(initState ? ('Error updating organization: ' + error) : ('Error creating organization: ' + error) );
      setIsError(true);
      console.error('Error details:', error);
    } finally {
      getOrgs();
      toggleDrawer(false)
    }
  };

  return (
    <Box>
      {initState ? (
        <IconButton 
          variant="plain" 
          onClick={() => toggleDrawer(true)}
          aria-label={`Edit Action`}
        >
          <CreateIcon />
        </IconButton>
      ) : (
        <Button 
          variant="soft" 
          color="neutral" 
          sx={{mt:1}} 
          startDecorator={<AddIcon />}
          onClick={() => toggleDrawer(true)}
          ref={addButtonRef}
          aria-haspopup="true"
          aria-expanded={open}
          aria-label="Open create organization form"
        >
          {"Add Organization"}
        </Button>
      )}
      <Drawer open={open}
        anchor="right"
        variant="plain"
        aria-labelledby="drawer-title"
        onClose={() => toggleDrawer(false)}
      >
        <Box sx={{ padding:'2rem' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              id="drawer-title"
              level="component-md"
              color="primary"
            >
              Create Organization
            </Typography>
            <Button
              variant="plain"
              className="close-btn"
              onClick={() => toggleDrawer(false)}
              color="primary"
              ref={closeButtonRef}
              aria-label="Close create organization form"
            >
              <CloseIcon/>
            </Button>
          </Stack>
          <Stack direction="column" spacing={3}>
            <FormControl error={errors.orgName}>
              <FormLabel>Institution Name</FormLabel>
              <Input 
                variant="outlined"
                color=
                  {errors.orgName ? "danger" : "primary"} 
                required
                value={orgName}
                onChange={handleOrgNameChange}
              />
              <FormHelperText>
                {errors.orgName ? "Institution name is required" : "" }
              </FormHelperText>
            </FormControl>
            <FormControl error={errors.orgContact}>
              <FormLabel>Contact Name</FormLabel>
              <Input 
                variant="outlined"
                color={errors.orgContact ? "danger" : "primary"} 
                required 
                value={orgContact}
                onChange={handleOrgContactChange}
              />
              <FormHelperText>
                {errors.orgContact ? "Contact name is required" : "" }
              </FormHelperText>
            </FormControl>
            <FormControl error={errors.orgEmail}>
              <FormLabel>Contact Email</FormLabel>
              <Input 
                variant="outlined"
                color={errors.orgEmail ? "danger" : "primary"} 
                required 
                value={orgEmail} 
                onChange={handleOrgEmailChange}
              />
              <FormHelperText>
                {errors.orgEmail ? "Contact email is required" : ""}
              </FormHelperText>
            </FormControl>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button onClick={handleSave}>Save</Button>
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
}
