import React, { useState } from 'react';
import {
  Card,
  Typography,
  Box,
  FormControl,
  Select,
  Option,
  Stack,
  Textarea,
  FormLabel,
  Tooltip,
  Checkbox
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WorkflowConfig from '../../config/workflowConfig';
import InputWithTooltip from './InputWithTooltip';

const FormLabelWithTooltip = ({ label, tooltip }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <FormLabel sx={{ mb: 0 }}>{label}</FormLabel>
    <Tooltip
      title={<div style={{ whiteSpace: 'pre-line' }}>{tooltip}</div>}
      variant="soft"
      placement="top-start"
    >
      <InfoIcon fontSize="sm" />
    </Tooltip>
  </Box>
);

export default function WorkflowConfigForm({ workflow = new WorkflowConfig(), index, setWorkflows, models }) {
  const [expandedSections, setExpandedSections] = useState({
    preprocess: true,
    retrieval: true,
    response: true
  });

  // Function to toggle section expansion
  const toggleSection = (sectionName) => {
    setExpandedSections({
      ...expandedSections,
      [sectionName]: !expandedSections[sectionName]
    });
  };

  return (
    <Card variant="outlined">
      <Typography level="h2">Workflow {index + 1} Configuration</Typography>

      {/* Step 1: Preprocessing (Metadata) */}
      <Card variant="soft">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:hover': { bgcolor: 'background.level1' },
            p: 1,
            borderRadius: 'sm'
          }}
          onClick={() => toggleSection('preprocess')}
        >
          <Typography level="h3">Step 1: Preprocessing (Metadata)</Typography>
          {expandedSections?.preprocess ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>

        {expandedSections?.preprocess && (
          <FormControl>
            <FormLabelWithTooltip label="Model Selection" tooltip="Select the model to use for the preprocessing step." />
            <Select
              value={workflow.preprocess.model}
              onChange={(_, newValue) => {
                setWorkflows(prev => {
                  const newWorkflows = [...prev];
                  newWorkflows[index].preprocess.model = newValue;
                  return newWorkflows;
                });
              }}
            >
              {models.map((model) => (
                <Option key={model.id} value={model.id}>
                  {model.id}
                </Option>
              ))}
            </Select>
          </FormControl>
        )}

        <Stack direction="row" spacing={2}>
          {[
            {
              label: "Max Tokens",
              tooltip: "Maximum number of tokens to generate in the response",
              property: "max_tokens",
              type: "number",
              min: 1,
              max: 100000,
              step: 1000
            },
            workflow.preprocess.model !== "o3-mini" ? {
              label: "Temperature",
              tooltip: "Controls randomness (0.0 = deterministic, 1.0 = creative)",
              property: "temperature",
              type: "number",
              min: 0,
              max: 1,
              step: 0.1
            } : null,
            workflow.preprocess.model === "o3-mini" ? {
              label: "Reasoning Effort",
              tooltip: "Controls the amount of reasoning effort the model applies",
              property: "reasoning_effort",
              type: "select",
              options: [
                { value: "low", label: "Low" },
                { value: "medium", label: "Medium" },
                { value: "high", label: "High" }
              ]
            } : null,
            {
              label: "Followup Questions",
              tooltip: "The number of prior questions and answers to use to generate followup questions",
              property: "followup_questions",
              type: "number",
              min: 0,
              max: 10,
              step: 1
            }
          ].filter(Boolean).map((param, i) => (
            param.type === "select" ? (
              <FormControl key={i}>
                <FormLabelWithTooltip label={param.label} tooltip={param.tooltip} />
                <Select
                  value={workflow.preprocess[param.property]}
                  onChange={(_, newValue) => {
                    setWorkflows(prev => {
                      const newWorkflows = [...prev];
                      newWorkflows[index].preprocess[param.property] = newValue;
                      return newWorkflows;
                    });
                  }}
                >
                  {param.options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <InputWithTooltip
                key={i}
                label={param.label}
                tooltip={param.tooltip}
                value={workflow.preprocess[param.property]}
                onChange={(e) => {
                  setWorkflows(prev => {
                    const newWorkflows = [...prev];
                    newWorkflows[index].preprocess[param.property] = e.target.value;
                    return newWorkflows;
                  });
                }}
                min={param.min}
                max={param.max}
                step={param.step}
              />
            )))}
        </Stack>
      </Card>

      {/* Step 2: Context Retrieval */}
      <Card variant="soft">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:hover': { bgcolor: 'background.level1' },
            p: 1,
            borderRadius: 'sm'
          }}
          onClick={() => toggleSection('retrieval')}
        >
          <Typography level="h3">Step 2: Context Retrieval</Typography>
          {expandedSections?.retrieval ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>

        {expandedSections?.retrieval && (
          <>
            <FormControl>
              <FormLabelWithTooltip label="Context Retrieval Type" tooltip="Select the type of context retrieval to use." />
              <Select
                value={workflow.retrieval.type}
                onChange={(_, newValue) => {
                  setWorkflows(prev => {
                    const newWorkflows = [...prev];
                    newWorkflows[index].retrieval.type = newValue;
                    return newWorkflows;
                  });
                }}
              >
                <Option value="full_document">Full Document</Option>
                <Option value="chunks">Chunks</Option>
              </Select>
            </FormControl>

            {workflow.retrieval.type === 'full_document' && (
              <Stack direction="row" spacing={2}>
                {[
                  {
                    label: "Max Tokens",
                    tooltip: "The maximum number of tokens to be used for context retrieval.",
                    property: "max_tokens_full_docs",
                    min: 1, max: 100000, step: 1000
                  },
                  {
                    label: "Window Size",
                    tooltip: "The maximum number of tokens that will be taken from a single document centered around the most relevant chunk.",
                    property: "window_size_full_docs",
                    min: 1, max: 40000, step: 1000
                  },
                  {
                    label: "TF-IDF Cutoff",
                    tooltip: "Used to filter out low quality questions when no relevant chunks exceed the cutoff.",
                    property: "tfidf_min_cutoff",
                    min: 0, max: 1, step: 0.1
                  }
                ].map((input, i) => (
                  <InputWithTooltip
                    key={i}
                    label={input.label}
                    tooltip={input.tooltip}
                    value={workflow.retrieval[input.property]}
                    onChange={(e) => {
                      setWorkflows(prev => {
                        const newWorkflows = [...prev];
                        newWorkflows[index].retrieval[input.property] = e.target.value;
                        return newWorkflows;
                      });
                    }}
                    min={input.min}
                    max={input.max}
                    step={input.step}
                  />
                ))}
              </Stack>
            )}

            {workflow.retrieval.type === 'chunks' && (
              <>
                {[
                  // First row
                  [
                    {
                      label: "Cosine Chunks", property: "cosine_chunks_to_get", min: 1, max: 100, step: 1,
                      tooltip: "The number of chunks to retrieve using cosine similarity."
                    },
                    {
                      label: "TF-IDF Chunks", property: "tfidf_chunks_to_get", min: 1, max: 100, step: 1,
                      tooltip: "The number of chunks to retrieve using TF-IDF similarity."
                    },
                    {
                      label: "Rerank Chunks", property: "rerank_chunks_to_get", min: 1, max: 100, step: 1,
                      tooltip: "The number of chunks to retrieve using reranking."
                    }
                  ],
                  // Second row
                  [
                    {
                      label: "TF-IDF Cutoff", property: "tfidf_min_cutoff", min: 0, max: 1, step: 0.1,
                      tooltip: "Used to filter out low quality questions when no relevant chunks exceed the cutoff."
                    },
                    {
                      label: "Rerank Cutoff", property: "rerank_min_cutoff", min: 0, max: 1, step: 0.1,
                      tooltip: "Used to filter out low quality questions when no relevant chunks exceed the cutoff."
                    }
                  ]
                ].map((row, rowIndex) => (
                  <Stack key={rowIndex} direction="row" spacing={2}>
                    {row.map((input, i) => (
                      <InputWithTooltip
                        key={i}
                        label={input.label}
                        tooltip={input.tooltip}
                        value={workflow.retrieval[input.property]}
                        onChange={(e) => {
                          setWorkflows(prev => {
                            const newWorkflows = [...prev];
                            newWorkflows[index].retrieval[input.property] = e.target.value;
                            return newWorkflows;
                          });
                        }}
                        min={input.min}
                        max={input.max}
                        step={input.step}
                      />
                    ))}
                  </Stack>
                ))}
              </>
            )}
                        
            <FormControl>
              <FormLabelWithTooltip 
                label="Context Format" 
                tooltip={`Changes how the context is formatted:\n\nText format:\nTitle: document_title\nText: document_content\n\nXML format:\n<document id=1>\n  <source>document_title</source>\n  <document_text>document_content</document_text>\n</document>`} 
              />
              <Select
                value={workflow.retrieval.output_format || 'text'}
                onChange={(_, newValue) => {
                  setWorkflows(prev => {
                    const newWorkflows = [...prev];
                    newWorkflows[index].retrieval.output_format = newValue;
                    return newWorkflows;
                  });
                }}
              >
                <Option value="text">Text</Option>
                <Option value="xml">XML</Option>
              </Select>
            </FormControl>

          </>
        )}
      </Card>

      {/* Step 3: Response Generation */}
      <Card variant="soft">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '&:hover': { bgcolor: 'background.level1' },
            p: 1,
            borderRadius: 'sm'
          }}
          onClick={() => toggleSection('response')}
        >
          <Typography level="h3">Step 3: Response Generation</Typography>
          {expandedSections?.response ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>

        {expandedSections?.response && (
          <>
            <FormControl sx={{ mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Checkbox
                  checked={workflow.response.use_homework_filter || false}
                  onChange={(event) => {
                    setWorkflows(prev => {
                      const newWorkflows = [...prev];
                      newWorkflows[index].response.use_homework_filter = event.target.checked;
                      return newWorkflows;
                    });
                  }}
                />
                <FormLabelWithTooltip 
                  label="Use Homework Filter" 
                  tooltip="Enable to filter out responses that may contain homework answers" 
                />
              </Box>
            </FormControl>

            <FormControl>
              <FormLabelWithTooltip label="Model Selection" tooltip="Select the model to use for the response generation step." />
              <Select
                value={workflow.response.model}
                onChange={(_, newValue) => {
                  setWorkflows(prev => {
                    const newWorkflows = [...prev];
                    newWorkflows[index].response.model = newValue;
                    return newWorkflows;
                  });
                }}
              >
                {models.map((model) => (
                  <Option key={model.id} value={model.id}>
                    {model.id}
                  </Option>
                ))}
              </Select>
            </FormControl>

            <Stack direction="row" spacing={2}>
              {[
                {
                  label: "Max Tokens",
                  tooltip: "Maximum number of tokens to generate in the response",
                  property: "max_tokens",
                  type: "number",
                  min: 1,
                  max: 100000,
                  step: 1000
                },
                workflow.response.model !== "o3-mini" ? {
                  label: "Temperature",
                  tooltip: "Controls randomness (0.0 = deterministic, 1.0 = creative)",
                  property: "temperature",
                  type: "number",
                  min: 0,
                  max: 1,
                  step: 0.1
                } : null,
                workflow.response.model === "o3-mini" ? {
                  label: "Reasoning Effort",
                  tooltip: "Controls the amount of reasoning effort the model applies (0.0 = minimal, 1.0 = maximum)",
                  property: "reasoning_effort",
                  type: "select",
                  options: [
                    { value: "low", label: "Low" },
                    { value: "medium", label: "Medium" },
                    { value: "high", label: "High" }
                  ]
                } : null
              ].filter(Boolean).map((param, i) => (
                param.type === "select" ? (
                  <FormControl key={i}>
                    <FormLabelWithTooltip label={param.label} tooltip={param.tooltip} />
                    <Select
                      value={workflow.response[param.property]}
                      onChange={(_, newValue) => {
                        setWorkflows(prev => {
                          const newWorkflows = [...prev];
                          newWorkflows[index].response[param.property] = newValue;
                          return newWorkflows;
                        });
                      }}
                    >
                      {param.options.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <InputWithTooltip
                    key={i}
                    label={param.label}
                    tooltip={param.tooltip}
                    value={workflow.response[param.property]}
                    onChange={(e) => {
                      setWorkflows(prev => {
                        const newWorkflows = [...prev];
                        newWorkflows[index].response[param.property] = e.target.value;
                        return newWorkflows;
                      });
                    }}
                    min={param.min}
                    max={param.max}
                    step={param.step}
                  />
                )))}
            </Stack>

            <FormControl>
              <FormLabelWithTooltip
                label="Instructions"
                tooltip="System instructions/prompt for the LLM"
              />
              <Textarea
                minRows={5}
                value={workflow.response.instructions || ''}
                onChange={(e) => {
                  setWorkflows(prev => {
                    const newWorkflows = [...prev];
                    newWorkflows[index].response.instructions = e.target.value;
                    return newWorkflows;
                  });
                }}
                placeholder="Enter custom instructions for the LLM..."
                sx={{
                  fontFamily: 'code',
                  fontSize: '0.9em',
                  lineHeight: 1.5,
                  backgroundColor: 'background.level1'
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabelWithTooltip label="Output Format" tooltip="Instructions to define the output format of the response to be generated." />
              <Textarea
                minRows={5}
                value={workflow.response.output_format || ''}
                onChange={(e) => {
                  setWorkflows(prev => {
                    const newWorkflows = [...prev];
                    newWorkflows[index].response.output_format = e.target.value;
                    return newWorkflows;
                  });
                }}
                placeholder="Enter custom output format for the LLM..."
                sx={{
                  fontFamily: 'code',
                  fontSize: '0.9em',
                  lineHeight: 1.5,
                  backgroundColor: 'background.level1'
                }}
              />
            </FormControl>
            
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControl>
                <FormLabelWithTooltip label="Response Style" tooltip="The style of the response to be generated." />
                <Select
                  value={workflow.response.style}
                  onChange={(_, newValue) => {
                    setWorkflows(prev => {
                      const newWorkflows = [...prev];
                      newWorkflows[index].response.style = newValue;
                      return newWorkflows;
                    });
                  }}
                >
                  <Option value="Json">JSON</Option>
                  <Option value="Text">Text</Option>
                </Select>
              </FormControl>
              <FormControl sx={{ mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Checkbox
                    checked={workflow.response.use_structured_output || false}
                    disabled={workflow.response.style !== "Json"}
                    onChange={(event) => {
                      setWorkflows(prev => {
                        const newWorkflows = [...prev];
                        newWorkflows[index].response.use_structured_output = event.target.checked;
                        return newWorkflows;
                      });
                    }}
                  />
                  <FormLabelWithTooltip 
                    label="Use Structured Output" 
                    tooltip=" This moves the JSON schema out of the instructions and into the response format." 
                  />
                </Box>
              </FormControl>
            </Stack>
          </>
        )}
      </Card>
    </Card>
  );
}
