import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Chip,
  List,
  ListItem
} from '@mui/joy';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import LatexRenderer from '../LatexRenderer';
import TextRenderer from '../TextRenderer';
import FootnoteButton from '../FootnoteButton';

// Component to render individual workflow result
const WorkflowResult = ({ workflowResult, index }) => {
  const [activeFootnote, setActiveFootnote] = useState(null);
  const [showFootnotes, setShowFootnotes] = useState(false);
  const footnotesRef = useRef({});

  // Safe extraction of data from workflowResult
  const hasError = !!workflowResult?.error;
  const errorMessage = workflowResult?.error || "";
  const answer = workflowResult?.response?.output?.answer || "";
  const footnotes = workflowResult?.response?.output?.footnotes || [];
  const hasFootnotes = footnotes && footnotes.length > 0;
  
  const preprocessOutput = workflowResult?.preprocess?.output || "";
  const preprocessMetrics = workflowResult?.preprocess?.metrics;
  const retrievalMetrics = workflowResult?.retrieval?.metrics;
  const responseMetrics = workflowResult?.response?.metrics;
  const retrievalContext = workflowResult?.retrieval?.context;
  const rawResponse = workflowResult?.response?.output || {};
  
  // Extract debug information for HomeworkResponseGenerator
  const debugInfo = workflowResult?.response?.output?.debug || null;
  const hasDebugInfo = !!debugInfo;
  const initialQueryAnswer = debugInfo?.initial_query?.answer || null;
  const revisionQueryAnswer = debugInfo?.revision_query?.answer || null;
  const revisionApplied = debugInfo?.revision_applied || false;
  const hasRevisionQuery = !!revisionQueryAnswer;

  const scrollToFootnote = useCallback((footnoteName) => {
    if (footnotesRef.current[footnoteName]) {
      footnotesRef.current[footnoteName].scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center' 
      });
      footnotesRef.current[footnoteName].focus();
    }
  }, []);

  // Enhanced render function for rich text formatting
  const renderAnswer = useCallback(() => {
    if (!answer) return <Typography level="body-md">No answer provided</Typography>;

    // Split the answer into code blocks and non-code blocks
    const blocks = answer.split(/(```[\s\S]*?```)/);

    return blocks.map((block, blockIndex) => {
      if (block.startsWith('```') && block.endsWith('```')) {
        // This is a code block
        const code = block.slice(3, -3).trim();
        const language = code.split('\n')[0].trim();
        const codeContent = code.split('\n').slice(1).join('\n');
        
        return (
          <SyntaxHighlighter 
            language={language || 'text'} 
            style={a11yLight} 
            key={`code-${blockIndex}`}
          >
            {codeContent}
          </SyntaxHighlighter>
        );
      } else {
        // Split block into paragraphs and potential lists
        const lines = block.split('\n');
        let elements = [];
        let currentList = [];
        let currentListType = null; // 'ordered' or 'unordered'
        let orderedListCounter = 1;

        const processCurrentList = () => {
          if (currentList.length > 0) {
            const listItems = currentList.map((item, idx) => {
              const listNumber = currentListType === 'ordered' ? 
                (orderedListCounter - currentList.length + idx) : 
                null;
                
              const parts = item
                .replace(/^\d+\.\s*|\-\s*|\*\s*/, '')
                .split(/(\[\d+\])/);

              return (
                <ListItem key={idx} sx={{ counterReset: listNumber ? `list-item ${listNumber - 1}` : 'none' }}>
                  {parts.map((part, partIndex) => {
                    if (part.match(/\[\d+\]/)) {
                      if (hasFootnotes) {
                        const footnoteName = part.slice(1, -1);
                        return (
                          <FootnoteButton
                            key={`footnote-${partIndex}`}
                            footnoteName={footnoteName}
                            setShowFootnotes={setShowFootnotes}
                            setActiveFootnote={setActiveFootnote}
                            scrollToFootnote={scrollToFootnote}
                          />
                        );
                      }
                      return '';
                    }
                    return part.match(/\\\(.*?\\\)|\\\[.*?\\\]/) ?
                      <LatexRenderer key={`latex-${partIndex}`} text={part} /> : 
                      <TextRenderer key={`text-${partIndex}`} text={part} />;
                  })}
                </ListItem>
              );
            });

            elements.push(
              <List 
                key={`list-${elements.length}`}
                marker={currentListType === 'ordered' ? 'decimal' : 'disc'}
                size='lg'
                sx={{ 
                  paddingLeft: 2,
                  marginBottom: 2,
                  '& .MuiListItem-root': {
                    paddingY: 0.5,
                  },
                  listStyleType: currentListType === 'ordered' ? 'decimal' : 'disc',
                  counterReset: currentListType === 'ordered' ? `list-item ${orderedListCounter - currentList.length}` : 'none'
                }}
              >
                {listItems}
              </List>
            );

            if (currentListType === 'ordered') {
              orderedListCounter += currentList.length;
            }

            currentList = [];
            currentListType = null;
          }
        };

        lines.forEach((line, lineIndex) => {
          const trimmedLine = line.trim();
          
          // Skip empty lines
          if (!trimmedLine) {
            processCurrentList();
            return;
          }

          // Check for list items
          const orderedListMatch = trimmedLine.match(/^\d+\.\s/);
          const unorderedListMatch = trimmedLine.match(/^[\-\*]\s/);

          if (orderedListMatch || unorderedListMatch) {
            const newListType = orderedListMatch ? 'ordered' : 'unordered';
            
            // If we're starting a new type of list, process the old one
            if (currentListType && currentListType !== newListType) {
              processCurrentList();
            }
            
            currentListType = newListType;
            currentList.push(trimmedLine);
          } else {
            // Not a list item, process any existing list
            processCurrentList();
            
            // Check if this paragraph is the unwanted footnotes section
            if (trimmedLine.startsWith('Footnotes:')) return;

            // Process regular paragraph
            const parts = trimmedLine.split(/(\[\d+\])/);
            elements.push(
              <Typography level="body-lg" key={`para-${elements.length}`}>
                {parts.map((part, partIndex) => {
                  if (part.match(/\[\d+\]/)) {
                    if (hasFootnotes) {
                      const footnoteName = part.slice(1, -1);
                      return (
                        <FootnoteButton
                          key={`footnote-${partIndex}`}
                          footnoteName={footnoteName}
                          setShowFootnotes={setShowFootnotes}
                          setActiveFootnote={setActiveFootnote}
                          scrollToFootnote={scrollToFootnote}
                        />
                      );
                    }
                    return '';
                  }
                  return part.match(/\\\(.*?\\\)|\\\[.*?\\\]/) ?
                    <LatexRenderer key={`latex-${partIndex}`} text={part} /> : 
                    <TextRenderer key={`text-${partIndex}`} text={part} />;
                })}
              </Typography>
            );
          }
        });

        // Process any remaining list items
        processCurrentList();

        return elements;
      }
    });
  }, [answer, hasFootnotes, setActiveFootnote, scrollToFootnote]);

  // Memoized answer rendering
  const memoizedAnswer = useMemo(() => renderAnswer(), [renderAnswer]);

  const renderFootnotes = () => {
    return footnotes.map((footnote, index) => (
      <Box 
        key={index} 
        sx={{ 
          display: 'flex', 
          backgroundColor: activeFootnote === (index + 1).toString() ? 'var(--joy-palette-neutral-100)' : 'transparent' 

        }}
        ref={el => footnotesRef.current[index + 1] = el}
        tabIndex="-1"
        role="region"
        aria-label={`Footnote ${index + 1}`}
      >
        <Box ml={0.5} mr={2} mt={1}>
          <Typography level="title-md" component="span">{index + 1}</Typography>
        </Box>
        <Box sx={{ overflow: 'auto', wordWrap: 'break-word' }}>
          <Typography pt={1} level="body-sm">
            <LatexRenderer text={footnote} />
          </Typography>
        </Box>
      </Box>
    ));
  };

  // Early return for null workflowResult
  if (!workflowResult) return null;
  
  // Early return for error state
  if (hasError) {
    return (
      <Box sx={{ p: 2, bgcolor: 'error.softBg', borderRadius: 'sm' }}>
        <Typography level="body-sm">Error: {errorMessage}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Typography level="title-md" sx={{ mb: 1 }}>Workflow {index + 1}</Typography>
      
      {/* Response */}
      <Card variant="outlined" sx={{ mb: 2 }}>
        <Typography level="title-md">Preprocessing</Typography>
        {workflowResult?.preprocess?.error && (<Typography level="body-sm">{workflowResult?.preprocess?.error}</Typography>)}
        <Stack>
            {Object.entries(preprocessOutput)
              .filter(([key]) => key !== 'output')
              .map(([key, value], idx) => (
                <Typography level="body-sm" sx={{ mt: 0, fontFamily: 'monospace' }}>
                  {key}: {value}
                </Typography>
              ))}
        </Stack>
        
        {/* Response section with title and status chips in a flex container */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
          <Typography level="title-md">Response</Typography>
          
          {/* Status chips positioned to the right */}
          {hasDebugInfo && (
            <Box>
              {revisionApplied ? (
                <Chip size="sm" color="warning" variant="soft">
                  Applied Revision
                </Chip>
              ) : hasRevisionQuery ? (
                <Chip size="sm" color="success" variant="soft">
                  No Revision Needed
                </Chip>
              ) : initialQueryAnswer?.startsWith("Forbidden") ? (
                <Chip size="sm" color="danger" variant="soft">
                  Question Blocked
                </Chip>
              ) : (
                null
              )}
            </Box>
          )}
        </Box>
        {workflowResult?.retrieval?.error && (<Typography level="body-sm">{workflowResult?.retrieval?.error}</Typography>)}
        {workflowResult?.response?.error && (<Typography level="body-sm">{workflowResult?.response?.error}</Typography>)}
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          {memoizedAnswer}
        </Box>
        
        {/* Footnotes Section */}
        {hasFootnotes && (
          <Accordion 
            sx={{ boxShadow: 'none', border: 'none' }}
            expanded={showFootnotes}
            onChange={(event, expanded) => setShowFootnotes(expanded)}
          >
            <AccordionSummary>
              <Typography level="title-sm">Footnotes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {renderFootnotes()}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Card>
      
      {/* Metrics Accordion */}
      <Accordion defaultExpanded={true}>
        <AccordionSummary>
          <Typography level="title-sm">Metrics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {/* Preprocess Metrics */}
            {preprocessMetrics && (
              <Box>
                <Typography level="body-sm">Preprocess Metrics</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {Object.entries(preprocessMetrics).map(([key, value]) => (
                    <Chip key={key} size="sm">
                      {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
                    </Chip>
                  ))}
                </Box>
              </Box>
            )}
            
            {/* Retrieval Metrics */}
            {retrievalMetrics && (
              <Box>
                <Typography level="body-sm">Retrieval Metrics</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {Object.entries(retrievalMetrics).map(([key, value]) => (
                    <Chip key={key} size="sm">
                      {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
                    </Chip>
                  ))}
                </Box>
              </Box>
            )}
            
            {/* Response Metrics */}
            {responseMetrics && (
              <Box>
                <Typography level="body-sm">Response Metrics</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1}}>
                  {Object.entries(responseMetrics).map(([key, value]) => {
                    // Check if it's a nested metrics object from HomeworkResponseGenerator
                    if (key === 'initial_query' || key === 'revision_query') {
                      return (
                        <Box key={key} sx={{ width: '100%'}}>
                          <Typography level="body-xs">{key === 'initial_query' ? 'Initial Query' : 'Revision Query'}</Typography>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 0.5}}>
                            {Object.entries(value).map(([subKey, subValue]) => (
                              <Chip key={`${key}-${subKey}`} size="sm">
                                {subKey}: {typeof subValue === 'object' ? JSON.stringify(subValue) : subValue}
                              </Chip>
                            ))}
                          </Box>
                        </Box>
                      );
                    }
                    
                    // Regular metrics
                    return (
                      <Chip key={key} size="sm">
                        {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
                      </Chip>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      
      {/* Context Accordion */}
      {retrievalContext && (
        <Accordion>
          <AccordionSummary>
            <Typography level="title-sm">Retrieved Context</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography level="body-sm" sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
              {typeof retrievalContext === 'string' 
                ? retrievalContext 
                : JSON.stringify(retrievalContext, null, 2)
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Preprocess Output Accordion */}
      {preprocessOutput.output && (
        <Accordion>
          <AccordionSummary>
            <Typography level="title-sm">Raw Preprocessing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography level="body-sm" sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
              {preprocessOutput.output}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      
      {/* Raw Response Accordion */}
      <Accordion>
        <AccordionSummary>
          <Typography level="title-sm">Raw Response</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography level="body-sm" sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
            {rawResponse?.debug ? JSON.stringify(rawResponse.debug, null, 2) : JSON.stringify(rawResponse, null, 2)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

// Component to render a question and its workflow results
const QuestionResult = ({ questionResult, index }) => {
  return (
    <Card key={index} variant="outlined" sx={{ mb: 3 }}>
      <Typography level="title-md">Question {index + 1}</Typography>
      <Typography level="body-md" sx={{ fontWeight: 'bold', mb: 2 }}>
        {questionResult.question}
      </Typography>
      
      <Divider />
      
      {/* Display workflows side by side in a grid */}
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {questionResult.workflows.map((workflowResult, workflowIndex) => (
          <React.Fragment key={workflowIndex}>
            <Grid xs={12} md={12 / questionResult.workflows.length}>
              <WorkflowResult workflowResult={workflowResult} index={workflowIndex} />
              {/* Vertical divider for desktop */}
              {workflowIndex < questionResult.workflows.length - 1 && (
                <Divider orientation="vertical" sx={{ 
                  position: 'absolute', 
                  right: 0, 
                  top: 0, 
                  height: '100%',
                  display: { xs: 'none', md: 'block' }
                }} />
              )}
            </Grid>
            
            {/* Horizontal divider for mobile only */}
            {workflowIndex < questionResult.workflows.length - 1 && (
              <Grid xs={12} sx={{ display: { xs: 'block', md: 'none' }, my: 2 }}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Card>
  );
};

// Main component for displaying all results
const WorkflowResults = ({ results }) => {
  if (!results || results.length === 0) {
    return null;
  }

  return (
    <Box>
      {results.map((questionResult, index) => (
        <QuestionResult key={index} questionResult={questionResult} index={index} />
      ))}
    </Box>
  );
};

export default WorkflowResults;
