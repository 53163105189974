import axios from 'axios';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import './Tables.css';

function Row({ row, onResendInvite, removeStudent }) {
  //console.log('Row data:', row);
  return (
    <tr>
      <td>
        <Typography level="body-md">{row.user_email}</Typography>
      </td>
      <td>
          <Stack direction="row" gap={1} justifyContent="flex-end">
            <Button 
              variant="outlined" 
              color="primary" 
              size="sm"
              onClick={() => onResendInvite(row.user_id)}
              aria-label={`Reinvite ${row.user_email}`} 
            >
              Re-invite
            </Button>
            <Button 
              variant="plain" 
              color="primary" 
              size="sm"
              onClick={() => removeStudent(row.user_id)}
              aria-label={`Remove ${row.user_email}`} 
            >
              Remove
            </Button>
          </Stack>
      </td>
    </tr>
  );
}

//This section is used for pagination
function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

//This section handles sorting the table
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy]);
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function TableCollapsibleRow({ courseId, refreshTrigger, onAlert, onInvite, requireEmails }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState({ field: 'email', direction: 'asc' });
  const [loading, setLoading] = React.useState(true);

  const fetchStudents = useCallback(async () => {
    setLoading(true);
    if (!courseId) {
      console.log('No course ID available, skipping fetch');
      return;
    }
    try {
      const response = await axios.get(`/api/course_users/${courseId}`);
      setRows(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
      setRows([]);
    } finally {
      setLoading(false);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      fetchStudents();
    }
  }, [fetchStudents, refreshTrigger, courseId]);

  const handleResendInvite = async (studentId) => {
    try {
      await axios.post(`/api/resend_invite/${studentId}`, {
        courseId: courseId 
      });

      onAlert({
        show: true,
        message: 'Invite resent successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error resending invite:', error);
      onAlert({
        show: true,
        message: 'Failed to resend invite: ' + (error.response?.data?.errors?.error || error.message),
        severity: 'danger'
      });
    }
  };

  const handleRemoveStudent = async (studentId) => {
    try {
        const response = await axios.post('/api/user_course_association', {
            user_id: studentId,
            course_id: courseId,
            operation: 'remove'
        });

        if (response.status === 200) {
          // Refresh the table data
          fetchStudents();

          onAlert({
            show: true,
            message: 'Student removed successfully',
            severity: 'success'
          });
        }
    } catch (error) {
        console.error('Error removing student:', error);
        onAlert({
          show: true,
          message: 'Failed to remove student: ' + (error.response?.data?.error || error.message),
          severity: 'danger'
        });
    }
  };

  //This section is used for pagination
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Sheet variant="plain" sx={{marginBottom:'2rem'}}>
      {/* Table Sort and Pagination */} 
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={2} className="table-toolbar">
        <FormControl>
          <FormLabel>Sort by:</FormLabel>
          <Stack direction="row">
            <Select
              value={`${sortOrder.field}-${sortOrder.direction}`}
              disabled={!requireEmails}
              onChange={(_, newValue) => {
                const [field, direction] = newValue.split('-');
                setSortOrder({ field, direction });
              }}
              sx={{ minWidth: 200 }}
              variant='plain'
            >
              <Option value="email-asc">Alphabetically A-Z</Option>
              <Option value="email-desc">Alphabetically Z-A</Option>
              {/* TODO: Add more sorting options as needed */}
            </Select>
            <Button
              variant="outlined"
              color="primary"
              onClick={onInvite}
              disabled={!requireEmails}
            >
              Invite Students
            </Button>
          </Stack>
        </FormControl>
        { /* Pagination Component */}
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl orientation="horizontal" size="md">
            <FormLabel>Rows/Page:</FormLabel>
            <Select onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage} disabled={!requireEmails}>
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
            </Select>
          </FormControl>
          <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium'}}>
            {labelDisplayedRows({
              from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
              to: getLabelDisplayedRowsTo(),
              count: rows.length === -1 ? -1 : rows.length,
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Previous Page"
              disabled={page === 0  || !requireEmails}
              onClick={() => handleChangePage(page - 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Next Page"
              disabled={
                (rows.length !== -1 
                  ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                  : false) || !requireEmails
              }
              onClick={() => handleChangePage(page + 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Stack>
        { /* End Pagination Component */}
      </Stack>
      <Divider />
      {/* Table Body Rows */}
      {requireEmails ? (
        rows.length === 0 ? (
          <Stack direction="row" alignItems="flex-end" justifyContent="space-around">
            <Button
              variant="plain"
              color="primary"
              onClick={onInvite}
              disabled={!requireEmails}
              sx={{marginTop: "2rem"}}
            >
              Invite Your First Student
            </Button>
          </Stack>
        ) : (
          <Table
            hoverRow
            size="md"
            sx={{
              boxSizing: 'border-box',
              "& tr > td:last-of-type": { textAlign: "right", width: "50%", },
              "& tr td:not(:last-of-type)": { width: "50%", textAlign: "left", overflow: "hidden" },
              minwidth: 320,
              overflow: 'auto'
            }}
          >
            <tbody>
            {stableSort(rows, getComparator(sortOrder.direction, sortOrder.field))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                  <Row key={row.user_id} row={row} courseId={courseId} onResendInvite={handleResendInvite} removeStudent={handleRemoveStudent}/>
              ))}
              {emptyRows > 0 && (
                <tr
                  style={{
                    height: `calc(${emptyRows} * 40px)`,
                    "--TableRow-hoverBackground": "transparent",
                  }}
                >
                  <td colSpan={4} aria-hidden />
                </tr>
              )}
            </tbody>
          </Table>
        )
      ) : (
        <Stack direction="row" alignItems="flex-end" justifyContent="space-around">
          <Typography mt="5rem" width="30rem" level="body-lg" sx={{textAlign: "center", fontWeight: 'bold'}}>
            To invite students and require that they enter their email to access your course's All Day TA, check the checkbox for "Require student emails"
          </Typography>
        </Stack>
      )}
    </Sheet>
  );
}