import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
  Autocomplete,
  AutocompleteOption
} from '@mui/joy';
import { 
  STATUS, 
  // isProcessingStatus, 
  // isSuccessStatus, 
  // hasError, 
  canPerformActions 
} from './fileStatusUtils';
import GlobalSnackbar from '../GlobalSnackbar';
import '../Modals.css';

export default function MoveFilesToModuleModal({ open, onClose, modules = [], selected = [], setFiles }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'neutral',
  });
  const [selectedModule, setSelectedModule] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setSelectedModule(null);
      setIsLoading(false);
    }
  }, [open]);

  const handleClose = () => {
    setSelectedModule(null);
    onClose();
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault(); // Prevent default form submission
    
    try {
      setIsLoading(true);
      await handleMoveFilesToModule(selectedModule?.id);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: 'An error occurred while moving files. Please try again.',
        color: 'danger',
      });
      console.error('Error moving files to module:', error);
    }
  };

  // Format file count text
  const fileCountText = () => {
    const count = Array.isArray(selected) ? selected.length : 0;
    return `${count} file${count !== 1 ? 's' : ''}`;
  };

  const handleMoveFilesToModule = async (module_id) => {
    if (selected.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select files to move to module.",
        color: 'danger',
      });
      return;
    }

    // Only allow files that don't have another action taking place
    const eligibleFiles = selected.filter(file => canPerformActions(file));

    if (eligibleFiles.length === 0) {
      setSnackbar({
        open: true,
        message: "None of the selected files can be modified at this time.",
        color: 'danger',
      });
      return;
    }

    // Update UI immediately to show processing state
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        eligibleFiles.some(selectedFile => selectedFile.file_id === file.file_id)
          ? { ...file, status: STATUS.UPDATING }
          : file
      )
    );

    try {
      if (module_id) {
        // Move files to module
        await axios.post(`/api/modules/${module_id}/files`, {
          file_ids: eligibleFiles.map(file => file.file_id)
        });
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            eligibleFiles.some(selectedFile => selectedFile.file_id === file.file_id)
              ? { ...file, status: STATUS.SUCCESS, module: modules.find(m => m.id === module_id)  }
              : file
          )
        );
        setSnackbar({
          open: true,
          message: "Files moved to module successfully.",
          color: 'success',
        });
      } else {
        // Remove files from module
        await axios.delete(`/api/modules/files`, {
          data: { file_ids: eligibleFiles.map(file => file.file_id) },
        });
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            eligibleFiles.some(selectedFile => selectedFile.file_id === file.file_id)
              ? { ...file, status: STATUS.SUCCESS, module: null  }
              : file
          )
        );
        setSnackbar({
          open: true,
          message: "Files removed from module successfully.",
          color: 'success',
        });
      }
    } catch (error) {
      console.error('Error moving files to module:', error);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          eligibleFiles.some(selectedFile => selectedFile.file_id === file.file_id)
            ? { ...file, status: "Error moving files to module" }
            : file
        )
      );
      setSnackbar({
        open: true,
        message: "Files could not be moved to module. Please try again.",
        color: 'danger',
      });
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog
          variant="outlined"
          size="md"
          className="modal-container-mobile-fullscreen"
          sx={{
            maxWidth: '50vw',
            width: '100%',
          }}
        >
          <DialogTitle>Move Files to Module</DialogTitle>
          <ModalClose
            aria-label="Close Dialog"
          />
          <DialogContent>
            <form onSubmit={handleSubmit} aria-label="Move files to module form">
              <Stack spacing={3}>
                <Box>
                  <Typography>
                    Moving {fileCountText()} to a module
                  </Typography>
                </Box>

                {modules.length === 0 ? (
                  <Alert 
                    color="neutral" 
                    variant="soft" 
                    aria-live="polite"
                    role="alert"
                  >
                    No modules available. Please create a module first.
                  </Alert>
                ) : (
                  <FormControl>
                    <FormLabel>Select Module</FormLabel>
                    <Autocomplete
                      placeholder="Choose a module"
                      color="primary"
                      options={modules}
                      getOptionLabel={(option) => option.title}
                      value={selectedModule}
                      onChange={(e, newValue) => setSelectedModule(newValue)}
                      disabled={modules.length === 0}
                      sx={{ width: '100%' }}
                      renderOption={(props, option) => {
                          const { key, ...restProps } = props; // Extract and exclude the key from props
                          return (
                            <AutocompleteOption key={key} {...restProps}>
                              {option.title}
                            </AutocompleteOption>
                          );
                        }}
                    />
                  </FormControl>
                )}
              </Stack>
            </form>
          </DialogContent>
          <DialogActions className="dialog-actions-right">
            <Button
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              color="primary"
              onClick={handleSubmit}
              loading={isLoading}
              disabled={modules.length === 0}
            >
              Move Files
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <GlobalSnackbar 
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </>
  );
}
