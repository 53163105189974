import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Textarea,
} from '@mui/joy';
import GlobalSnackbar from '../GlobalSnackbar';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import '../Modals.css';

export default function CreateModuleModal({ open, onClose, existingModules = [], setModules, onSuccess }) {
  const [moduleList, setModuleList] = useState([]);
  const [newModule, setNewModule] = useState('');
  const [moduleError, setModuleError] = useState('');
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    color: 'danger',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'success',
  });
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when modal is opened
  useEffect(() => {
    if (open) {
      setModuleList([]);
      setNewModule('');
      setModuleError('');
      setAlert({
        show: false,
        message: '',
        color: 'danger',
      });
      setIsLoading(false);
    }
  }, [open]);

  const closeWithReset = () => {
    setModuleList([]);
    setNewModule('');
    setModuleError('');
    setAlert({
      show: false,
      message: '',
      color: 'danger',
    });
    onClose();
  };

  // Process multiple module names from text input
  const processModuleNames = (moduleInput) => {
    // Convert input to array by splitting on newlines or commas
    const moduleArray = Array.isArray(moduleInput) 
      ? moduleInput 
      : moduleInput.split(/[\n,]+/)
          .map(module => module.trim())
          .filter(module => module.length > 0);

    // Create Sets to easily check for duplicates
    const currentModulesSet = new Set(moduleList);
    
    // Extract names from existing modules for comparison
    const existingModuleTitles = new Set(
      Array.isArray(existingModules) 
        ? existingModules.map(m => typeof m === 'object' ? m.title : m) 
        : []
    );

    // Categorize module names
    const duplicateModules = [];
    let validModules = [];
    for (const module of moduleArray) {
      if (currentModulesSet.has(module) || existingModuleTitles.has(module)) {
        duplicateModules.push(module);
      }
      else {
        validModules.push(module);
      }
    }

    // Check for duplicates within the new modules
    const internalDuplicates = validModules.filter((module, index, self) => 
      self.indexOf(module) !== index
    );

    // Combine both types of duplicates
    const allDuplicates = [...new Set([...duplicateModules, ...internalDuplicates])];

    // Construct error messages
    let errorMessages = [];
    if (allDuplicates.length > 0) {
      errorMessages.push(`Duplicate module name${allDuplicates.length > 1 ? 's' : ''}: ${allDuplicates.join(', ')}`);
    }
    validModules = [...new Set(validModules)];

    return {
      validModules: validModules,
      errorMessage: errorMessages.join('. '),
      hasValidModules: validModules.length > 0
    };
  };

  // Add module from text input
  const handleAddModule = () => {
    if (!newModule.trim()) {
      setModuleError('Please enter at least one module name');
      return;
    }

    const { validModules, errorMessage, hasValidModules } = processModuleNames(newModule);

    if (hasValidModules) {
      setModuleList([...moduleList, ...validModules]);
      setNewModule('');
      setAlert({
        show: true,
        message: `Added ${validModules.length} module${validModules.length > 1 ? 's' : ''}`,
        color: 'success',
      });
    }

    setModuleError(errorMessage);
    if (!errorMessage && !hasValidModules) {
      setAlert({
        show: true,
        message: 'No valid module names to add',
        color: 'warning',
      });
    }
  };

  // Remove a module from the list
  const handleRemoveModule = (module) => {
    setModuleList(moduleList.filter(m => m !== module));
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault(); // Prevent default form submission
    await handleSave();
  };

  // Validate and save modules
  const handleSave = async () => {
    setAlert({
      show: false,
      message: '',
      color: 'danger',
    });

    if (moduleList.length === 0) {
      setAlert({
        show: true,
        message: 'Please add at least one module before saving',
        color: 'danger',
      });
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post('/api/modules', {
        modules: moduleList
      });
      // Map the response to the expected format so table can display the module name
      const createdModules = response.data.created.map(m => ({
        id: m.id,
        title: m.name,
      }));
      setModules([...existingModules, ...createdModules]);
      
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: `Successfully saved ${moduleList.length} module${moduleList.length > 1 ? 's' : ''}`,
        color: 'success',
      });

      // Notify parent component about successful module creation
      if (onSuccess) {
        onSuccess(createdModules);
      }
      
      // Close modal after success
      closeWithReset();
    } catch (error) {
      setIsLoading(false);
      setAlert({
        show: true,
        message: error.response?.data?.error || 'An error occurred while saving modules. Please try again.',
        color: 'danger',
      });
      console.error('Error saving modules:', error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={closeWithReset}>
        <ModalDialog
          variant="outlined"
          size="md"
          className="modal-container-mobile-fullscreen"
          sx={{
            maxWidth: '50vw',
            width: '100%',
          }}
        >
          <DialogTitle>Create New Modules</DialogTitle>
          <ModalClose
            aria-label="Close Dialog"
          />
          <DialogContent>
            <form onSubmit={handleSubmit} aria-label="Module creation form">
              <Stack spacing={3}>

                <Stack
                  direction='column'
                  spacing={1}
                  p={0}
                  component="fieldset"
                  border="none"
                >
                  <FormControl error={!!moduleError} sx={{ width: "100%" }}>                 
                    <FormLabel>Module Names</FormLabel>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Textarea
                        variant="outlined"
                        maxRows={6}
                        color={moduleError ? "danger" : "primary"}
                        value={newModule}
                        onChange={(e) => setNewModule(e.target.value)}
                        placeholder="Enter module names here..."
                        sx={{ width: "100%" }}
                      />
                      <Button
                        type="button"
                        variant="outlined"
                        endDecorator={<AddIcon />}
                        onClick={handleAddModule}
                        sx={{ height: "38px" }}
                      >
                        Add
                      </Button>
                    </Stack>
                    <FormHelperText>
                      {moduleError || "Add multiple module names separated with commas or a line-break"}
                    </FormHelperText> 
                  </FormControl>
                  
                </Stack>
                
                <Divider />
                
                {moduleList.length > 0 && (
                  <Box
                    component="section" 
                    tabIndex={0}
                    role="region"
                    aria-label="Added module names" 
                    className="scrollable-box"
                  >
                    <List 
                      aria-label="List of added modules"
                      sx={{  
                        "--ListItem-paddingX": "0px",
                        "--ListItem-paddingY": "8px",
                        overflowY: "auto"
                      }}>
                      {moduleList.map((module, index) => (
                        <ListItem
                          key={index}
                          sx={{ width: '100%' }}
                          endAction={
                            <IconButton 
                              aria-label={`Remove ${module}`} 
                              size="sm" 
                              color="danger"
                              onClick={() => handleRemoveModule(module)}
                              disabled={isLoading}
                            >
                              <CloseIcon />
                            </IconButton>
                          }
                        >
                          {module}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
                
                {alert.show && (
                  <Alert color={alert.color} sx={{ mb: 2 }} role="alert" aria-live="polite" endDecorator={
                      <Button 
                        onClick={() => setAlert({ show: false })}
                        variant="plain"
                        color={alert.color}
                        size="sm"
                      >
                        Dismiss
                      </Button>
                    }>
                    {alert.message}
                  </Alert>
                )}
              </Stack>
            </form>
          </DialogContent>
          <DialogActions className="dialog-actions-right">
            <Button 
              variant="outlined" 
              onClick={closeWithReset}
              disabled={isLoading}
            >
              Cancel
            </Button>
            {isLoading ? (
              <Button 
                type="submit"
                variant="solid" 
                loading
                loadingPosition="end"
              >
                Saving...
              </Button>
            ) : (
              <Button 
                variant="solid" 
                color="primary" 
                onClick={handleSubmit}
              >
                Save Modules
              </Button>
            )}
          </DialogActions>
        </ModalDialog>
      </Modal>
      <GlobalSnackbar 
        open={snackbar.open}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        color={snackbar.color}
        ariaLive={snackbar.color === 'danger' ? 'assertive' : 'polite'}
      />
    </>
  );
}
